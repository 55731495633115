import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from '@redwoodjs/web/dist/toast';
import { ZodObject, ZodSchema } from 'zod';
import { findMaxLengthOfFieldSchema, isFieldRequired } from 'src/lib/zod';
import { useDialog, useCharCount, useReadOnlyForm } from 'src/hooks';
import { classNames } from '../../lib';
import { Spinner } from '../Spinner';
import { IconButton } from '../IconButton';
import { ExpandedTextArea } from './ExpandedTextArea';
import { FadeIn } from '../FadeIn';
import { ReadOnlyField } from '../ReadOnlyField';
import { Label } from '../Label';
import { FieldError } from '../FieldError';
import ExpandIcon from '../../assets/arrows-expand.svg';
import { TextArea } from './TextArea';

const CHARACTER_LIMIT_FOR_TEXTAREA_EXPANSION = 150;

export type TextAreaFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  helpText?: string;
  className?: string;
  inputContainerClassNames?: string;
  inputClassName?: string;
  largeLabel?: boolean;
  loading?: boolean;
  autoResize?: boolean;
  schema?: ZodSchema;
  /**
   * Determines whether the textarea can be expanded.
   * Note: When `autoResize` is true, the textarea cannot be expanded.
   */
  expandable?: boolean;
  // If resize is enabled, the textArea will scroll when the content exceeds the maxHeight
  required?: boolean;
  scrollHeight?: number;
  disabled?: boolean;
  onFocus?: () => void;
};

export const TextAreaField: FC<TextAreaFieldProps> = ({
  name,
  className,
  inputContainerClassNames,
  inputClassName,
  label,
  placeholder,
  largeLabel,
  loading,
  autoResize,
  scrollHeight,
  schema,
  expandable = true,
  required,
  disabled,
  onFocus,
}) => {
  /**
   * No HTML Event is fired when updating a `<textarea/>` with javascript e.g `input.value="x"` or `formMethods.setValue("name", "jane")
   * We must therefore call `.watch()` on the field to listen for `.setValue()` events.
   *
   * This couples the field to `React-Hook-Form`
   */
  const [showExpandButton, setShowExpandButton] = useState(false);
  const formMethods = useFormContext();
  const { show, close } = useDialog();
  const { readOnly } = useReadOnlyForm();
  const value = formMethods.watch(name);
  // https://www.react-hook-form.com/faqs/#Howtosharerefusage
  const { ref, ...register } = formMethods.register(name, {
    setValueAs: (value) => (value ? (value.trim() === '' ? null : value.trim()) : null),
  });
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldSchema = (schema as ZodObject<any, any>)?.shape?.[name];
  const maxLengthOfFieldSchema = findMaxLengthOfFieldSchema(fieldSchema);
  const [charCount, updateCharCount] = useCharCount(0, maxLengthOfFieldSchema);

  const isRequired = schema ? isFieldRequired(schema, name) : required;

  const resizeTextarea = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    if (scrollHeight && textarea.scrollHeight > scrollHeight) {
      return (textarea.style.height = `${scrollHeight}px`);
    }

    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = `${textarea.scrollHeight + 8}px`;
    }
  };

  const handleTextareaInput = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.value = updateCharCount(textarea.value);

    if (autoResize) {
      resizeTextarea(textarea);
      setShowExpandButton(textarea.value.length > CHARACTER_LIMIT_FOR_TEXTAREA_EXPANSION);
    } else {
      setShowExpandButton(textarea.scrollHeight > textarea.clientHeight);
    }
  }, [textareaRef, updateCharCount]);

  useEffect(() => {
    handleTextareaInput();
  }, [value, handleTextareaInput]);

  const handleTextareaPaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData('text/plain').trim();
    const currentCharCount = textareaRef.current?.value.length || 0;
    const newCharCount = currentCharCount + pastedText.length;

    if (maxLengthOfFieldSchema && newCharCount > maxLengthOfFieldSchema) {
      toast.error('Pasted text has been trimmed as it exceeds the field character limit.');
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    handleTextareaInput();
    handleTextareaPaste(event);
  };

  const updateTextAreaValue = (value: string) => {
    if (textareaRef.current) {
      textareaRef.current.value = value;
      const event = new Event('input', { bubbles: true });
      textareaRef.current.dispatchEvent(event);
    }
  };

  useLayoutEffect(() => {
    if (autoResize && textareaRef.current) {
      resizeTextarea(textareaRef.current);
    }
  }, [autoResize]);

  const handleTextareaExpand = () => {
    show(
      <ExpandedTextArea
        onClose={close}
        initialContent={textareaRef.current?.value ?? ''}
        name={name}
        onUpdateContent={(content) => updateTextAreaValue(content)}
        schema={schema}
        charCount={charCount}
        label={label}
        autoResize
      />
    );
  };

  const isDisabled = disabled || loading;
  const isCharCountLimitReached = maxLengthOfFieldSchema && charCount >= maxLengthOfFieldSchema;

  if (readOnly) {
    return <ReadOnlyField name={name} label={label} value={value} />;
  }

  const hasError = !!formMethods.formState.errors[name];

  return (
    <div className={classNames('flex flex-col gap-1', !autoResize && 'flex-1', className)}>
      <div className="flex justify-between">
        <Label
          name={name}
          label={label || ' '}
          readOnly={readOnly}
          largeLabel={largeLabel}
          required={isRequired}
          error={hasError}
        />
      </div>
      <div
        className={classNames(
          'relative flex flex-col',
          !autoResize && 'flex-1',
          inputContainerClassNames
        )}
      >
        <TextArea
          onInput={handleTextareaInput}
          onPaste={handlePaste}
          placeholder={placeholder}
          onFocus={onFocus}
          className={classNames(
            !autoResize && 'flex-1',
            expandable && showExpandButton && 'pr-10',
            loading && 'pr-10',
            inputClassName,
            hasError && 'border-error-light'
          )}
          disabled={isDisabled}
          {...register}
          ref={(e) => {
            ref(e);
            textareaRef.current = e;
          }}
        />
        {loading && (
          <div className="absolute right-3 top-2">
            <Spinner className="h-6 w-6" />
          </div>
        )}

        {expandable && !autoResize && (
          <FadeIn visible={showExpandButton} className="absolute bottom-1 right-4">
            <IconButton
              Icon={ExpandIcon}
              onClick={handleTextareaExpand}
              tooltipText="Expand"
              size="xs"
            />
          </FadeIn>
        )}
      </div>
      <div className="flex justify-between">
        <div>
          <FieldError name={name} />
        </div>
        {maxLengthOfFieldSchema && (
          <div
            className={classNames(
              'self-end text-sm text-text-light',
              Boolean(isCharCountLimitReached) && 'text-text-medium/90'
            )}
          >
            {charCount}/{maxLengthOfFieldSchema}
          </div>
        )}
      </div>
    </div>
  );
};
