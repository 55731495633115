import { Info } from '../Info';

type LabelProps = {
  label: string;
  infoText?: string | null;
  name: string;
};

export const Label: React.FC<LabelProps> = ({ label, infoText, name }) => (
  <div className="flex min-w-52 flex-wrap items-center space-x-1">
    <label htmlFor={name} className="text-sm font-medium text-text-medium">
      {label}
    </label>
    {infoText && (
      <Info iconType="filled" size="small" color="gray">
        {infoText}
      </Info>
    )}
  </div>
);
