import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { GET_COMPANY_AND_DOCUMENTS_QUERY } from 'src/graphql/queries';
import { Company, GetCompanyAndDocuments, GetCompanyAndDocumentsVariables } from 'types/graphql';
import { ExportSubmitParams } from './types';
import { handleExport } from './lib/handleExport';
import { EntityExportDialog } from './EntityExportDialog';
import { useApolloClient } from '@apollo/client';

type Props = {
  id: string;
};

export const CompanyExportDialog: FC<Props> = ({ id }) => {
  const client = useApolloClient();
  const { data } = useQuery<GetCompanyAndDocuments, GetCompanyAndDocumentsVariables>(
    GET_COMPANY_AND_DOCUMENTS_QUERY,
    {
      variables: {
        companyId: id,
      },
    }
  );

  const company = data?.company as Company;

  const handleSubmit = async ({ includeDocuments, onComplete }: ExportSubmitParams) => {
    if (!company) return;

    try {
      await handleExport(client, company, 'Company', includeDocuments);
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Company" onSubmit={handleSubmit} />;
};
