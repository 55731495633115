import { z } from 'zod';

export const candidateFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Required' })
    .max(255, "Name can't be longer than 255 characters"),
  jobTitle: z
    .string()
    .trim()
    .min(1, { message: 'Required' })
    .max(255, "Job title can't be longer than 255 characters"),
  notes: z.string().trim().max(15000, "Notes can't be longer than 15000 characters").nullish(),
  availability: z
    .string()
    .trim()
    .max(1000, "Availability can't be longer than 1000 characters")
    .nullish(),
  currentSalary: z
    .string()
    .trim()
    .max(255, "Current salary can't be longer than 255 characters")
    .nullish(),
  desiredJobTitle: z
    .string()
    .trim()
    .max(255, "Desired job title can't be longer than 255 characters")
    .nullish(),
  desiredSalary: z
    .string()
    .trim()
    .max(255, "Desired salary can't be longer than 255 characters")
    .nullish(),
  location: z.string().trim().max(255, "Location can't be longer than 255 characters").nullish(),
  refId: z.string().trim().max(255, "Ref ID can't be longer than 255 characters").nullish(),
  profileSummary: z
    .string()
    .trim()
    .max(10000, "Profile summary can't be longer than 10000 characters")
    .nullish(),
  rightToWork: z
    .string()
    .trim()
    .max(255, "Right to work can't be longer than 255 characters")
    .nullish(),
  experience: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Job title can't be longer than 255 characters"),
        company: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Company can't be longer than 255 characters"),
        startDate: z.date().nullable(),
        endDate: z.date().nullable(),
        location: z
          .string()
          .trim()
          .max(255, "Location can't be longer than 255 characters")
          .nullable(),
        description: z
          .string()
          .trim()
          .max(4000, "Description can't be longer than 4000 characters")
          .nullish(),
      })
    )
    .optional(),
  education: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Education can't be longer than 255 characters"),
        institution: z
          .string()
          .max(255, "Institution can't be longer than 255 characters")
          .nullish(),
        startDate: z.date().nullable(),
        endDate: z.date().nullable(),
        location: z
          .string()
          .trim()
          .max(255, "Location can't be longer than 255 characters")
          .nullable(),
        description: z
          .string()
          .trim()
          .max(4000, "Description can't be longer than 4000 characters")
          .nullish(),
      })
    )
    .optional(),
  skills: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Title can't be longer than 255 characters"),
        description: z
          .string()
          .max(1000, "Description can't be longer than 1000 characters")
          .trim()
          .nullable(),
      })
    )
    .optional(),
  achievements: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        description: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(2000, "Description can't be longer than 2000 characters"),
      })
    )
    .optional(),
  languages: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        language: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Language can't be longer than 255 characters"),
        level: z.number().max(5, { message: 'Level must be between 1 and 5' }).nullable(),
      })
    )
    .optional(),
  interests: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        description: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(2000, "Description can't be longer than 2000 characters"),
      })
    )
    .optional(),
  certifications: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Title can't be longer than 255 characters"),
        description: z
          .string()
          .max(2000, "Description can't be longer than 2000 characters")
          .trim()
          .nullable(),
      })
    )
    .optional(),
});

export type CandidateFormValues = z.infer<typeof candidateFormSchema>;
