import { useEffect, useState } from 'react';
import { deepEqual } from 'src/lib/object';

/**
 * Debounce a value - **Automatically compares the value to the previous value and only updates if they are different.**
 *
 * @param value - value to debounce
 * @param delay - delay in milliseconds (default: 500)
 * @returns debounced value
 */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (!deepEqual(value, debouncedValue)) {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [value, delay]);

  return debouncedValue;
}
