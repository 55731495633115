import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { FC, PropsWithChildren } from 'react';
import { z } from 'zod';
import { useDialog } from '../../hooks';
import { jsonSchemaToZod } from '../../lib/jsonSchemaToZod';
import { State } from '../../pages/PromptEngineeringPage/PromptEngineeringPage';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { DialogLayout } from '../DialogLayout';
import { IconButton } from '../IconButton';
import { TextAreaField } from '../TextArea';
import { TextField } from '../TextField';
import { ConfigFormField } from './DocumentConfigForm';
import { JsonSchemaEnum } from './schema';
import { sortSchemaFields } from './util';
import { Form } from '../Form/Form';

type Props = {
  state: State;
  setState: (state: State) => void;
};

/**
 *  Editor used to add, edit, and delete fields from a JSON Schema (Used in the Prompt Engineering Page)
 * */
export const DocumentConfigEditor: React.FC<Props> = ({ state, setState }) => {
  const { show, close } = useDialog();

  const validator = state.configFields ? jsonSchemaToZod(state.configFields) : null;
  const resolver = validator ? zodResolver(validator) : undefined;

  const formMethods = useForm({
    resolver,
    values: state.configValues,
  });
  const fields = sortSchemaFields(state.configFields);

  formMethods.watch((data) => {
    setState({ ...state, configValues: data });
  });

  const handleDelete = (key: string) => {
    const { [key]: _, ...rest } = state.configFields.properties;
    const values = { ...(state.configValues ?? {}) };
    delete values[key];
    setState({
      ...state,
      configFields: { ...state.configFields, properties: rest },
      configValues: values,
    });
  };

  const handleEdit = (key: string) => {
    show(
      <DialogLayout
        className="min-h-0 w-full overflow-hidden "
        title="Edit Select Field"
        onClose={close}
      >
        <div className="overflow-auto px-3">
          <EnumFieldForm
            value={state.configFields.properties[key] as JsonSchemaEnum}
            name={key}
            onChange={(v, k) => {
              // Exclude the old key from the new schema
              const { [key]: _, ...rest } = state.configFields.properties;
              setState({
                ...state,
                configFields: {
                  ...state.configFields,
                  properties: { ...rest, [k]: v },
                },
              });
              close();
            }}
          />
        </div>
      </DialogLayout>
    );
  };

  const handleMoveUp = (key: string) => {
    const index = fields.findIndex((field) => field.key === key);
    if (index === 0) return;
    const newFields = [...fields];
    const prev = newFields[index - 1];
    const curr = newFields[index];
    newFields[index - 1] = curr;
    newFields[index] = prev;
    const newSchemaProperties = { ...state.configFields.properties };
    newFields.forEach((field, i) => {
      newSchemaProperties[field.key] = {
        ...field.properties,
        order: i,
      };
    });

    setState({
      ...state,
      configFields: {
        ...state.configFields,
        properties: newSchemaProperties,
      },
    });
  };

  const handleMoveDown = (key: string) => {
    const index = fields.findIndex((field) => field.key === key);
    if (index === fields.length - 1) return;
    const newFields = [...fields];
    const next = newFields[index + 1];
    const curr = newFields[index];
    newFields[index + 1] = curr;
    newFields[index] = next;
    const newSchemaProperties = { ...state.configFields.properties };
    newFields.forEach((field, i) => {
      newSchemaProperties[field.key] = {
        ...field.properties,
        order: i,
      };
    });

    setState({
      ...state,
      configFields: {
        ...state.configFields,
        properties: newSchemaProperties,
      },
    });
  };

  return (
    <Form className="flex flex-col gap-y-5" formMethods={formMethods}>
      {fields.map(({ key, properties }, i) => (
        <FormButtonBarWrapper
          onDelete={handleDelete}
          onEdit={handleEdit}
          onMoveDown={handleMoveDown}
          onMoveUp={handleMoveUp}
          isFirst={i === 0}
          isLast={i === fields.length - 1}
          property={key}
          key={key}
        >
          <ConfigFormField key={key} name={key} field={properties} schema={validator} />
        </FormButtonBarWrapper>
      ))}
    </Form>
  );
};

const FormButtonBarWrapper: React.FC<
  PropsWithChildren<{
    isFirst?: boolean;
    isLast?: boolean;
    onMoveUp?: (key: string) => void;
    onMoveDown?: (key: string) => void;
    onDelete?: (key: string) => void;
    onEdit?: (key: string) => void;
    property: string;
  }>
> = ({ children, isFirst, isLast, onDelete, onEdit, onMoveDown, onMoveUp, property }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-end gap-x-3">
        {!isFirst && (
          <IconButton
            Icon={ChevronUpIcon}
            onClick={() => onMoveUp && onMoveUp(property)}
            size="small"
            tooltipText="Move Up"
          />
        )}
        {!isLast && (
          <IconButton
            Icon={ChevronDownIcon}
            onClick={() => onMoveDown && onMoveDown(property)}
            size="small"
            tooltipText="Move Down"
          />
        )}
        <IconButton
          Icon={PencilIcon}
          onClick={() => onEdit && onEdit(property)}
          size="small"
          tooltipText="Edit"
        />
        <IconButton
          Icon={XCircleIcon}
          onClick={() => onDelete && onDelete(property)}
          size="small"
          tooltipText="Delete"
        />
      </div>
      {children}
    </div>
  );
};

type EnumFormValues = {
  title: string;
  key?: string;
  enum: {
    value: string;
    default: boolean;
    description?: string;
    label: string;
  }[];
};

function parseJsonSchemaToEnumFormValue(v: JsonSchemaEnum, key: string): EnumFormValues {
  return {
    title: v.title,
    key,
    enum: v.enumDetails.map((i) => ({
      value: i.value,
      default: i.value === v.default,
      description: i.description,
      label: i.label,
    })),
  };
}

const schema = z.object({
  title: z.string(),
  key: z.string(),
  enum: z.array(
    z.object({
      value: z.string(),
      description: z.string().optional(),
      default: z.boolean().optional(),
      label: z.string(),
    })
  ),
});

export const EnumFieldForm: FC<{
  value?: JsonSchemaEnum;
  name?: string;
  onChange: (v: JsonSchemaEnum, key: string) => void;
}> = ({ value, name, onChange }) => {
  const formMethods = useForm<EnumFormValues>({
    defaultValues: value
      ? parseJsonSchemaToEnumFormValue(value, name ?? '')
      : {
          title: '',
          enum: [
            {
              value: '',
              label: '',
              description: undefined,
              default: true,
            },
          ],
        },
    resolver: zodResolver(schema),
  });
  const { fields, append, remove, swap } = useFieldArray({
    control: formMethods.control,
    name: 'enum',
  });

  const enumValue = formMethods.watch('enum');
  const defaultValue = enumValue?.findIndex((i) => i?.default);

  const onSubmit = (data: EnumFormValues) => {
    const enumField: JsonSchemaEnum = {
      title: data.title,
      type: 'string',
      enum: data.enum.map((i) => i.value),
      enumDetails: data.enum.map((i) => ({
        label: i.label,
        value: i.value,
        description: i.description,
      })),
      default: data.enum.find((i) => i?.default)?.value ?? '',
    };

    onChange(enumField, data.key ?? '');
  };

  return (
    <Form className="flex flex-col gap-y-3" onSubmit={onSubmit} formMethods={formMethods}>
      <TextField
        label="Key"
        name="key"
        helpText='How the value is referenced in the prompt e.g "length" for {{config.length}}'
        schema={schema}
      />
      <TextField schema={schema} label="Label" name="title" helpText='The label e.g "Length"' />
      <div className="flex justify-between">
        <p className="text-lg font-bold text-text-dark">Options</p>
        <IconButton
          size="small"
          Icon={PlusIcon}
          onClick={() => append({ default: false, label: '', value: '' })}
          tooltipText="Add Value"
        />
      </div>
      {fields.map((field, i) => (
        <div key={field.id} className="flex flex-col gap-y-3 border-y border-text-light py-2">
          <div className="flex items-center gap-x-3">
            <p className="font-semibold text-text-dark">{i + 1}.</p>
            <TextField
              schema={schema}
              label="Label"
              name={`enum.${i}.label`}
              helpText='Value presented in select box e.g "Detailed"'
            />
            <TextField
              schema={schema}
              label="Value"
              name={`enum.${i}.value`}
              helpText='How value is referenced in the prompt e.g {#eq "detailed"}'
            />
            <Controller
              name={`enum.${i}.default`}
              control={formMethods.control}
              render={({ field }) => (
                <Checkbox
                  label="Default"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={defaultValue !== -1 && defaultValue !== i}
                />
              )}
            />
            <div className="flex items-center">
              {i !== 0 && (
                <IconButton
                  size="small"
                  tooltipText="Move Up"
                  Icon={ChevronUpIcon}
                  onClick={() => swap(i, i - 1)}
                />
              )}
              {i !== fields.length - 1 && (
                <IconButton
                  size="small"
                  tooltipText="Move Down"
                  Icon={ChevronDownIcon}
                  onClick={() => swap(i, i + 1)}
                />
              )}

              <IconButton
                size="small"
                tooltipText="Delete"
                Icon={XCircleIcon}
                onClick={() => remove(i)}
              />
            </div>
          </div>
          <TextAreaField label="Description" name={`enum.${i}.description`} />
        </div>
      ))}
      <Button type="submit" text="Save" />
    </Form>
  );
};
