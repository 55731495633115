import { MODEL } from '../../../types/graphql';
import { FC, useEffect } from 'react';
import { Form, TextField } from 'src/components';
import { useForm } from 'react-hook-form';

type Parameters = {
  temperature?: number;
};

type Props = {
  parameters: Parameters;
  model: MODEL;
  onChange: (values: Parameters) => void;
};

export const Parameters: FC<Props> = ({ parameters, model, onChange }) => {
  const formMethods = useForm<Parameters>({
    defaultValues: parameters,
  });

  formMethods.watch((data) => onChange(data));

  const { min, max, initial } = model?.includes('CLAUDE')
    ? { min: 0, max: 1, initial: 0.5 }
    : { min: 0, max: 2, initial: 1 };

  useEffect(() => {
    formMethods.setValue('temperature', initial);
  }, [initial]);

  return (
    <div className="max-w-[120px]">
      <Form formMethods={formMethods}>
        <TextField
          min={min}
          name="temperature"
          label="Temperature"
          required
          type="number"
          step={0.1}
          helpText={`Min: ${min} Max: ${max}`}
        />
      </Form>
    </div>
  );
};
