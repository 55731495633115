import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { BlogPostIdeasFormValues, blogPostIdeasFormSchema } from '../../lib/formSchemas';
import { TextField } from '../TextField';
import { BaseForm } from './BaseForm';
import { BaseFormProps } from './types';
import { BlogPostIdeas } from '../../../types/graphql';
import { Link } from '../Link';
import { routes } from '@redwoodjs/router';
import { PageTitle } from '../PageTitle';
import { BoltIcon } from '@heroicons/react/24/outline';
import { useTemplateInputsStore } from '../../services/store';

export const BlogPostIdeasForm: FC<BaseFormProps> = ({
  onSubmit,
  operation,
  document,
  documentLoading,
  mutationError,
  mutationLoading,
}) => {
  // Store the input in local state so that it can be accessed by the BlogPostForm
  const cachedInput = useTemplateInputsStore((state) => state.blogPostIdeas);
  const formMethods = useForm<BlogPostIdeasFormValues>({
    resolver: zodResolver(blogPostIdeasFormSchema),
    defaultValues: {},
  });

  if (document && document?.__typename !== 'BlogPostIdeas') {
    throw new Error('Document is not a BlogPostIdeas');
  }

  const handleSubmit: SubmitHandler<BlogPostIdeasFormValues> = (data) => {
    useTemplateInputsStore.setState({ blogPostIdeas: data });
    onSubmit({
      inputData: {
        blogPostIdeas: data,
      },
    });
  };

  useEffect(() => {
    if (document?.__typename === 'BlogPostIdeas') {
      const documentData = document as BlogPostIdeas;
      formMethods.reset(documentData.input);
    } else if (cachedInput) {
      formMethods.reset(cachedInput);
    }
  }, [document, formMethods]);

  return (
    <BaseForm<BlogPostIdeasFormValues>
      formProps={{ onSubmit: handleSubmit, formMethods }}
      operation={operation}
      documentLoading={documentLoading}
      mutationError={mutationError}
      mutationLoading={mutationLoading}
      document={document}
    >
      <PageTitle Icon={BoltIcon} text="Blog Post Ideas" />
      {!document && (
        <p className="text-text-medium">
          Generate over 20 blog post ideas to help you get started with your content marketing
          strategy.
        </p>
      )}
      <TextField
        name="industry"
        label="Industry"
        placeholder="Fintech"
        schema={blogPostIdeasFormSchema}
      />
      <TextField
        name="clients"
        label="Target Clients"
        placeholder="e.g Fintech companies"
        schema={blogPostIdeasFormSchema}
      />
      <TextField
        name="candidates"
        label="Target Candidates"
        placeholder="e.g C# developers"
        schema={blogPostIdeasFormSchema}
      />
      {document && (
        <div className="text-text-medium">
          <p className="font-medium text-text-dark">Turn this into a blog post</p>
          <ol className="flex flex-col gap-y-2 py-2">
            <li>1. Select & copy the topic you&apos;d like to turn into a post. </li>
            <li>
              2.{' '}
              <Link
                size="large"
                onClick={() => {
                  window.open(
                    `${routes.createDocument({
                      templateType: 'BlogPost',
                    })}`,
                    '_blank'
                  );
                }}
              >
                Click here
              </Link>{' '}
              to create a blog post.
            </li>
          </ol>
        </div>
      )}
    </BaseForm>
  );
};
