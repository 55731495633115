import { PropsWithChildren } from 'react';
import { FormProvider, useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import { FormEditStateProvider } from 'src/providers';

export type Props<T extends FieldValues> = Omit<React.ComponentProps<'form'>, 'onSubmit'> & {
  onSubmit?: SubmitHandler<T>;
  formMethods: ReturnType<typeof useForm<T>>;
  readOnly?: boolean;
};

export const Form = <T extends FieldValues>({
  readOnly = false,
  formMethods,
  children,
  onSubmit,
  ...props
}: PropsWithChildren<Props<T>>) => {
  return (
    <FormEditStateProvider readOnly={readOnly}>
      <form {...props} onSubmit={onSubmit && formMethods.handleSubmit(onSubmit)}>
        <FormProvider<T> {...formMethods}>{children}</FormProvider>
      </form>
    </FormEditStateProvider>
  );
};
