import { FC } from 'react';
import { Carousel } from './Carousel';
import { classNames } from 'src/lib';
import { useSwiperSlide } from 'swiper/react';
export type Props = {
  templates: { id: string; name: string; preview: { src: string } }[];
  onSelectTemplate: (templateId: string) => void;
  selectedTemplateId?: string | null;
};

export const CVTemplateCarousel: FC<Props> = ({
  templates,
  onSelectTemplate,
  selectedTemplateId,
}) => {
  return (
    <Carousel
      items={templates}
      ItemComponent={CVTemplateCarouselItem}
      onSelect={(index) => onSelectTemplate(templates[index].id)}
      initialSlide={templates.findIndex((template) => template.id === selectedTemplateId) ?? 0}
    />
  );
};

export const CVTemplateCarouselItem: FC<{
  item: { id: string; name: string; preview: { src: string } };
  onClickItem: (index: number) => void;
  index: number;
}> = ({ item, index, onClickItem }) => {
  const swiperSlide = useSwiperSlide();

  return (
    <div
      className="flex cursor-pointer flex-col items-center justify-center gap-2"
      onClick={() => onClickItem(index)}
    >
      <div
        className={classNames(
          `${swiperSlide.isActive ? 'border-2 border-generate-medium' : ''}`,
          'transform rounded-lg bg-white shadow-lg transition-transform'
        )}
      >
        <img src={item.preview.src} alt={item.name} className="object-fit h-60 w-full rounded-lg" />
      </div>
      <p
        className={classNames(
          'mt-2 text-center font-medium capitalize',
          swiperSlide.isActive && 'font-semibold  text-generate-medium'
        )}
      >
        {item.name}
      </p>
    </div>
  );
};
