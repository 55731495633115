import { ReactNode } from 'react';

/**
 * Recursively extracts plain text from ReactNode children, ensuring words are separated by spaces.
 *
 * @param children - The ReactNode children from which to extract text.
 * @returns A concatenated string of all text found within the children nodes, with spaces between elements.
 */
export const extractTextFromChildren = (children: ReactNode): string => {
  if (typeof children === 'string') {
    // Trim the string to remove any leading or trailing whitespace.
    return children.trim();
  }

  if (React.isValidElement(children)) {
    // Recursively process children of the React element, adding spaces between elements.
    return extractTextFromChildren(children.props.children);
  }

  if (Array.isArray(children)) {
    // Process each child in the array, joining them with a space to ensure separation.
    return children.map(extractTextFromChildren).filter(Boolean).join(' ').trim();
  }

  // For other types (null, undefined, etc.), return an empty string.
  return '';
};
