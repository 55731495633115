import { FC } from 'react';
import { classNames } from 'src/lib';

export type ReadOnlyRichTextFieldProps = {
  name: string;
  label?: string;
  value?: string;
  className?: string;
};

export const ReadOnlyRichTextField: FC<ReadOnlyRichTextFieldProps> = ({
  name,
  label,
  value,
  className,
}) => {
  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      <label htmlFor={name} className="text-sm font-bold text-text-dark">
        {label}
      </label>
      <div
        id={name}
        className="overflow-hidden whitespace-pre-wrap break-words bg-inherit text-base font-normal text-text-veryDark"
      >
        {value ? <div className="prose" dangerouslySetInnerHTML={{ __html: value }} /> : ' - '}
      </div>
    </div>
  );
};
