import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { navigate, routes } from '@redwoodjs/router';
import { FC } from 'react';
import { useDialog } from '../../hooks';
import { CreateTemplateDialog } from '../CreateTemplateDialog';
import { DialogLayout } from '../DialogLayout';

export const CreateDialog: FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { show, close } = useDialog();
  return (
    <DialogLayout onClose={onClose && onClose} title="🪄 Create Something Powerful">
      <div className="flex flex-grow flex-wrap justify-center gap-8 py-6">
        <CreateCard
          Picture={BriefcaseIcon}
          title="Job"
          description="Generate all the content you need to source and place candidates."
          onClick={() => {
            navigate(routes.newJob());
            onClose && onClose();
          }}
        />
        <CreateCard
          Picture={UserIcon}
          title="Candidate"
          description="Generate stellar candidate introductions."
          onClick={() => {
            navigate(routes.newCandidate());
            onClose && onClose();
          }}
        />
        <CreateCard
          Picture={BuildingOfficeIcon}
          title="Company"
          description="Generate compelling company introductions."
          onClick={() => {
            navigate(routes.newCompany());
            onClose && onClose();
          }}
        />
        <CreateCard
          Picture={ChatBubbleLeftRightIcon}
          title="Chat"
          description="Create anything you need using AdScribe Chat - a Chat-GPT style AI."
          onClick={() => {
            navigate(routes.chat());
            onClose && onClose();
          }}
        />
        <CreateCard
          Picture={WrenchScrewdriverIcon}
          title="Template"
          description="Craft expertly written Client Proposals and more using AdScribe Templates."
          onClick={() => {
            show(<CreateTemplateDialog onClose={close} />);
          }}
        />
      </div>
    </DialogLayout>
  );
};

type Props = {
  title: string;
  description: string;
  Picture: FC<React.ComponentProps<'svg'>>;
  onClick: () => void;
};
const CreateCard: FC<Props> = ({ title, description, onClick, Picture }) => {
  return (
    <div
      className="flex w-[260px] cursor-pointer flex-col items-center gap-y-4 rounded-3xl border border-text-light p-6 hover:border-generate-medium"
      onClick={onClick}
    >
      <Picture
        className="h-[72px] w-[72px] text-text-dark"
        strokeWidth={1}
        height={72}
        width={72}
      />
      <p className="text-center font-medium text-generate-medium">{title}</p>
      <p className="text-center text-sm text-text-medium">{description}</p>
    </div>
  );
};
