import showdown from 'showdown';

// Strip HTML comments after conversion
export const stripHtmlComments = (content: string): string => {
  return content.replace(/<!--[\s\S]*?-->/g, '');
};

const converter = new showdown.Converter({
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
});

export function convertMarkdownToHtml(markdown: string): string {
  const html = converter.makeHtml(markdown);
  return stripHtmlComments(html);
}

export function convertHtmlToMarkdown(html: string): string {
  const markdown = converter.makeMarkdown(html);
  return stripHtmlComments(markdown);
}
