import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { useState } from 'react';
import {
  CreatePromptTestMutation,
  CreatePromptTestMutationVariables,
  DOCUMENT_TYPE,
  GetMeQuery,
  GetMeQueryVariables,
  GetMyDocTemplateQuery,
  GetMyDocumentTemplateQueryVariables,
  MODEL,
} from '../../../types/graphql';
import { JsonSchema } from '../../components/DocumentConfigForm/schema';
import { useDialog } from '../../hooks';
import { compilePromptTemplate } from './handlebars';
import { ConfigEditor } from './ConfigEditor';
import { InputEditor, jobFormToInputValues } from './InputEditor';
import { jobCampaignScenarios } from './inputScenarios';
import { IconButton } from '../../components/IconButton';
import { ArrowDownOnSquareIcon, ClockIcon } from '@heroicons/react/24/outline';
import { useLazyQuery } from '@apollo/client';
import { toast } from '@redwoodjs/web/dist/toast';
import { Spinner } from '../../components/Spinner';
import { Select } from '../../components/SelectField/SelectField';
import { documentPresentationalProperties, DocumentType } from '../../lib/document';
import { GET_ME_QUERY } from '../../graphql/queries';
import { HistoryDialog } from './HistoryDialog';
import { OutputEditor } from './OutputEditor';
import { PromptEditor } from './PromptEditor';
import { TemplateContext } from './types';
import { CompiledPrompt } from './CompiledPrompt';
import { Parameters } from './Parameters';
import { MODEL_SELECT_OPTIONS } from './constants';

const GET_DOC_TEMPLATE_QUERY = gql`
  query GetMyDocTemplateQuery($myDocumentTemplateInput: MyDocumentTemplateInput!) {
    myDocumentTemplate(input: $myDocumentTemplateInput) {
      id
      title
      description
      config
      prompt
      sp
      p
      configSchema
    }
  }
`;

const CREATE_PROMPT_TEST_MUTATION = gql`
  mutation CreatePromptTestMutation($input: CreatePromptTestInput!) {
    createPromptTest(input: $input) {
      text
      markup
    }
  }
`;

const options = Object.keys(documentPresentationalProperties) as DocumentType[];

export type State = {
  docType: DocumentType;
  configFields: JsonSchema;
  prompts: {
    system: string;
    user: string;
  };
  /**
   * The compiled prompt with the input values
   */
  compiledPrompts?: {
    system: string;
    user: string;
  };
  /**
   * Result
   */
  result?: string;
  configValues: Record<string, unknown>;
  inputValues: Record<string, unknown>;
  model: MODEL;
  parameters: Record<string, unknown>;
  // Legacy - deprecated
  prompt?: string;
};

export type TemplateExport = {
  docType: State['docType'];
  model: State['model'];
  defaultConfig: State['configValues'];
  configSchema: State['configFields'];
  userPrompt: State['prompts']['user'];
  systemPrompt: State['prompts']['system'];
  parameters: State['parameters'];
};

/**
 * Used for local storage
 */
type History = { state: State; date: string }[];

const PromptEngineeringPage = () => {
  const { show, close } = useDialog();
  const [state, setState] = useState<State>({
    configValues: {},
    configFields: {
      type: 'object',
      properties: {},
      required: [],
    },
    parameters: {
      temperature: 0.5,
    },
    prompts: {
      system: '',
      user: '',
    },
    docType: 'JobAdvert',
    inputValues: jobFormToInputValues(jobCampaignScenarios[1].input),
    result: undefined,
    model: 'CLAUDE_3_HAIKU',
  });

  // Fetch user persona
  const { data, loading } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: { isAdmin: true },
  });

  // Load the current user doc template for the doc type
  const [getMyTemplate] = useLazyQuery<GetMyDocTemplateQuery, GetMyDocumentTemplateQueryVariables>(
    GET_DOC_TEMPLATE_QUERY,
    {
      onCompleted: (data) => {
        const template = data.myDocumentTemplate;
        setState({
          ...state,
          prompts: { user: template.prompt ?? 'Default', system: template.sp ?? '' },
          configFields: template.configSchema
            ? JSON.parse(template.configSchema)
            : { title: 'Config', type: 'object', properties: {} },
          configValues: template.config ? JSON.parse(template.config) : {},
        });
      },
      onError: (e) => {
        toast.error('Unable to load document template');
      },
    }
  );

  const [createPromptTest, { loading: testLoading }] = useMutation<
    CreatePromptTestMutation,
    CreatePromptTestMutationVariables
  >(CREATE_PROMPT_TEST_MUTATION, {
    onCompleted: (data) => {
      const newState = {
        ...state,
        result: data?.createPromptTest?.text ?? '',
        resultMarkup: data.createPromptTest.markup,
      };
      const history = localStorage.getItem('promptHistory');
      const parsedHistory: History = history ? JSON.parse(history) : [];
      const newHistory = [...parsedHistory, { state: newState, date: new Date().toISOString() }];
      localStorage.setItem('promptHistory', JSON.stringify(newHistory));
      setState(newState);
    },
  });

  const onSelectDocType = (docType: DOCUMENT_TYPE) => {
    getMyTemplate({
      variables: {
        myDocumentTemplateInput: {
          docType,
        },
      },
    });
    setState({
      ...state,
      docType,
    });
  };

  const onTest = () => {
    setState({ ...state, result: undefined });
    console.log({ state });
    createPromptTest({
      variables: {
        input: {
          systemMessage: compilePromptTemplate(
            state.prompts.system,
            state.configValues,
            state.inputValues as Record<string, unknown>,
            data?.me?.userPersona ?? {},
            { language: data?.me?.language ?? 'en' }
          ),
          userMessage: compilePromptTemplate(
            state.prompts.user,
            state.configValues,
            state.inputValues as Record<string, unknown>,
            data?.me?.userPersona ?? {},
            { language: data?.me?.language ?? 'en' }
          ),
          model: state.model,
        },
      },
    });
  };

  const onOpenHistory = () => {
    const history = localStorage.getItem('promptHistory');
    const parsedHistory: History = history ? JSON.parse(history) : [];
    show(
      <HistoryDialog
        onClose={close}
        history={parsedHistory}
        onRestore={(state) => {
          setState(state);
          close();
        }}
      />
    );
  };

  const onExport = async () => {
    const payload: TemplateExport = {
      docType: state.docType,
      model: state.model,
      defaultConfig: state.configValues,
      configSchema: state.configFields,
      userPrompt: state.prompts.user,
      systemPrompt: state.prompts.system,
      parameters: state.parameters,
    };
    const jsonPayload = await JSON.stringify(payload);

    const element = document.createElement('a');
    const file = new Blob([jsonPayload], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${state.docType}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  };

  const onImport: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    if (!file.name.endsWith('.json')) {
      toast.error('Invalid file type');
      return;
    }
    file.text().then((text) => {
      const data = JSON.parse(text);
      // If old template, just add to user prompt
      const prompts = data.prompt
        ? { user: data, system: '' }
        : { user: data.userPrompt, system: data.systemPrompt };

      setState({
        ...state,
        docType: data.docType,
        model: data.model,
        configValues: data.defaultConfig,
        configFields: data.configSchema,
        parameters: data.parameters,
        prompts,
      });
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const templateContext: TemplateContext = {
    input: state.inputValues,
    config: state.configValues,
    userPersona: data?.me?.userPersona,
    user: { language: data?.me?.language },
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col">
      <MetaTags title="PromptEngineering" description="PromptEngineering page" />
      <div className="flex items-center gap-x-6 border border-text-light px-6 py-2">
        <div className="flex gap-x-4">
          <Select
            label="Doc Type"
            name="docType"
            options={options.map((i) => ({ value: i, label: i }))}
            value={state.docType}
            onChange={onSelectDocType}
          />

          <Select<MODEL>
            label="Model"
            name="model"
            options={MODEL_SELECT_OPTIONS}
            value={state.model ?? 'CLAUDE_3_SONNET'}
            onChange={(v: MODEL) => setState({ ...state, model: v })}
          />
          <Parameters
            model={state.model}
            onChange={(values) => setState({ ...state, parameters: values })}
            parameters={state.parameters}
          />
        </div>

        <div className="flex flex-grow justify-end gap-x-4">
          <input type="file" onChange={onImport} />
          <IconButton tooltipText="Export" onClick={onExport} Icon={ArrowDownOnSquareIcon} />
          <IconButton onClick={onOpenHistory} Icon={ClockIcon} tooltipText="History" />
        </div>
      </div>
      <div className="flex min-h-0 flex-1">
        <div className="flex basis-1/3 flex-col overflow-hidden">
          <PromptEditor
            onChange={(values) => setState({ ...state, prompts: values })}
            prompts={state.prompts}
            templateContext={templateContext}
            onSubmit={onTest}
          />
        </div>
        <div className="flex basis-1/3 flex-col overflow-hidden">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="flex-1 overflow-auto">
              <CompiledPrompt templateContext={templateContext} prompts={state.prompts} />
            </div>
            {(state.result || testLoading) && (
              <div className="flex-1 overflow-auto">
                <OutputEditor content={state.result} loading={testLoading} />
              </div>
            )}
          </div>
        </div>
        <div className="flex basis-1/3 overflow-hidden border border-text-light">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="flex flex-1 flex-col overflow-hidden">
              <ConfigEditor state={state} setState={setState} />
            </div>
            <div className="flex flex-1 flex-col overflow-hidden">
              <InputEditor docType={state.docType} state={state} setState={setState} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptEngineeringPage;
