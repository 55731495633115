import { FC, Fragment } from 'react';
import { useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/react/24/outline';

import { GET_CUSTOMER_SUBSCRIPTION_QUERY } from 'src/graphql/queries';
import { GetCustomerSubscription, GetCustomerSubscriptionVariables } from 'types/graphql';

import { Button, IconButton, Spinner } from 'src/components';
import { SettingsItem } from '../SettingsComponents';

import { formatDate } from 'src/lib/dateTime';
import { formatCurrency } from 'src/lib/currency';

const BillingPage: FC = () => {
  const { data, loading, error } = useQuery<
    GetCustomerSubscription,
    GetCustomerSubscriptionVariables
  >(GET_CUSTOMER_SUBSCRIPTION_QUERY);

  const handleDownload = (invoiceUrl: string | null) => {
    if (invoiceUrl) {
      const a = document.createElement('a');
      a.href = invoiceUrl;
      a.target = '_blank';
      a.download = 'invoice.pdf';
      a.click();
    } else {
      toast.error('Invoice download link is not available');
    }
  };

  const onUpdate = () => {
    const emailUrl = new URL(`mailto:${process.env.SUPPORT_EMAIL}`);
    emailUrl.searchParams.append(
      'subject',
      `Update Licenses for ${data?.getCustomerSubscription?.product?.name}`
    );
    emailUrl.searchParams.append('body', `Please update my license limit to `);

    // Plus encoded spaces may not work on iOS mail app so we must convert to percent encoded spaces
    const urlString = emailUrl.toString().replace(/\+/g, '%20');

    window.open(urlString);
  };

  if (loading) return <Spinner />;
  if (error) {
    toast.error('Error fetching billing details');
    return null;
  }

  const invoices = data?.getCustomerSubscription?.invoices || [];
  const plan = data?.getCustomerSubscription?.product?.name || 'N/A';
  const licenses = data?.getCustomerSubscription?.licenses || 'N/A';
  const expiresAt = data?.getCustomerSubscription?.expiresAt;
  const nextInvoiceDate = expiresAt ? formatDate(new Date(expiresAt)) : 'N/A';

  return (
    <div className="w-full max-w-[740px] p-6">
      <h2 className="text-xl font-semibold">Billing</h2>
      <div className="flex flex-col justify-center gap-12 pt-10">
        <SettingsItem label="Plan" value={plan} />
        <SettingsItem label="Licenses" value={licenses.toString()}>
          <Button
            variant="outline"
            size="medium"
            text="Update"
            LeftIcon={PlusIcon}
            onClick={onUpdate}
          />
        </SettingsItem>
        <SettingsItem label="Next Invoice" value={nextInvoiceDate} />
      </div>
      <div className="mt-14">
        <h3 className="text-xl font-bold text-text-dark">Invoices</h3>
        <div className="border-b border-gray-200 pt-4" />
        {invoices.map((invoice) => (
          <Fragment key={invoice.id}>
            <div className="flex justify-between py-4">
              <div className="flex flex-col justify-center">
                <div className="text-xs font-medium text-text-dark">
                  {formatDate(new Date(invoice.effectiveDate))}
                </div>
                <div className="text-sm font-normal text-text-medium">
                  {formatCurrency(invoice.amountPaid, invoice.currency)}
                </div>
              </div>

              <div className="flex items-center justify-end">
                <IconButton
                  Icon={ArrowDownTrayIcon}
                  onClick={() => handleDownload(invoice.invoiceUrl ?? '')}
                  tooltipText="Download Invoice"
                  size="medium"
                />
              </div>
            </div>
            <div className="border-b border-gray-200" />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default BillingPage;
