import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { GET_CANDIDATE_AND_DOCUMENTS_QUERY } from 'src/graphql/queries';
import {
  Candidate,
  GetCandidateAndDocuments,
  GetCandidateAndDocumentsVariables,
} from 'types/graphql';
import { EntityExportDialog } from './EntityExportDialog';
import { ExportSubmitParams } from './types';
import { handleExport } from './lib/handleExport';
import { useApolloClient } from '@apollo/client';

type Props = {
  id: string;
};

export const CandidateExportDialog: FC<Props> = ({ id }) => {
  const client = useApolloClient();
  const { data } = useQuery<GetCandidateAndDocuments, GetCandidateAndDocumentsVariables>(
    GET_CANDIDATE_AND_DOCUMENTS_QUERY,
    {
      variables: {
        candidateId: id,
      },
    }
  );

  const candidate = data?.candidate as Candidate;

  const handleSubmit = async ({ includeDocuments, onComplete }: ExportSubmitParams) => {
    if (!candidate) return;

    try {
      await handleExport(client, candidate, 'Candidate', includeDocuments);
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Candidate" onSubmit={handleSubmit} />;
};
