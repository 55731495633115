import { FC } from 'react';
import { classNames } from '../../lib';

export type Props = {
  text: string;
  color: 'green' | 'orange' | 'red' | 'grey' | 'cyan';
  size?: 'sm' | 'md';
};

export const StatusPill: FC<Props> = ({ color, text, size = 'md' }) => {
  const sizeClasses = size === 'sm' ? 'text-xs px-1' : 'text-sm px-2 py-1';
  return (
    <div className={classNames('rounded-md capitalize', sizeClasses, styles[color])}>{text}</div>
  );
};

const styles: Record<Props['color'], string> = {
  green: 'bg-green-50 text-green-500 border-green-200',
  orange: 'bg-yellow-50 text-yellow-500 border-yellow-200',
  red: 'bg-red-50 text-red-500 border-red-200',
  cyan: 'bg-cyan-50 text-cyan-500 border-cyan-200 border-blue-200 border',
  grey: 'bg-slate-100 text-text-medium border border-slate-200',
};
