import * as z from 'zod';

export const documentsFormSchema = z.object({
  headerStyle: z.enum(['LOGO', 'LETTERHEAD']).optional(),
  horizontalMargin: z.enum(['small', 'medium', 'large']).optional(),
  showFooter: z.boolean(),
  headingFont: z.string(),
  bodyFont: z.string(),
  fontScale: z.string().optional(),
});

export type DocumentFormValues = z.infer<typeof documentsFormSchema>;
