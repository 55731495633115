export const GET_ME_QUERY = gql`
  query GetMeQuery($isAdmin: Boolean!) {
    me {
      id
      email
      name
      language
      jobCampaignOnboardingStatus
      candidateCampaignOnboardingStatus
      companyCampaignOnboardingStatus
      needsBrandVoiceOnboarding
      hasCompletedProfileOnboarding
      defaultCandidatePermissions
      defaultCompanyPermissions
      defaultJobPermissions
      subscription {
        status
        cancelAt @include(if: $isAdmin)
        product @include(if: $isAdmin) {
          name
        }
      }
      organisation {
        id
        name
        userLimit @include(if: $isAdmin)
        stripeCustomerId @include(if: $isAdmin)
        defaultBrandVoice {
          __typename
          ... on OrganisationBrandVoice {
            id
          }
        }
      }
      defaultBrandVoice {
        __typename
        ... on UserBrandVoice {
          id
        }
        ... on OrganisationBrandVoice {
          id
        }
      }

      userPersona {
        __typename
        id
        candidates
        clientLocations
        clients
        industries
        recruitmentType
      }
    }
  }
`;
