import DashboardPage from './pages/DashboardPage/DashboardPage';
import ChatPage from './pages/ChatPage/ChatPage';
import NewJobPage from './pages/NewJobPage/NewJobPage';
import CandidatePage from './pages/CandidatePage/CandidatePage';
import NewCandidatePage from './pages/NewCandidatePage/NewCandidatePage';
import CandidatesPage from './pages/CandidatesPage/CandidatesPage';
import NewCompanyPage from './pages/NewCompanyPage/NewCompanyPage';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import CompaniesPage from './pages/CompaniesPage/CompaniesPage';
import CreateDocumentPage from './pages/CreateDocumentPage/CreateDocumentPage';
import DocumentPage from './pages/DocumentPage/DocumentPage';
import DocumentsPage from './pages/DocumentsPage/DocumentsPage';
import HelpPage from './pages/HelpPage/HelpPage';
import PromptEngineeringPage from './pages/PromptEngineeringPage/PromptEngineeringPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import AcceptInvitePage from './pages/AcceptInvitePage/AcceptInvitePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { Router, Route, Set, Private } from '@redwoodjs/router';
import { useEffect } from 'react';
import { Spinner } from './components/Spinner';
import * as Sentry from '@sentry/react';
import { HeaderLayout } from './layouts';
import JobsPage from './pages/JobsPage/JobsPage';
import { setUser } from './services';
import { useAuth } from './auth';
import JobPage from './pages/JobPage/JobPage';
import { ENVIRONMENT } from './services/env';
import SettingsLayout from './layouts/SettingsLayout/SettingsLayout';
import SnippetsPage from './pages/SettingsPages/SnippetsPage/SnippetsPage';
import CampaignPage from './pages/SettingsPages/CampaignPage/CampaignPage';
import AccountPage from './pages/SettingsPages/AccountPage/AccountPage';
import ProfilePage from './pages/SettingsPages/ProfilePage/ProfilePage';
import PrivacyPage from './pages/SettingsPages/PrivacyPage/PrivacyPage';
import OrganisationPage from './pages/SettingsPages/OrganisationPage/OrganisationPage';
import BillingPage from './pages/SettingsPages/BillingPage/BillingPage';

const Routes = () => {
  const { currentUser } = useAuth();
  const userId = currentUser?.id;

  useEffect(() => {
    if (userId) {
      setUser(userId);
      Sentry.setUser({ id: userId });
    }
  }, [userId]);

  return (
    <Router useAuth={useAuth}>
      <Private unauthenticated="login" whileLoadingAuth={() => <Spinner />}>
        <Set wrap={HeaderLayout}>
          <Route path="/" page={DashboardPage} name="Home" />
          <Route path="/chat" page={ChatPage} name="chat" />
          <Route path="/new-job" page={NewJobPage} name="newJob" />

          <Route path="/job/{jobId}" page={JobPage} name="job" />
          <Route path="/jobs" page={JobsPage} name="jobs" />
          <Route path="/candidate/{candidateId}" page={CandidatePage} name="candidate" />
          <Route path="/new-candidate" page={NewCandidatePage} name="newCandidate" />
          <Route path="/candidates" page={CandidatesPage} name="candidates" />
          <Route path="/new-company" page={NewCompanyPage} name="newCompany" />
          <Route path="/company/{companyId}" page={CompanyPage} name="company" />
          <Route path="/companies" page={CompaniesPage} name="companies" />
          <Route
            path="/document/create/{templateType}"
            page={CreateDocumentPage}
            name="createDocument"
          />
          <Route path="/document/{documentId}" page={DocumentPage} name="document" />
          <Route path="/documents" page={DocumentsPage} name="documents" />
          <Route path="/help" page={HelpPage} name="help" />

          <Route path="/user-persona/new" page={NewUserPersonaPage} name="newUserPersona" />
          <Set wrap={SettingsLayout}>
            <Route path="/settings/account" page={AccountPage} name="settingsAccount" />
            <Route path="/settings/profile" page={ProfilePage} name="settingsProfile" />
            <Route
              path="/settings/organisation"
              page={OrganisationPage}
              name="settingsOrganisation"
            />
            <Route path="/settings/privacy" page={PrivacyPage} name="settingsPrivacy" />
            <Route path="/settings/documents" page={CampaignPage} name="settingsDocuments" />
            <Route path="/settings/snippets" page={SnippetsPage} name="settingsSnippets" />
            <Route path="/settings/billing" page={BillingPage} name="settingsBilling" />
          </Set>
        </Set>
        {ENVIRONMENT !== 'production' && (
          <Route path="/prompt-engineering" page={PromptEngineeringPage} name="promptEngineering" />
        )}
      </Private>
      <Set>
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
        <Route path="/accept-invite/{code}" page={AcceptInvitePage} name="acceptInvite" />
      </Set>

      <Route notfound page={NotFoundPage} />
    </Router>
  );
};

export default Routes;
