import { useRef, useEffect } from 'react';
import { navigate, routes } from '@redwoodjs/router';
import { MetaTags } from '@redwoodjs/web';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from '@redwoodjs/web/dist/toast';

import { Form, TextField, Button } from 'src/components';

import { useAuth } from 'src/auth';
import Logo from '../../assets/logo.svg';

const schema = z.object({
  email: z
    .string({ coerce: true })
    .min(1, { message: 'Email is required' })
    .email({ message: 'Invalid email address' }),
  password: z.string({ coerce: true }).min(1, { message: 'Password is required' }),
});

const LoginPage = () => {
  const { isAuthenticated, logIn, loading } = useAuth();
  const formMethods = useForm({
    resolver: zodResolver(schema),
  });

  const emailRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const onSubmit = async (data: Record<string, string>) => {
    const response = await logIn({
      username: data.email,
      password: data.password,
    });

    if (response.id) {
      navigate(routes.Home());
    } else {
      if (response.error === 'No active subscription found') {
        toast.error('You need an active subscription.');
      } else {
        toast.error('Invalid username or password');
      }
    }
  };

  return (
    <div className="flex h-full w-full flex-grow items-center justify-center">
      <MetaTags title="Login" />
      <Form
        className="flex w-full max-w-lg flex-col gap-y-3 rounded-3xl border border-text-light p-12"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <div className="w-48 self-center">
          <Logo />
        </div>
        <h3 className="pt-3 text-2xl text-text-dark">Sign In</h3>
        <TextField disabled={loading} schema={schema} name="email" label="Username" />
        <TextField
          disabled={loading}
          type="password"
          name="password"
          label="Password"
          schema={schema}
        />
        <div className="flex flex-col gap-y-3 pt-4">
          <Button loading={loading} type="submit" text="Log In" size="large" />
          <Button
            variant="outline"
            loading={loading}
            onClick={() => navigate(routes.forgotPassword())}
            text="Forgot Password"
            size="large"
          />
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
