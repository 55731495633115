import mixpanelPackage from 'mixpanel-browser';
import { MIXPANEL_PROJECT_TOKEN } from './env';

const mixpanel = mixpanelPackage.init(
  MIXPANEL_PROJECT_TOKEN,
  {
    debug: process.env.NODE_ENV !== 'production',
  },
  'mixpanel'
);

type Events = {
  'Page View': {
    page: string;
    [key: string]: string | number | boolean | undefined | null | object;
  };
  'Button Click': {
    buttonName: string;
    [key: string]: string | number | boolean | undefined | null | object;
  };
};

const getQueryParams = () => {
  return Object.fromEntries(new URLSearchParams(window.location.search));
};

export const track = <K extends keyof Events>(eventName: K, properties: Events[K]) => {
  const queryParams = getQueryParams();
  mixpanel.track(eventName, { ...properties, ...queryParams, client: 'web' });
};

export const setUser = (userId: string) => {
  mixpanel.identify(userId);
};
