import { FC, Fragment, useLayoutEffect, useRef, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem as HeadlessMenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { track } from 'src/services';
import { classNames } from 'src/lib';
import { buttonColors, buttonDimensions } from '../Button';

export type DropdownButtonProps = {
  options: {
    text: string;
    Icon: FC<React.ComponentProps<'svg'>>;
    onClick: () => void;
    iconClassName?: string;
    disabled?: boolean;
  }[];
  /**
   * If buttonText is provided, the dropdown button will be a button with the text provided.
   */
  buttonText?: string;
  buttonSize?: 'medium' | 'large';
  buttonVariant?: 'primary' | 'outline';
  /**
   * Dropdown button should always have an icon
   */
  Icon?: FC<React.ComponentProps<'svg'>>;
  iconClassName?: string;
  /**
   * If buttonOverride is provided, button will be a custom component
   */
  buttonOverride?: React.ReactNode;
};

export const DropdownButton: FC<DropdownButtonProps> = ({
  options,
  buttonText,
  Icon = EllipsisVerticalIcon,
  buttonSize,
  buttonVariant,
  iconClassName,
}) => {
  const [expandDirection, setExpandDirection] = useState<'left' | 'right'>('left');
  const anchorRef = useRef<HTMLButtonElement>(null);

  // This effect is used to determine if the dropdown menu should expand to the left or right
  useLayoutEffect(() => {
    if (anchorRef.current) {
      const { left } = anchorRef.current.getBoundingClientRect();
      if (left < 200) {
        setExpandDirection('right');
      }
    }
  }, [anchorRef]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton
          ref={anchorRef}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
          className={classNames(
            'inline-flex items-center justify-center gap-x-1 focus:outline-none focus:ring-2 focus:ring-primary-medium focus:ring-offset-2 focus:ring-offset-gray-100',
            buttonText
              ? classNames(
                  buttonSize === 'large' ? buttonDimensions.large : buttonDimensions.medium,
                  buttonVariant === 'primary' ? buttonColors.primary : buttonColors.outline
                )
              : classNames('rounded-md p-2 text-text-dark hover:bg-gray-50')
          )}
        >
          <Icon
            className={classNames('h-5 w-5 text-text-dark', iconClassName)}
            aria-hidden="true"
          />
          {buttonText && buttonText}
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={classNames(
            expandDirection === 'left' ? 'right-0 origin-top-right' : 'left-0 origin-top-left',
            'absolute z-20 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          <div className="py-1">
            {options.map((option) => (
              <DropdownMenuItem key={option.text} {...option} />
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

const DropdownMenuItem: FC<{
  text: string;
  Icon: FC<React.ComponentProps<'svg'>>;
  onClick: () => void;
  iconClassName?: string;
  disabled?: boolean;
}> = ({ text, Icon, onClick, iconClassName, disabled }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    track('Button Click', { buttonName: text });
    e.stopPropagation();
    onClick();
  };

  return (
    <HeadlessMenuItem disabled={disabled}>
      {({ active }) => (
        <a
          href="#"
          onClick={handleClick}
          className={classNames(
            active ? 'bg-gray-100 text-text-veryDark' : 'text-text-dark',
            'group flex items-center px-4 py-2 text-sm'
          )}
        >
          <Icon
            className={classNames(
              'mr-3 h-5 w-5 text-text-medium group-hover:text-gray-500',
              iconClassName
            )}
            aria-hidden="true"
          />
          {text}
        </a>
      )}
    </HeadlessMenuItem>
  );
};
