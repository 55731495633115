import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { LeftMarginIcon } from 'src/assets/LeftMarginIcon';
import { RightMarginIcon } from 'src/assets/RightMarginIcon';
import { TopMarginIcon } from 'src/assets/TopMarginIcon';
import { BottomMarginIcon } from 'src/assets/BottomMarginIcon';
import {
  UPSERT_BRAND_ASSETS_MUTATION,
  UPSERT_BRAND_ASSET_LOGO_MUTATION,
  UPSERT_BRAND_ASSET_LETTERHEAD_MUTATION,
  DELETE_BRAND_ASSET_LOGO_IMAGE_MUTATION,
  DELETE_BRAND_ASSET_LETTERHEAD_IMAGE_MUTATION,
} from 'src/graphql/mutations';
import { GET_BRAND_ASSETS_QUERY } from 'src/graphql/queries';
import {
  GetBrandAssets,
  UpsertBrandAssets,
  UpsertBrandAssetsVariables,
  UpsertBrandAssetLetterhead,
  UpsertBrandAssetLetterheadVariables,
  UpsertBrandAssetLogo,
  UpsertBrandAssetLogoVariables,
  UpsertBrandAssetInput,
  DeleteBrandAssetLogoImage,
  DeleteBrandAssetLogoImageVariables,
  DeleteBrandAssetLetterheadImage,
  DeleteBrandAssetLetterheadImageVariables,
} from 'types/graphql';
import { useDebounce, useFileUpload } from 'src/hooks';
import { BrandingFormValues, brandingFormSchema } from './brandingFormSchema';
import { SelectField, Tabs, TextField } from 'src/components';
import { Form } from 'src/components/Form';
import { RichTextField } from 'src/components/RichTextField';
import { AlignmentSelectionGroup, FileInput } from '../../../components';
import { alignmentOptions, footerFontOptions, logoSizeOptions } from '../../../constants';
import { FadeIn } from 'src/components/FadeIn';
import { Info } from 'src/components/Info';
import { HEADER_TABS, HeaderTab } from '../constants';

const DEFAULT_FORM_VALUES: BrandingFormValues = {
  footerFont: 'Roboto',
  footerContent: '',
  logoAlignment: 'left',
  logoUrl: '',
  letterheadUrl: '',
  logoSize: '124',
  letterheadTopMargin: 24,
  letterheadBottomMargin: 24,
  letterheadLeftMargin: 24,
  letterheadRightMargin: 24,
  leftFooter: '',
  middleFooter: '',
  rightFooter: '',
};

enum FOOTER_TABS {
  LEFT = 'Left',
  MIDDLE = 'Middle',
  RIGHT = 'Right',
}

const FOOTER_TABS_OPTIONS: FOOTER_TABS[] = [
  FOOTER_TABS.LEFT,
  FOOTER_TABS.MIDDLE,
  FOOTER_TABS.RIGHT,
];

export const AssetsTabForm: FC<{
  brandAssets: GetBrandAssets['getBrandAssets'];
  headerTabControl: {
    selectedHeaderTab: HeaderTab;
    setSelectedHeaderTab: (tab: HeaderTab) => void;
  };
}> = ({ brandAssets, headerTabControl: { selectedHeaderTab, setSelectedHeaderTab } }) => {
  const [selectedFooterTab, setSelectedFooterTab] = useState<FOOTER_TABS>(FOOTER_TABS.LEFT);
  const { getFileUploadDestination, uploadFile } = useFileUpload();

  const [upsertBrandAssets] = useMutation<UpsertBrandAssets, UpsertBrandAssetsVariables>(
    UPSERT_BRAND_ASSETS_MUTATION,
    { refetchQueries: [GET_BRAND_ASSETS_QUERY] }
  );
  const [upsertLogoImage] = useMutation<UpsertBrandAssetLogo, UpsertBrandAssetLogoVariables>(
    UPSERT_BRAND_ASSET_LOGO_MUTATION,
    {
      refetchQueries: [GET_BRAND_ASSETS_QUERY],
    }
  );
  const [upsertLetterheadImage] = useMutation<
    UpsertBrandAssetLetterhead,
    UpsertBrandAssetLetterheadVariables
  >(UPSERT_BRAND_ASSET_LETTERHEAD_MUTATION, {
    refetchQueries: [GET_BRAND_ASSETS_QUERY],
  });
  const [deleteLogoImage] = useMutation<
    DeleteBrandAssetLogoImage,
    DeleteBrandAssetLogoImageVariables
  >(DELETE_BRAND_ASSET_LOGO_IMAGE_MUTATION, {
    refetchQueries: [GET_BRAND_ASSETS_QUERY],
  });
  const [deleteLetterheadImage] = useMutation<
    DeleteBrandAssetLetterheadImage,
    DeleteBrandAssetLetterheadImageVariables
  >(DELETE_BRAND_ASSET_LETTERHEAD_IMAGE_MUTATION, {
    refetchQueries: [GET_BRAND_ASSETS_QUERY],
  });

  const CONFIG_FORM_VALUES: BrandingFormValues = {
    footerFont: (brandAssets?.footerFont || DEFAULT_FORM_VALUES.footerFont) as
      | 'Roboto'
      | 'Libre Baskerville'
      | 'Roboto Mono',
    logoAlignment: (brandAssets?.logoAlignment || DEFAULT_FORM_VALUES.logoAlignment) as
      | 'left'
      | 'center'
      | 'right',
    logoSize: (brandAssets?.logoSize || DEFAULT_FORM_VALUES.logoSize) as '64' | '124' | '224',
    logoUrl: brandAssets?.logo?.src,
    letterheadUrl: brandAssets?.letterhead?.src,
    letterheadTopMargin:
      brandAssets?.letterheadTopMargin || DEFAULT_FORM_VALUES.letterheadTopMargin,
    letterheadBottomMargin:
      brandAssets?.letterheadBottomMargin || DEFAULT_FORM_VALUES.letterheadBottomMargin,
    letterheadLeftMargin:
      brandAssets?.letterheadLeftMargin || DEFAULT_FORM_VALUES.letterheadLeftMargin,
    letterheadRightMargin:
      brandAssets?.letterheadRightMargin || DEFAULT_FORM_VALUES.letterheadRightMargin,
    leftFooter: brandAssets?.leftFooter || DEFAULT_FORM_VALUES.leftFooter,
    middleFooter: brandAssets?.middleFooter || DEFAULT_FORM_VALUES.middleFooter,
    rightFooter: brandAssets?.rightFooter || DEFAULT_FORM_VALUES.rightFooter,
  };

  const formMethods = useForm<BrandingFormValues>({
    resolver: zodResolver(brandingFormSchema),
    defaultValues: CONFIG_FORM_VALUES,
  });

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);

  useEffect(() => {
    const {
      logoAlignment,
      leftFooter,
      middleFooter,
      rightFooter,
      logoSize,
      footerFont,
      letterheadLeftMargin,
      letterheadRightMargin,
      letterheadTopMargin,
      letterheadBottomMargin,
    } = debouncedFormValues;

    const upsertBrandAssetsInput: UpsertBrandAssetInput = {
      leftFooter,
      middleFooter,
      rightFooter,
      footerFont,
      logoSize,
      letterheadLeftMargin,
      letterheadRightMargin,
      letterheadTopMargin,
      letterheadBottomMargin,
      logoAlignment,
    };

    upsertBrandAssets({
      variables: {
        input: upsertBrandAssetsInput,
      },
    });
  }, [debouncedFormValues]);

  const hasLogo = Boolean(brandAssets?.logo?.src);
  const hasLetterHead = Boolean(brandAssets?.letterhead?.src);

  const handleFileRemove = async (type: 'logo' | 'letterhead') => {
    if (type === 'logo') {
      await deleteLogoImage({
        variables: { fileId: brandAssets?.logo?.id ?? '' },
      });
    } else if (type === 'letterhead') {
      await deleteLetterheadImage({
        variables: { fileId: brandAssets?.letterhead?.id ?? '' },
      });
    }
  };

  const handleFileUpload = async (file: File, type: 'logo' | 'letterhead') => {
    const maxSizeInMB = 3;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      toast.error(`File size should not exceed ${maxSizeInMB} MB.`);
      return;
    }

    const fileRecord = await getFileUploadDestination();
    try {
      const { url, blobName } = await uploadFile(
        file,
        fileRecord?.data?.getFileUploadLocation?.url
      );

      if (type === 'letterhead') {
        await upsertLetterheadImage({
          variables: {
            input: {
              fileUrl: url,
              mimeType: file.type,
              name: file.name,
              blobName,
            },
          },
        });
      } else if (type === 'logo') {
        await upsertLogoImage({
          variables: {
            input: {
              fileUrl: url,
              mimeType: file.type,
              name: file.name,
              blobName,
            },
          },
        });
      }
    } catch (uploadError) {
      console.error('Upload error:', uploadError);
      toast.error('Failed to upload file. Please try again.');
    }
  };

  return (
    <Form<BrandingFormValues> formMethods={formMethods}>
      <div>
        <div className="flex max-w-2xl flex-col justify-center gap-y-1 pb-3">
          <h1 className="text-lg font-semibold text-text-veryDark">Brand Assets</h1>
          <p className="text-sm font-normal text-text-medium">
            Manage your brand assets here. You will be able to use these in formatted CVs, PDF
            exports and cover sheets.
          </p>
        </div>

        <Tabs
          options={HEADER_TABS}
          setSelected={setSelectedHeaderTab}
          selected={selectedHeaderTab}
        />
        {selectedHeaderTab === 'Logo' ? (
          <>
            <div className="flex flex-col justify-between space-y-6 pt-4">
              <div className="flex items-center py-4">
                <div className="flex w-40 items-center gap-1">
                  <p className="font-medium text-text-dark">Logo:</p>
                  <Info>
                    <p className="p-2">
                      Supported formats: .jpg, .png.
                      <br />
                      Max size: 3MB.
                    </p>
                  </Info>
                </div>
                <FileInput
                  name="logoUrl"
                  onChange={(file) => handleFileUpload(file, 'logo')}
                  onRemove={() => handleFileRemove('logo')}
                  imageUrl={brandAssets?.logo?.src ?? ''}
                  frameClassName="h-16 min-w-16"
                />
              </div>
              {hasLogo && (
                <FadeIn visible={hasLogo}>
                  <div className="flex max-w-2xl items-center justify-between pt-5">
                    <p className="font-medium text-text-dark">Size:</p>
                    <SelectField name="logoSize" options={logoSizeOptions} />
                    <AlignmentSelectionGroup
                      name="logoAlignment"
                      options={alignmentOptions}
                      label="Alignment:"
                    />
                  </div>
                </FadeIn>
              )}
            </div>
            <div className="flex flex-col justify-center space-y-3 pt-5">
              <h2 className="text-lg font-bold text-text-dark">Footer</h2>
              <div className="flex items-center pt-3">
                <h2 className="w-40 font-semibold text-text-dark">Font:</h2>
                <div className="w-52">
                  <SelectField name="footerFont" options={footerFontOptions} />
                </div>
              </div>
              <Tabs
                options={FOOTER_TABS_OPTIONS}
                setSelected={setSelectedFooterTab}
                selected={selectedFooterTab}
              />
              <div className="pt-3">
                {selectedFooterTab === FOOTER_TABS.LEFT && (
                  <RichTextField
                    name="leftFooter"
                    label="Content"
                    placeholder="Enter left footer content..."
                  />
                )}
                {selectedFooterTab === FOOTER_TABS.MIDDLE && (
                  <RichTextField
                    name="middleFooter"
                    label="Content"
                    placeholder="Enter center footer content..."
                  />
                )}
                {selectedFooterTab === FOOTER_TABS.RIGHT && (
                  <RichTextField
                    name="rightFooter"
                    label="Content"
                    placeholder="Enter right footer content..."
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-between space-y-6 pt-4">
            <p className="pb-2 text-sm text-text-medium">
              Letterheads are applied as a backdrop with an A4 aspect ratio (1.41:1).
            </p>
            <div className="flex items-center">
              <div className="flex w-40 items-center gap-x-2">
                <p className="font-medium text-text-dark">Letterhead:</p>
                <Info>
                  <p className="p-2">
                    Supported formats: .jpg, .png.
                    <br />
                    Max file size: 3MB.
                  </p>
                </Info>
              </div>
              <FileInput
                name="letterheadUrl"
                onChange={(file) => handleFileUpload(file, 'letterhead')}
                onRemove={() => handleFileRemove('letterhead')}
                imageUrl={brandAssets?.letterhead?.src ?? ''}
                frameClassName="h-32 w-24"
              />
            </div>

            <FadeIn visible={hasLetterHead}>
              <div className="flex items-center pt-4">
                <h2 className="w-40 font-medium text-text-dark">Margins:</h2>
                <div className="grid grid-cols-2 gap-4">
                  <div className="w-24">
                    <TextField
                      name="letterheadLeftMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={LeftMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-24">
                    <TextField
                      name="letterheadTopMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={TopMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-24">
                    <TextField
                      name="letterheadRightMargin"
                      placeholder="24"
                      type="number"
                      LeftIcon={RightMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-24">
                    <TextField
                      name="letterheadBottomMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={BottomMarginIcon}
                      required
                    />
                  </div>
                </div>
              </div>
            </FadeIn>
          </div>
        )}
      </div>
    </Form>
  );
};
