export const GET_CUSTOMER_SUBSCRIPTION_QUERY = gql`
  query GetCustomerSubscription {
    getCustomerSubscription {
      id
      status
      stripeCustomerId
      expiresAt
      product {
        id
        name
      }
      licenses
      invoices {
        id
        status
        paid
        invoiceUrl
        amountPaid
        currency
        total
        effectiveDate
      }
    }
  }
`;
