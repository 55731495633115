/**
 * Formats a currency value based on whether the currency uses decimals or not.
 *
 * @param amount The amount to format, assumed to be in the smallest unit (e.g., cents for USD).
 * @param currency The currency code (e.g., 'USD', 'JPY').
 * @returns A formatted currency string based on the provided currency.
 * @description
 * - For zero-decimal currencies (e.g., JPY), the amount is treated as is.
 * - For other currencies, the amount is divided by 100 to convert from the smallest unit to standard (e.g., cents to dollars).
 * - The result is then formatted using `Intl.NumberFormat` with appropriate decimal places for the currency.
 */

export function formatCurrency(amount: number, currency: string): string {
  const zeroDecimalCurrencies = new Set([
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
  ]);

  // Determine if the currency has decimals
  const isZeroDecimal = zeroDecimalCurrencies.has(currency);

  // Convert amount from smallest unit to standard (i.e., cents to dollars)
  const formattedAmount = isZeroDecimal ? amount : amount / 100;

  // Use Intl.NumberFormat for proper currency formatting
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: isZeroDecimal ? 0 : 2,
    maximumFractionDigits: isZeroDecimal ? 0 : 2,
  }).format(formattedAmount);
}
