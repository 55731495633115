import { FC, useState, useRef, useLayoutEffect, Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { USER_ROLE } from 'types/graphql';
import { classNames, parseRole } from 'src/lib';

type UserRoleSelectProps = {
  value: USER_ROLE;
  onChange: (value: USER_ROLE) => void;
  disabled?: boolean;
};

const USER_ROLES: USER_ROLE[] = ['ADMIN', 'USER'];

export const UserRoleSelect: FC<UserRoleSelectProps> = ({ value, onChange, disabled }) => {
  const [expandDirection, setExpandDirection] = useState<'left' | 'right'>('right');
  const anchorRef = useRef<HTMLButtonElement>(null);

  // This effect is used to determine if the dropdown menu should expand to the left or right
  useLayoutEffect(() => {
    if (anchorRef.current) {
      const { right } = anchorRef.current.getBoundingClientRect();
      if (right < 200) {
        setExpandDirection('left');
      }
    }
  }, [anchorRef]);

  return (
    <div className="relative">
      <Listbox value={value} disabled={disabled} onChange={onChange}>
        {({ open }) => (
          <>
            <ListboxButton
              ref={anchorRef}
              className="-z-1 flex flex-row items-center gap-x-3 text-text-dark"
              disabled={disabled}
            >
              {!disabled && <ChevronDownIcon className="h-5 w-5 text-text-dark" />}
              {parseRole(value)}
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <ListboxOptions
                className={classNames(
                  expandDirection === 'left'
                    ? 'right-0 origin-top-right'
                    : 'left-0 origin-top-left',
                  'absolute z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                )}
              >
                {USER_ROLES.map((role) => (
                  <ListboxOption
                    key={role}
                    value={role}
                    className={({ active, selected }) =>
                      classNames(
                        'flex items-center gap-x-2 px-4 py-2 text-text-medium',
                        active ? 'bg-gray-100' : '',
                        selected ? 'text-text-veryDark' : 'hover:cursor-pointer'
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {parseRole(role)}
                        </span>
                        {selected && (
                          <CheckIcon className="h-5 w-5 text-text-dark" strokeWidth={2} />
                        )}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};
