import { FC } from 'react';
import { Spinner } from 'src/components';
import { classNames } from 'src/lib';
import { track } from 'src/services';

type ButtonProps = {
  variant?: 'primary' | 'outline' | 'danger';
  size?: 'xs' | 'small' | 'medium' | 'large' | 'mega';
  text: string;
  disabled?: boolean;
  LeftIcon?: FC<React.ComponentProps<'svg'>>;
  RightIcon?: FC<React.ComponentProps<'svg'>>;
  className?: string;
  leftIconClassName?: string;
  rightIconClassName?: string;
  loading?: boolean;
  onBlur?: () => void;
} & (
  | {
      onClick: () => void;
      type?: undefined;
    }
  | {
      type: 'submit' | 'reset' | 'button';
      onClick?: undefined;
    }
);

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'medium',
  disabled,
  LeftIcon,
  RightIcon,
  onClick,
  className,
  leftIconClassName,
  rightIconClassName,
  onBlur,
  loading,
  text,
  type = 'button',
}) => {
  const handleClick = () => {
    if (!disabled) {
      track('Button Click', { buttonName: text });
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      onBlur={onBlur}
      disabled={disabled || loading}
      className={classNames(
        'flex items-center justify-center',
        buttonColors[variant],
        buttonDimensions[size],
        className
      )}
    >
      {loading && (
        <span>
          <Spinner className={iconDimensions[size]} />
        </span>
      )}
      {LeftIcon && (
        <span>
          <LeftIcon className={classNames(iconDimensions[size], leftIconClassName)} />
        </span>
      )}
      <span>{text}</span>
      {RightIcon && (
        <span>
          <RightIcon className={classNames(iconDimensions[size], rightIconClassName)} />
        </span>
      )}
    </button>
  );
};

/**
 * Also used in DropdownButton
 */
export const buttonColors: Record<Required<ButtonProps>['variant'], string> = {
  primary:
    'bg-generate-medium text-white disabled:bg-text-medium disabled:text-text-light hover:bg-generate-light focus:ring-generate-medium focus:ring-1 focus:outline-none focus:ring focus:ring-offset-1 gap-x-3',
  danger:
    'bg-error-medium text-white disabled:bg-text-medium disabled:text-text-light hover:bg-error-light focus:ring-error-medium focus:ring-1 focus:outline-none focus:ring focus:ring-offset-1',
  outline:
    'bg-white border border-gray-300 text-text-dark disabled:bg-text-light disabled:text-text-dark hover:bg-gray-50 hover:text-text-medium focus:ring-primary-medium focus:ring-1 focus:outline-none focus:ring focus:ring-offset-1',
};

/**
 * Also used in DropdownButton
 */
export const buttonDimensions: Record<Required<ButtonProps>['size'], string> = {
  xs: 'py-1 px-1 rounded-md text-xs gap-x-1',
  small: 'py-2 px-3 rounded-lg text-sm gap-x-1',
  large: 'py-3 px-6 rounded-lg text-medium gap-x-3',
  medium: 'px-4 py-2 rounded-lg text-medium gap-x-2',
  mega: 'rounded-2xl py-4 px-8 text-medium',
};
export const iconDimensions: Record<Required<ButtonProps>['size'], string> = {
  xs: 'h-3 w-3',
  small: 'h-4 w-4',
  large: 'h-6 w-6',
  medium: 'h-6 w-6',
  mega: 'h-6 w-6',
};
