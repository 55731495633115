import { FC } from 'react';
import { ControllerProps, Controller } from 'react-hook-form';
import { ZodSchema } from 'zod';

import { isFieldRequired } from 'src/lib/zod';
import { Autocomplete, AutocompleteProps } from './Autocomplete';

export const AutocompleteField: FC<
  Pick<AutocompleteProps<string>, 'label' | 'name' | 'options' | 'placeholder'> &
    Pick<ControllerProps, 'name'> & {
      schema?: ZodSchema;
      onFocus?: () => void;
      onBlur?: () => void;
      disabled?: boolean;
    }
> = ({ options, name, label, schema, onFocus, onBlur, placeholder, disabled }) => {
  const blurWrapper = (fn: (...args: unknown[]) => void) => {
    return (...args: unknown[]) => {
      onBlur && onBlur();
      fn(...args);
    };
  };

  const isRequired = schema && isFieldRequired(schema, name);

  return (
    <Controller
      name={name}
      rules={{ required: isRequired || false }}
      render={({ field: { name, onBlur, onChange, value, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          getOptionKey={(option) => option}
          disabled={disabled}
          getOptionLabel={(option) => option}
          queryToOption={(query) => query}
          filterOptions={(options, query) =>
            options?.filter((option) => option.toLowerCase().includes(query?.toLowerCase()))
          }
          placeholder={placeholder}
          label={label}
          name={name}
          onFocus={onFocus}
          onBlur={blurWrapper((q) => {
            onChange(q);
            onBlur();
          })}
          value={value}
          options={options}
          onChange={onChange}
          error={!!error}
          required={isRequired}
        />
      )}
    />
  );
};
