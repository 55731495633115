export const EXTRACT_JOB_FILE_MUTATION = gql`
  mutation ExtractJobFileMutation($input: ExtractFileInput!) {
    extractJobFile(input: $input) {
      jobTitle
      companyName
      companyBenefits
      companyCulture
      companyOverview
      jobRequirements
      jobResponsibilities
      location
      contract
      salary
    }
  }
`;
