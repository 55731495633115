import { useController, useFormContext } from 'react-hook-form';
import { RadioGroup, Field, Radio } from '@headlessui/react';
import { JsonSchemaEnum } from './schema';
import { Info } from '../Info';
import { FC } from 'react';
import { Tooltip } from '../Tooltip';
import { Icons } from './Icons';

type Props = {
  schema: JsonSchemaEnum;
  name: string;
};

/**
 * Works like a select field with 2 varaitions:
 * 1. It uses radio buttons instead of a dropdown. Tooltip is used to show the description
 * 2. It's an IconButton with a tooltip instead of a text label. Tooltip will present the label and description
 */
export const EnumField: React.FC<Props> = ({ name, schema }) => {
  const { control } = useFormContext();
  const defaultValue = schema.default;

  const options = schema.enum.map((value) => {
    const details = schema.enumDetails.find((i) => i.value === value);
    return {
      value,
      label: details?.label ?? value,
      description: details?.description,
      Icon: details?.Icon ? Icons[details.Icon] : null,
    };
  });

  const { field, formState } = useController({
    name,
    control,
    defaultValue,
  });

  const error = formState.errors[name];

  return (
    <div className="flex items-center gap-x-10">
      <RadioGroup value={field.value} onChange={field.onChange} className="flex gap-4">
        {options.map(({ value, label, description, Icon }) => (
          <Field key={value}>
            {Icon ? (
              <IconRadioOption Icon={Icon} label={label} description={description} value={value} />
            ) : (
              <RadioOption label={label} description={description} value={value} />
            )}
          </Field>
        ))}
      </RadioGroup>

      {error && <div className="text-error-medium">{error.message as unknown as string}</div>}
    </div>
  );
};

const RadioOption: FC<{
  value: string;
  label: string;
  description?: string;
  Icon?: React.FC | null;
}> = ({ label, value, description }) => {
  return (
    <Radio
      value={value}
      className={({ checked }) =>
        `flex cursor-pointer items-center gap-0.5 rounded border px-2 py-1
    ${
      checked
        ? 'border-sky-300 bg-sky-200 text-text-dark'
        : ' bg-gray-200 text-text-medium hover:bg-sky-100 '
    }
    `
      }
    >
      {({ checked }) => (
        <>
          <span
            className={`text-sm font-medium ${checked ? 'text-text-dark' : 'text-text-medium'}`}
          >
            {label}
          </span>

          {description && (
            <Info iconType="filled" size="small" color="gray">
              {description}
            </Info>
          )}
        </>
      )}
    </Radio>
  );
};

const IconRadioOption: FC<{
  value: string;
  label: string;
  description?: string;
  Icon: React.FC<{ className: string }>;
}> = ({ label, value, Icon, description }) => {
  return (
    <Tooltip
      innerBody={
        <div>
          <p>{label}</p>
          {!!description && <p>{description}</p>}
        </div>
      }
    >
      <Radio
        value={value}
        className={({ checked }) =>
          `flex cursor-pointer items-center rounded border p-0.5
    ${
      checked
        ? 'border-sky-300 bg-sky-200 text-text-dark'
        : ' bg-gray-200 text-text-medium hover:bg-sky-100 '
    }
    `
        }
      >
        <Icon className="h-5 w-5" />
      </Radio>
    </Tooltip>
  );
};
