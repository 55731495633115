import { FC } from 'react';
import { DocumentEditor } from '../../components';
import { usePageClasses } from '../../hooks';
import { Document } from '../../../types/graphql';

type Props = {
  document: Omit<Document, 'history' | 'config' | 'permissions' | 'owner' | 'status'>;
};

export const GeneralDocument: FC<Props> = ({ document }) => {
  usePageClasses('bg-pageGray');

  return (
    <div className="flex min-h-0 flex-grow justify-center">
      <div className="flex w-full max-w-5xl flex-grow ">
        <DocumentEditor id={document.id} />
      </div>
    </div>
  );
};
