import { FC, useState } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useQuery } from '@redwoodjs/web';
import { ZodSchema } from 'zod';
import { isFieldRequired } from 'src/lib/zod';
import { useDebounce, useReadOnlyForm } from '../../hooks';
import { GetCompanies, GetCompaniesVariables } from '../../../types/graphql';
import { GET_COMPANIES_QUERY } from '../../graphql/queries';
import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { ReadOnlyField } from '../ReadOnlyField';
import { BuildingOfficeIcon } from '@heroicons/react/20/solid';

type CompanyAutocompleteValue = { id?: string; name: string };

export const CompanyAutocompleteField: FC<
  Pick<AutocompleteProps<CompanyAutocompleteValue>, 'label' | 'name' | 'placeholder'> &
    Pick<ControllerProps, 'name'> & {
      schema?: ZodSchema;
      onFocus?: () => void;
      onBlur?: () => void;
      disabled?: boolean;
    }
> = ({ schema, name, label, disabled, onFocus, onBlur, placeholder }) => {
  const [query, setQuery] = useState('');

  const { readOnly } = useReadOnlyForm();

  const isRequired = schema && isFieldRequired(schema, name);

  const blurWrapper = (fn: (...args: unknown[]) => void) => {
    return (...args: unknown[]) => {
      onBlur && onBlur();
      fn(...args);
    };
  };

  const debouncedQuery = useDebounce(query, 500);
  const { data, previousData, loading, error } = useQuery<GetCompanies, GetCompaniesVariables>(
    GET_COMPANIES_QUERY,
    {
      variables: {
        input: {
          searchTerm: debouncedQuery,
          connection: {
            take: 10,
          },
          accessLevel: 'WRITE',
        },
      },
    }
  );

  const options = data?.companies?.nodes || previousData?.companies?.nodes || [];

  if (error) {
    throw error;
  }

  const { watch } = useFormContext();

  const readOnlyValue = watch(name)?.name;

  return readOnly ? (
    <>
      <ReadOnlyField name={name} label={label} value={readOnlyValue} />
    </>
  ) : (
    <Controller
      name={name}
      rules={{ required: isRequired || false }}
      render={({ field: { name, onBlur, onChange, value, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          LeftIcon={BuildingOfficeIcon}
          disabled={disabled}
          getOptionKey={(option) => option?.id || ''}
          getOptionLabel={(option) => option?.name || ''}
          queryToOption={(query) => ({ name: query, id: null })}
          placeholder={placeholder}
          label={label}
          name={name}
          onFocus={onFocus}
          onQueryChange={setQuery}
          onBlur={blurWrapper((v) => {
            // If the user has entered a value that is not in the list of options, and tabbed away, we want to save that value.
            onChange({ id: null, name: v });
            onBlur();
          })}
          value={value}
          options={options}
          onChange={onChange}
          loading={loading && !previousData}
          error={!!error}
          required
        />
      )}
    />
  );
};
