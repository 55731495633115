import Worker from 'src/worker';
import { EntityData, EntityName } from '../types';
import { saveAs } from 'file-saver';
import { ApolloClient } from '@apollo/client';
import { GENERATE_BRANDED_DOCUMENT_QUERY, GENERATE_CV_PDF_QUERY } from 'src/graphql/queries';
import {
  GenerateBrandedDocumentQuery,
  GenerateBrandedDocumentQueryVariables,
  GenerateCvPdfQuery,
  GenerateCvPdfQueryVariables,
} from 'types/graphql';

export const handleExport = async (
  client: ApolloClient<unknown>,
  entityData: EntityData | undefined | null,
  entityName: EntityName,
  includeDocuments: boolean
) => {
  if (!entityData) throw new Error('No data available for export');
  const workerInstance = new Worker();

  try {
    if (entityData?.documents && includeDocuments) {
      const pdfItemsWithCanvases = await Promise.all(
        entityData.documents.map(async (document) => {
          if (document.__typename === 'CandidateCv') {
            const { __typename, ...structuredMarkup } = document.structuredMarkup;
            const result = await client.query<GenerateCvPdfQuery, GenerateCvPdfQueryVariables>({
              query: GENERATE_CV_PDF_QUERY,
              variables: {
                input: {
                  ...structuredMarkup,
                  certifications: structuredMarkup.certifications?.map(({ __typename, ...c }) => c),
                  experience: structuredMarkup.experience?.map(({ __typename, ...e }) => e),
                  education: structuredMarkup.education?.map(({ __typename, ...ed }) => ed),
                  skills: structuredMarkup.skills?.map(({ __typename, ...s }) => s),
                  achievements: structuredMarkup.achievements?.map(({ __typename, ...a }) => a),
                  interests: structuredMarkup.interests?.map(({ __typename, ...i }) => i),
                  languages: structuredMarkup.languages?.map(({ __typename, ...l }) => l),
                },
              },
            });
            return {
              name: `${document.title || document.__typename}.pdf`,
              content: result?.data?.generateCvPdf?.pdfContent,
            };
          }
          const result = await client.query<
            GenerateBrandedDocumentQuery,
            GenerateBrandedDocumentQueryVariables
          >({
            query: GENERATE_BRANDED_DOCUMENT_QUERY,
            variables: {
              input: {
                documentId: document.id,
              },
            },
          });
          return {
            name: `${document.title || document.__typename}.pdf`,
            content: result.data.generateBrandedDocument.pdfContent,
          };
        })
      );

      const stringifiedData = JSON.stringify({
        entityData: { ...entityData, documents: pdfItemsWithCanvases },
        entityName,
      });

      const formattedData = await workerInstance.formatEntityDocuments(stringifiedData);
      if (formattedData) {
        saveAs(formattedData);
      }
    } else {
      const stringifiedData = JSON.stringify({
        entityData,
        entityName,
      });

      const results = await workerInstance.formatEntity(stringifiedData);

      if (results?.data) {
        saveAs(new Blob([results.data], { type: 'text/plain' }), `${results.fileName}`);
      }
    }
  } catch (error) {
    console.error('Error in handleExport:', error);
    throw error;
  }
};
