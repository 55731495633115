import { BriefcaseIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { FC, useEffect, useRef } from 'react';
import { Link, SelectField, TextAreaField, TextField } from '../../components';
import { DropdownButton } from '../../components/DropdownButton';
import {
  TCompanyCampaignDocumentInput,
  TJobCampaignDocumentInput,
} from '../../lib/documentGeneration/validators';
import {
  JobFormValues,
  jobFormSchema,
  CompanyFormValues,
  companyFormSchema,
  candidateFormSchema,
  contractOptions,
  BlogPostFormValues,
  TestimonialRequestFormValues,
  MarketingStrategyFormValues,
  blogPostFormSchema,
  testimonialRequestFormSchema,
  CandidateFormValues,
} from '../../lib/formSchemas';
import { jobCampaignScenarios } from './inputScenarios';
import { State } from './PromptEngineeringPage';
import { classNames } from '../../lib';
import { Titlebar } from './Titlebar';
import { DocumentType } from '../../lib/document';
import { Document } from '../../../types/graphql';
import { CompanyAutocompleteField } from '../../components/Autocomplete';
import { EnumField } from '../../components/DocumentConfigForm/EnumField';
import { Form } from 'src/components/Form/Form';
import { RichTextField } from 'src/components/RichTextField';
import { Accordion } from 'src/components/Accordion';
import { DatePickerField } from 'src/components/DatePicker';
import { IconButton } from 'src/components/IconButton';

type Props = {
  docType: Document['__typename'];
  state: State;
  setState: (state: State) => void;
};

export const InputEditor: FC<Props> = ({ state, setState }) => {
  return (
    <div className={classNames('flex flex-col overflow-auto border border-text-light')}>
      <Titlebar>Input</Titlebar>
      <div className="flex flex-col px-6 py-2">
        <InputForm
          docType={state.docType}
          inputValues={state.inputValues}
          onChange={(v) => setState({ ...state, inputValues: v })}
        />
      </div>
    </div>
  );
};

export const InputForm: FC<{
  docType: DocumentType;
  onChange: (value: Record<string, unknown>) => void;
  inputValues: Record<string, unknown>;
}> = ({ docType, onChange, inputValues }) => {
  switch (docType) {
    case 'JobAdvert':
    case 'JobBooleanSearch':
    case 'JobInterviewPreparation':
    case 'JobInterviewQuestions':
    case 'JobSnapshot':
    case 'JobReferenceCheck':
    case 'JobSocialPost':
    case 'JobInMail':
    case 'JobEmail':
      return <JobForm values={inputValues as JobFormValues} onChange={onChange} />;
    case 'CompanySnapshot':
    case 'CompanyIntroduction':
      return (
        <CompanyForm values={inputValues as TCompanyCampaignDocumentInput} onChange={onChange} />
      );
    case 'CandidateSnapshot':
    case 'CandidateIntroduction':
    case 'CandidateCv':
      // TODO: When new candidate form is done, replace CandidateForm with it
      return <CandidateForm values={inputValues as any} onChange={onChange} />;
    case 'BlogPost':
      return <BlogPostForm values={inputValues as BlogPostFormValues} onChange={onChange} />;
    case 'BlogPostIdeas':
      return (
        <BlogPostIdeasForm values={inputValues as BlogPostIdeasFormValues} onChange={onChange} />
      );
    case 'TestimonialRequest':
      return (
        <TestimonialRequestForm
          values={inputValues as TestimonialRequestFormValues}
          onChange={onChange}
        />
      );
    case 'MarketingStrategy':
      return (
        <MarketingStrategyForm
          values={inputValues as MarketingStrategyFormValues}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};

export function jobFormToInputValues(values: JobFormValues): TJobCampaignDocumentInput {
  return {
    job: {
      company: {
        name: values.company.name,
        location: values.location ?? null,
        overview: values.overview,
        culture: values.culture,
        benefits: values.benefits ?? null,
      },
      title: values.title,
      salary: values.salary ?? null,
      contractType: values.contract,
      mustHaves: values.mustHaves,
      roleAndResponsibilities: values.responsibilities,
    },
  };
}

function inputValuesToJobForm(inputValues: TJobCampaignDocumentInput): JobFormValues {
  const job = inputValues.job;
  return {
    company: {
      name: inputValues.job.company.name,
    },
    title: job.title,
    salary: job.salary,
    contract: job.contractType as (typeof contractOptions)[number],
    location: job.company.location,
    overview: job.company.overview,
    culture: job.company.culture,
    benefits: job.company.benefits,
    mustHaves: job.mustHaves,
    responsibilities: job.roleAndResponsibilities,
  };
}

const JobForm: FC<{
  values?: JobFormValues;
  onChange: (values: TJobCampaignDocumentInput) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<JobFormValues>({
    defaultValues: jobCampaignScenarios[0].input,
    resolver: zodResolver(jobFormSchema),
  });
  formMethods.watch((values) => onChange(jobFormToInputValues(values as JobFormValues)));

  useEffect(() => {
    onChange(jobFormToInputValues(formMethods.getValues()));
  }, []);

  return (
    <Form<JobFormValues> formMethods={formMethods} className="flex flex-grow flex-col gap-y-6">
      <DropdownButton
        buttonText="Select Preset"
        options={jobCampaignScenarios.map((s) => ({
          Icon: BriefcaseIcon,
          onClick: () => formMethods.reset(s.input),
          text: s.name,
        }))}
      />
      <TextAreaField
        className="min-h-[100px]"
        name="company.name"
        label="Company Name"
        schema={jobFormSchema}
      />

      <TextAreaField
        schema={jobFormSchema}
        className="min-h-[100px]"
        name="location"
        label="Location"
      />
      <TextAreaField
        schema={jobFormSchema}
        className="min-h-[250px]"
        name="overview"
        label="Overview"
      />
      <TextAreaField
        className="min-h-[250px]"
        name="culture"
        label="Culture"
        schema={jobFormSchema}
      />
      <TextAreaField
        className="min-h-[250px]"
        name="benefits"
        label="Benefits"
        schema={jobFormSchema}
      />
      <TextAreaField
        className="min-h-[100px]"
        name="title"
        label="Job Title"
        schema={jobFormSchema}
      />
      <TextAreaField label="Salary" className="min-h-[250px]" name="salary" />
      <TextAreaField label="Contract Type" className="min-h-[250px]" name="contract" />
      <TextAreaField
        className="min-h-[250px]"
        name="responsibilities"
        label="Role and Responsibilities"
        schema={jobFormSchema}
      />
      <TextAreaField
        className="min-h-[250px]"
        name="mustHaves"
        label="Must Haves"
        schema={jobFormSchema}
      />
    </Form>
  );
};

// TODO: Replace any with CandidateFormValues
function candidateFormToInputValues(input: CandidateFormValues): CandidateFormValues {
  return {
    name: input.name,
    jobTitle: input.jobTitle,
    notes: input.notes,
    availability: input.availability,
    currentSalary: input.currentSalary,
    desiredJobTitle: input.desiredJobTitle,
    desiredSalary: input.desiredSalary,
    location: input.location,
    refId: input.refId,
    profileSummary: input.profileSummary,
    experience: input.experience,
    education: input.education,
    skills: input.skills,
    achievements: input.achievements,
  };
}

const defaultCandidateFormValues: CandidateFormValues = {
  name: '',
  jobTitle: '',
  notes: '',
  availability: '',
  currentSalary: '',
  desiredJobTitle: '',
  desiredSalary: '',
  location: '',
  refId: '',
  profileSummary: '',
  experience: [],
  education: [],
  skills: [],
  achievements: [],
};

const CandidateForm: FC<{
  values?: CandidateFormValues;
  onChange: (data: { candidate: CandidateFormValues }) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<CandidateFormValues>({
    defaultValues: values ?? defaultCandidateFormValues,
    resolver: zodResolver(candidateFormSchema),
  });

  const {
    fields: experienceFields,
    append: appendExperience,
    remove: removeExperience,
  } = useFieldArray({ control: formMethods.control, name: 'experience', keyName: 'fieldId' });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({ control: formMethods.control, name: 'education', keyName: 'fieldId' });

  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({ control: formMethods.control, name: 'skills', keyName: 'fieldId' });

  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievement,
  } = useFieldArray({ control: formMethods.control, name: 'achievements', keyName: 'fieldId' });

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  formMethods.watch((values) => onChange({ candidate: values as CandidateFormValues }));
  /**
   * Reset values to default on mount
   */
  useEffect(() => {
    onChange({
      candidate: defaultCandidateFormValues,
      // TODO: Replace any with CandidateFormValues
    } as any);
  }, []);

  return (
    <Form<CandidateFormValues>
      formMethods={formMethods}
      onSubmit={(v) => onChange({ candidate: v })}
    >
      <div className="flex flex-1 flex-col gap-y-3 overflow-auto  ">
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <TextField
            label="Candidate Name"
            name="name"
            placeholder="Jane Doe"
            schema={candidateFormSchema}
            className="min-w-none w-full"
          />
          <TextField
            label="Reference ID"
            name="refId"
            placeholder="e.g CRM ref"
            schema={candidateFormSchema}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <TextField label="Job Title" name="jobTitle" schema={candidateFormSchema} />
          <TextField
            label="Desired Job Title"
            name="desiredJobTitle"
            schema={candidateFormSchema}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <TextField label="Current Salary" name="currentSalary" />
          <TextField label="Desired Salary" name="desiredSalary" />
        </div>
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <TextField label="Location" name="location" />
          <TextField label="Availability" name="availability" placeholder="4 weeks notice" />
        </div>

        <RichTextField
          label="Notes"
          name="notes"
          placeholder="Your notes"
          schema={candidateFormSchema}
          visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
        />

        <div className="flex flex-col space-y-2 pt-2">
          <h2 className="text-lg font-semibold text-text-veryDark">Relevant Experience</h2>
          {experienceFields.map((field, index) => {
            const startDateName = `experience.${index}.startDate` as const;
            const endDateName = `experience.${index}.endDate` as const;

            return (
              <div key={field.id} className="relative">
                <div className="flex items-center justify-between gap-2">
                  <Accordion
                    headline={formMethods.watch(`experience.${index}.title`) || 'Untitled'}
                    subtext={
                      formMethods.watch(startDateName) || formMethods.watch(endDateName)
                        ? `${formMethods.watch(startDateName) || ''}${
                            formMethods.watch(endDateName)
                              ? ` - ${formMethods.watch(endDateName)}`
                              : ''
                          }`
                        : ''
                    }
                  >
                    <div className="flex flex-col justify-center gap-3">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <TextField
                          label="Job Title"
                          name={`experience.${index}.title`}
                          defaultValue={field.title}
                          required
                        />
                        <TextField
                          label="Company"
                          name={`experience.${index}.company`}
                          defaultValue={field.company}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <DatePickerField label="Start Date" name={startDateName} />
                        <DatePickerField
                          label="End Date"
                          name={endDateName}
                          startDateName={startDateName}
                        />
                      </div>
                      <TextField
                        label="Location"
                        name={`experience.${index}.location`}
                        defaultValue={field.location ?? ''}
                      />
                      <RichTextField
                        label="Description"
                        name={`experience.${index}.description`}
                        placeholder="Describe your experience"
                        schema={candidateFormSchema}
                        visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                      />
                    </div>
                  </Accordion>
                  <IconButton
                    Icon={TrashIcon}
                    onClick={() => removeExperience(index)}
                    tooltipText="Delete"
                    className="hover:text-generate-medium"
                  />
                </div>
              </div>
            );
          })}
          <Link
            size="medium"
            className="text-generate-medium"
            iconClassName="text-generate-medium"
            LeftIcon={PlusIcon}
            onClick={() => {
              appendExperience({
                title: '',
                company: '',
                startDate: new Date(),
                endDate: null,
                location: '',
                description: '',
              });
            }}
          >
            Add Experience
          </Link>
        </div>

        <div className="flex flex-col space-y-2 pt-2">
          <h2 className="text-lg font-semibold text-text-veryDark">Education</h2>
          {educationFields.map((field, index) => {
            const startDateName = `education.${index}.startDate` as const;
            const endDateName = `education.${index}.endDate` as const;

            return (
              <div key={field.id} className="relative">
                <div className="flex items-center justify-between gap-2">
                  <Accordion
                    headline={formMethods.watch(`education.${index}.title`) || 'Untitled'}
                    subtext={
                      formMethods.watch(startDateName) || formMethods.watch(endDateName)
                        ? `${formMethods.watch(startDateName) || ''}${
                            formMethods.watch(endDateName)
                              ? ` - ${formMethods.watch(endDateName)}`
                              : ''
                          }`
                        : ''
                    }
                  >
                    <div className="flex flex-col justify-center gap-3">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <TextField
                          label="Title"
                          name={`education.${index}.title`}
                          defaultValue={field.title}
                          required
                        />
                        <TextField
                          label="Institution"
                          name={`education.${index}.institution`}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <DatePickerField
                          label="Start Date"
                          name={startDateName}
                          maxDate={new Date()}
                        />
                        <DatePickerField
                          label="End Date"
                          name={endDateName}
                          startDateName={startDateName}
                        />
                      </div>
                      <TextField
                        label="Location"
                        name={`education.${index}.location`}
                        defaultValue={field.location ?? ''}
                      />
                      <RichTextField
                        label="Description"
                        name={`education.${index}.description`}
                        placeholder="Describe your education"
                        schema={candidateFormSchema}
                        visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                      />
                    </div>
                  </Accordion>
                  <IconButton
                    Icon={TrashIcon}
                    onClick={() => removeEducation(index)}
                    tooltipText="Delete"
                    className="hover:text-generate-medium"
                  />
                </div>
              </div>
            );
          })}
          <Link
            size="medium"
            className="text-generate-medium"
            iconClassName="text-generate-medium"
            LeftIcon={PlusIcon}
            onClick={() => {
              appendEducation({
                title: '',
                institution: '',
                location: '',
                description: '',
                startDate: new Date(),
                endDate: null,
              });
            }}
          >
            Add Education
          </Link>
        </div>

        <div className="flex flex-col space-y-2 pt-2">
          <h2 className="text-lg font-semibold text-text-veryDark">Skills and Attributes</h2>
          {skillFields.map((field, index) => {
            return (
              <div key={field.id} className="relative">
                <div className="flex items-center justify-between gap-2">
                  <Accordion
                    headline={formMethods.watch(`skills.${index}.description`) || 'Untitled'}
                  >
                    <div className="flex flex-col justify-center gap-3">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <TextField
                          label="Skill Description"
                          name={`skills.${index}.title`}
                          defaultValue={field.title}
                          required
                        />
                      </div>
                    </div>
                  </Accordion>
                  <IconButton
                    Icon={TrashIcon}
                    onClick={() => removeSkill(index)}
                    tooltipText="Delete"
                    className="hover:text-generate-medium"
                  />
                </div>
              </div>
            );
          })}
          <Link
            size="medium"
            className="text-generate-medium"
            iconClassName="text-generate-medium"
            LeftIcon={PlusIcon}
            onClick={() => {
              appendSkill({
                title: '',
                description: '',
              });
            }}
          >
            Add Skill
          </Link>
        </div>

        <div className="flex flex-col space-y-2 pt-2">
          <h2 className="text-lg font-semibold text-text-veryDark">Achievements</h2>
          {achievementFields.map((field, index) => {
            return (
              <div key={field.id} className="relative">
                <div className="flex items-center justify-between gap-2">
                  <Accordion
                    headline={formMethods.watch(`achievements.${index}.description`) || 'Untitled'}
                  >
                    <div className="flex flex-col justify-center gap-3">
                      <TextAreaField
                        label="Description"
                        name={`achievements.${index}.description`}
                        placeholder="Describe the achievement"
                        className="min-w-none w-full"
                        required
                      />
                    </div>
                  </Accordion>
                  <IconButton
                    Icon={TrashIcon}
                    onClick={() => removeAchievement(index)}
                    tooltipText="Delete"
                    className="hover:text-generate-medium"
                  />
                </div>
              </div>
            );
          })}
          <Link
            size="medium"
            className="text-generate-medium"
            iconClassName="text-generate-medium"
            LeftIcon={PlusIcon}
            disabled={achievementFields.length >= 5}
            onClick={() => {
              appendAchievement({
                description: '',
              });
            }}
          >
            Add Achievement
          </Link>
        </div>

        <div className="flex flex-col space-y-2 pt-2">
          <h2 className="text-lg font-semibold text-text-veryDark">Profile</h2>
          <RichTextField
            label="Profile Summary"
            name="profileSummary"
            placeholder="Dear Sir/Madam,"
            schema={candidateFormSchema}
            visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
          />
        </div>
      </div>
    </Form>
  );
};

const defaultCompanyFormValues: CompanyFormValues = {
  benefits: '',
  culture: '',
  location: '',
  name: '',
  overview: '',
  notes: '',
  website: '',
};

const CompanyForm: FC<{
  values?: TCompanyCampaignDocumentInput;
  onChange: (data: TCompanyCampaignDocumentInput) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<CompanyFormValues>({
    defaultValues: values?.company ?? defaultCompanyFormValues,
    resolver: zodResolver(companyFormSchema),
  });
  formMethods.watch((values) => onChange({ company: values } as TCompanyCampaignDocumentInput));
  /**
   * Reset values to default on mount
   */
  useEffect(() => {
    onChange({
      company: defaultCompanyFormValues,
    } as TCompanyCampaignDocumentInput);
  }, []);

  return (
    <Form formMethods={formMethods}>
      <TextAreaField
        className="min-h-[100px]"
        name="name"
        label="Name"
        schema={companyFormSchema}
      />
      <TextAreaField
        label="Website"
        className="min-h-[100px]"
        name="website"
        schema={companyFormSchema}
      />
      <TextAreaField label="Location" className="min-h-[100px]" name="location" />
      <TextAreaField
        className="min-h-[300px]"
        name="overview"
        label="Overview"
        schema={companyFormSchema}
      />
      <TextAreaField
        className="min-h-[300px]"
        name="culture"
        label="Culture"
        schema={companyFormSchema}
      />
      <TextAreaField
        className="min-h-[300px]"
        name="benefits"
        label="Benefits"
        schema={companyFormSchema}
      />
      <TextAreaField className="min-h-[300px]" name="notes" label="Notes" />
    </Form>
  );
};

const defaultBlogPostFormValues: BlogPostFormValues = {
  type: 'Informative',
  targetAudience: '',
  industry: '',
  topic: '',
  informationToInclude: '',
};

const BlogPostForm: FC<{
  values?: BlogPostFormValues;
  onChange: (data: BlogPostFormValues) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<BlogPostFormValues>({
    defaultValues: defaultBlogPostFormValues,
  });
  formMethods.watch((values) => onChange(values as BlogPostFormValues));

  useEffect(() => {
    onChange(defaultBlogPostFormValues);
  }, []);

  const selectOptions = [
    'Informative',
    'Industry News & Insights',
    'Tips and Guidance',
    'Storytelling and Anecdotal',
    'Most Appropriate',
  ].map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <Form formMethods={formMethods}>
      <SelectField options={selectOptions} name="type" label="Type" />
      <TextField
        name="targetAudience"
        label="Target Audience"
        helpText="Who is the audience?"
        placeholder="e.g Architecture Directors"
        schema={blogPostFormSchema}
      />
      <TextField
        name="industry"
        label="Industry"
        helpText="What industry do the audience work in?"
        placeholder="e.g Architecture"
        schema={blogPostFormSchema}
      />
      <TextField name="description" label="Description" />
      <TextField name="topic" label="Topic" className="min-h-[200px]" />

      <TextAreaField
        name="informationToInclude"
        label="Reference Information"
        className="min-h-[200px]"
      />
    </Form>
  );
};

type BlogPostIdeasFormValues = {
  industry: string;
  clients: string;
  candidates: string;
};

const defaultBlogPostIdeasFormValues: BlogPostIdeasFormValues = {
  industry: '',
  clients: '',
  candidates: '',
};

const BlogPostIdeasForm: FC<{
  values?: BlogPostIdeasFormValues;
  onChange: (data: BlogPostIdeasFormValues) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<BlogPostIdeasFormValues>({
    defaultValues: defaultBlogPostIdeasFormValues,
  });
  formMethods.watch((values) => onChange(values as BlogPostIdeasFormValues));

  useEffect(() => {
    onChange(defaultBlogPostIdeasFormValues);
  }, []);

  return (
    <Form formMethods={formMethods}>
      <TextField name="industry" label="Industry" />
      <TextAreaField name="clients" label="Clients" />
      <TextAreaField name="candidates" label="Candidates" />
    </Form>
  );
};

const defaultTestimonialRequestFormValues: TestimonialRequestFormValues = {
  recipient: 'candidate',
  recipientName: 'John Doe',
  companyName: {
    name: 'PSR Solutions',
  },
  candidateName: null,
  servicesProvided: 'Placed an architect 6 months ago',
};

const TestimonialRequestForm: FC<{
  values?: TestimonialRequestFormValues;
  onChange: (
    data: Omit<TestimonialRequestFormValues, 'companyName'> & {
      companyName: string;
    }
  ) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<TestimonialRequestFormValues>({
    defaultValues: defaultTestimonialRequestFormValues,
  });
  formMethods.watch(({ companyName, ...values }) => {
    onChange({
      companyName: companyName?.name ?? '',
      ...values,
      recipient: values.recipient ?? 'client',
      recipientName: values.recipientName ?? '',
    });
  });

  useEffect(() => {
    onChange({
      ...defaultTestimonialRequestFormValues,
      companyName: defaultTestimonialRequestFormValues.companyName.name,
    });
  }, []);

  const recipient = formMethods.watch('recipient');

  const servicePlaceholderText =
    recipient === 'candidate'
      ? 'E.g. Placed in an Architect role'
      : 'E.g. Placed an Architectural Technician';

  const recipientNameLabel = recipient === 'candidate' ? 'Candidate Name' : 'Recipient Name';

  const companyLabel = recipient === 'candidate' ? 'Candidate Company Name' : 'Client Company Name';

  return (
    <Form formMethods={formMethods}>
      <EnumField
        schema={{
          default: 'client',
          enum: ['client', 'candidate'],
          title: 'Recipient',
          type: 'string',
          description: 'Who is the testimonial for?',
          enumDetails: [
            { label: 'Client', value: 'client' },
            { label: 'Candidate', value: 'candidate' },
          ],
          order: 0,
        }}
        name="recipient"
      />
      <CompanyAutocompleteField
        name="companyName"
        label={companyLabel}
        schema={testimonialRequestFormSchema}
      />
      <TextField
        name="recipientName"
        label={recipientNameLabel}
        schema={testimonialRequestFormSchema}
      />
      <TextField name="duration" label="Duration of Partnership" placeholder="6 Months" />
      <TextAreaField
        name="servicesProvided"
        label="Specific Services Provided"
        className="min-h-[200px]"
        placeholder={servicePlaceholderText}
      />
    </Form>
  );
};

const defaultMarketingStrategyFormValues: MarketingStrategyFormValues = {
  industry: '',
  targetClients: '',
  targetCandidates: '',
};

const MarketingStrategyForm: FC<{
  values?: MarketingStrategyFormValues;
  onChange: (data: MarketingStrategyFormValues) => void;
}> = ({ values, onChange }) => {
  const formMethods = useForm<MarketingStrategyFormValues>({
    defaultValues: defaultBlogPostIdeasFormValues,
  });
  formMethods.watch((values) => onChange(values as MarketingStrategyFormValues));

  useEffect(() => {
    onChange(defaultMarketingStrategyFormValues);
  }, []);

  return (
    <Form formMethods={formMethods}>
      <TextField name="industry" label="Industry" />
      <TextAreaField name="targetClients" label="Clients" />
      <TextAreaField name="targetCandidates" label="Candidates" />
    </Form>
  );
};
