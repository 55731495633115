export const EXTRACT_COMPANY_FILE_MUTATION = gql`
  mutation ExtractCompanyFileMutation($input: ExtractFileInput!) {
    extractCompanyFile(input: $input) {
      name
      benefits
      culture
      location
      notes
      overview
      website
    }
  }
`;
