import { FC, useState } from 'react';
import {
  Document,
  DocumentTemplate,
  GetDocumentTemplatesQuery,
  GetDocumentTemplatesQueryVariables,
  Maybe,
} from 'types/graphql';
import { DialogLayout } from '../DialogLayout';
import { documentPresentationalProperties } from 'src/lib/document';
import { Select } from '../SelectField/SelectField';
import { DocumentConfigForm } from '../DocumentConfigForm';
import { useQuery } from '@redwoodjs/web';
import { GET_DOCUMENT_TEMPLATES_QUERY } from 'src/graphql/queries/getDocumentTemplatesQuery';
import { notNullish } from 'src/lib/guards';

export const ChangeStyleDialog: FC<{
  template?: Maybe<Pick<DocumentTemplate, 'configSchema' | 'defaultConfig' | 'config' | 'id'>>;
  document: Pick<Document, 'id' | '__typename' | 'title'>;
  onChangeStyle: (
    config: Record<string, unknown>,
    setDefault?: boolean,
    templateId?: string
  ) => void;
  onClose: () => void;
}> = ({ template, document, onChangeStyle, onClose }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(template?.id ?? null);

  const { data: documentTemplateData, loading } = useQuery<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >(GET_DOCUMENT_TEMPLATES_QUERY, {
    variables: { documentTemplatesInput: { docTypes: [document.__typename] } },
  });

  const onSelect = (option: string) => {
    setSelectedTemplateId(option);
  };

  const templates = documentTemplateData?.documentTemplates.filter(notNullish) ?? [];

  const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);

  const selectOptions =
    templates.map(({ id, title }) => ({
      value: id,
      label: title ?? 'Unnamed Template',
    })) || [];

  const parsedSchema = selectedTemplate?.configSchema
    ? JSON.parse(selectedTemplate?.configSchema)
    : undefined;

  /**
   * Documents save their own config, so if the selected template is the same as the document's template,
   * we want to use the document's config instead of the user default config or document config.
   */
  const isSameTemplateAsDocument = selectedTemplate?.id === template?.id;
  const config = isSameTemplateAsDocument ? template?.config : selectedTemplate?.config;
  const parsedConfig = config ? JSON.parse(config) : undefined;
  const selectedTemplateTitle = selectedTemplate?.title || 'Select';

  const onSubmit = (data: Record<string, unknown>, saveDefault?: boolean) => {
    onChangeStyle(data, saveDefault, selectedTemplate?.id);
  };

  return (
    <DialogLayout onClose={onClose} className="overflow-hidden" title="Change Document Style">
      <div className="overflow-auto pb-6">
        <p className="pb-4 pt-2 text-base text-text-medium">
          Select your preferences and click {'Generate'} to regenerate your{' '}
          {document.title || documentPresentationalProperties[document.__typename].title} with the
          new style.
        </p>

        {selectOptions.length > 1 && (
          <div className="flex w-full max-w-sm items-center gap-x-5 px-6 pb-8 pt-4">
            <div className="text-sm font-medium text-text-medium">Template:</div>
            <Select
              label=""
              name="template-select"
              onChange={onSelect}
              options={selectOptions}
              value={selectedTemplateId}
              loading={loading}
            />
          </div>
        )}
        {selectedTemplate && (
          <div className="px-6">
            <DocumentConfigForm
              schema={parsedSchema}
              config={parsedConfig}
              showSaveDefaultField={true}
              submitText="Generate"
              onSubmit={onSubmit}
            />
          </div>
        )}
      </div>
    </DialogLayout>
  );
};
