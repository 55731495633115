import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';
import { FC } from 'react';
import {
  CreateTemplateDocumentInput,
  Document,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  UpdateTemplateDocMutation,
  UpdateTemplateDocMutationVariables,
} from '../../../types/graphql';
import { DocumentEditor, Moonwalk, TemplateForm } from '../../components';
import { UPDATE_TEMPLATE_DOC_MUTATION } from '../../graphql/mutations';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';

type Props = {
  document: Omit<Document, 'history' | 'config' | 'permissions' | 'owner' | 'status'>;
};

export const TemplateDocument: FC<Props> = ({ document }) => {
  const [updateTemplateDocument, { loading, error }] = useMutation<
    UpdateTemplateDocMutation,
    UpdateTemplateDocMutationVariables
  >(UPDATE_TEMPLATE_DOC_MUTATION, {
    onCompleted: (data) => {
      navigate(routes.document({ documentId: data.updateTemplateDocument.id }));
    },
    update(cache, { data }) {
      cache.updateQuery<GetDocumentQuery, GetDocumentQueryVariables>(
        {
          query: GET_DOCUMENT_QUERY,
          variables: {
            id: data?.updateTemplateDocument.id ?? '',
          },
        },
        (data) => {
          return {
            __typename: 'Query',
            document: {
              ...data?.document,
            } as Document,
          };
        }
      );
    },
  });

  const onSubmit = (input: CreateTemplateDocumentInput) => {
    updateTemplateDocument({
      variables: {
        id: document.id,
        input,
      },
    });
  };

  return (
    <div className="flex h-full min-h-0 flex-1">
      <div className="flex basis-5/12 flex-col overflow-hidden">
        <TemplateForm
          document={document}
          onSubmit={onSubmit}
          mutationLoading={loading}
          mutationError={error}
          operation="update"
          templateType={document.__typename as NonNullable<Document['__typename']>}
        />
      </div>
      <div className="flex basis-7/12 overflow-hidden">
        {loading ? (
          <Moonwalk text="Crafting your document..." />
        ) : (
          <DocumentEditor id={document.id} />
        )}
      </div>
    </div>
  );
};
