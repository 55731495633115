import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { MarketingStrategyFormValues, marketingStrategyFormSchema } from '../../lib/formSchemas';
import { TextField } from '../TextField';
import { BaseForm } from './BaseForm';
import { BaseFormProps } from './types';
import { MarketingStrategy } from '../../../types/graphql';
import { PageTitle } from '../PageTitle';
import { BoltIcon } from '@heroicons/react/24/outline';
import { useTemplateInputsStore } from '../../services/store';

export const MarketingStrategyForm: FC<BaseFormProps> = ({
  onSubmit,
  operation,
  document,
  documentLoading,
  mutationError,
  mutationLoading,
}) => {
  // Store the input in local state so that it can be accessed by the BlogPostForm
  const cachedInput = useTemplateInputsStore((state) => state.marketingStrategy);
  const formMethods = useForm<MarketingStrategyFormValues>({
    resolver: zodResolver(marketingStrategyFormSchema),
    defaultValues: {},
  });

  if (document && document?.__typename !== 'MarketingStrategy') {
    throw new Error('Document is not a MarketingStrategy');
  }

  const handleSubmit: SubmitHandler<MarketingStrategyFormValues> = (data) => {
    useTemplateInputsStore.setState({ marketingStrategy: data });
    onSubmit({
      inputData: {
        marketingStrategy: data,
      },
    });
  };

  useEffect(() => {
    if (document?.__typename === 'MarketingStrategy') {
      const documentData = document as MarketingStrategy;
      formMethods.reset(documentData.input);
    } else if (cachedInput) {
      formMethods.reset(cachedInput);
    }
  }, [document, formMethods]);

  return (
    <BaseForm<MarketingStrategyFormValues>
      formProps={{ onSubmit: handleSubmit, formMethods }}
      operation={operation}
      documentLoading={documentLoading}
      mutationError={mutationError}
      mutationLoading={mutationLoading}
      document={document}
    >
      <PageTitle Icon={BoltIcon} text="Marketing Strategy" />
      <TextField
        name="industry"
        label="Industry"
        placeholder="Fintech"
        schema={marketingStrategyFormSchema}
      />
      <TextField name="targetClients" label="Target Clients" schema={marketingStrategyFormSchema} />
      <TextField
        name="targetCandidates"
        label="Target Candidates"
        placeholder="e.g C# developers"
        schema={marketingStrategyFormSchema}
      />
    </BaseForm>
  );
};
