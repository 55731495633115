import { Info } from '../Info';
import { JsonSchemaToggleGroup } from './schema';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  schema: JsonSchemaToggleGroup;
  name: string;
};

type ToggleOptionProps = {
  value: string;
  label: string;
  description?: string;
  selected: boolean;
  onClick: () => void;
};

export const ToggleGroupField: React.FC<Props> = ({ name, schema }) => {
  const { control } = useFormContext();
  const defaultValue = schema.default;

  const options = schema.items.enum.map((value) => {
    const details = schema.itemDetails.find((i) => i.value === value);
    return {
      value,
      label: details?.label ?? value,
      description: details?.description,
    };
  });

  const { field, formState } = useController({
    name,
    control,
    defaultValue,
  });

  const error = formState.errors[name];

  const handleToggle = (value: string) => {
    const newValue = field.value.includes(value)
      ? field.value.filter((v: string) => v !== value)
      : [...field.value, value];

    field.onChange(newValue);
  };

  return (
    <div className="flex gap-x-10">
      <div className="flex flex-wrap gap-4">
        {options.map(({ value, label, description }) => (
          <ToggleOption
            key={value}
            value={value}
            label={label}
            description={description}
            selected={field.value.includes(value)}
            onClick={() => handleToggle(value)}
          />
        ))}
      </div>
      {error && <div className="text-error-medium">{error.message as unknown as string}</div>}
    </div>
  );
};

const ToggleOption: React.FC<ToggleOptionProps> = ({ label, description, selected, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex cursor-pointer items-center gap-x-0.5 rounded-full border px-2 py-1 ${
        selected
          ? 'border-sky-300 bg-sky-200 text-text-dark'
          : ' bg-gray-200 text-text-medium hover:bg-sky-100'
      }`}
    >
      <span className="text-sm font-medium">{label}</span>
      {description && (
        <Info iconType="filled" size="small" color="gray">
          {description}
        </Info>
      )}
    </button>
  );
};
