import { FC } from 'react';
import { classNames } from 'src/lib';

export type ReadOnlyFieldProps = {
  name: string;
  label?: string;
  value?: string | null;
  className?: string;
};

export const ReadOnlyField: FC<ReadOnlyFieldProps> = ({ name, label, value, className }) => {
  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      <label htmlFor={name} className="text-sm font-bold text-text-dark">
        {label}
      </label>
      <p
        id={name}
        className="overflow-hidden whitespace-pre-wrap break-words bg-inherit text-base font-normal text-text-veryDark"
      >
        {value || ' - '}
      </p>
    </div>
  );
};
