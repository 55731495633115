import { classNames } from 'src/lib';
import { StatusPill } from 'src/components/StatusPill';

type Props<T extends string> = {
  options: readonly T[];
  selected: T;
  setSelected: (selected: T) => void;
  // This is a temporary prop to allow for a beta tag to be added to a tab
  betaOption?: T;
};

export function Tabs<T extends string>({ options, selected, setSelected, betaOption }: Props<T>) {
  return (
    <div className="flex gap-x-12 border-b border-text-light">
      {options.map((option) => (
        <TabItem
          key={option}
          selected={option === selected}
          onClick={() => setSelected(option)}
          value={option}
          beta={option === betaOption}
        >
          {option}
        </TabItem>
      ))}
    </div>
  );
}

function TabItem<T>({
  selected,
  value,
  onClick,
  children,
  beta,
}: {
  selected?: boolean;
  value: T;
  onClick: (tab: T) => void;
  children: React.ReactNode;
  beta?: boolean;
}) {
  return (
    <div
      onClick={selected ? undefined : () => onClick(value)}
      className={classNames(
        'relative select-none justify-self-start px-1 py-4 text-sm font-medium',
        selected
          ? 'border-b border-primary-dark text-primary-dark'
          : 'cursor-pointer text-text-medium hover:text-primary-light'
      )}
    >
      {children}
      {beta && (
        <div className="absolute -right-10 top-2.5">
          <StatusPill text="BETA" size="sm" color="cyan" />
        </div>
      )}
    </div>
  );
}

export default Tabs;
