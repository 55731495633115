import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { jsonSchemaToZod } from '../../lib/jsonSchemaToZod';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import {
  JSONSchemaStructure,
  JsonSchema,
  JsonSchemaEnum,
  JsonSchemaString,
  JsonSchemaToggleGroup,
} from './schema';
import { sortSchemaFields } from './util';
import { Form } from '../Form/Form';
import { StructuredField } from '../StructuredField';
import { EnumField } from './EnumField';
import { ZodSchema } from 'zod';
import { MarkdownRichTextField } from '../MarkdownRichTextField';
import { ToggleGroupField } from './ToggleGroupField';
import { Label } from './Label';

export type DocumentConfigFormProps = {
  schema: JsonSchema;
  onSubmit: (data: Record<string, unknown>, saveDefault?: boolean) => void;
  onCancel?: () => void;
  submitText?: string;
  cancelText?: string;
  loading?: boolean;
  config?: Record<string, unknown>;
  showSaveDefaultField?: boolean;
};

/**
 * Renders a JSON Schema form
 *  */
export const DocumentConfigForm: React.FC<DocumentConfigFormProps> = ({
  schema,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  loading,
  config,
  showSaveDefaultField,
}) => {
  const [saveDefault, setSaveDefault] = useState<boolean | undefined>();

  const validator = jsonSchemaToZod(schema);

  const formMethods = useForm<Record<string, unknown>>({
    resolver: zodResolver(validator),
    defaultValues:
      // No idea why this is necessary, but it is
      (config as Record<string, unknown>) ?? undefined,
  });
  const fields = sortSchemaFields(schema);

  useEffect(() => {
    formMethods.reset(config ?? {});
  }, [config, formMethods]);

  const handleSubmit = (values: Record<string, unknown>) => {
    onSubmit(values, saveDefault);
  };

  return (
    <Form className="flex flex-col gap-y-8" formMethods={formMethods} onSubmit={handleSubmit}>
      {fields.map(({ key, properties }) => (
        <ConfigFormField key={key} name={key} field={properties} schema={validator} />
      ))}
      <div className="flex justify-end gap-x-4 py-3">
        {onCancel && (
          <Button
            text={cancelText ?? 'Cancel'}
            variant="outline"
            size="medium"
            onClick={onCancel}
            loading={loading}
          />
        )}
        {/* To keep this from conflicting with Zod Schema, this is isolated from the main form payload */}
        {showSaveDefaultField && (
          <Checkbox label="Save as default" value={saveDefault} onChange={setSaveDefault} />
        )}
        <Button type="submit" text={submitText ?? 'Save'} loading={loading} size="medium" />
      </div>
    </Form>
  );
};

export const ConfigFormField: React.FC<{
  name: string;
  field: JsonSchemaString | JsonSchemaEnum | JSONSchemaStructure | JsonSchemaToggleGroup;
  schema: ZodSchema | null;
}> = ({ field, name, schema }) => {
  if ('enum' in field) {
    return (
      <div className="flex">
        <Label name={name} label={field.title} infoText={field.description} />
        <EnumField name={name} schema={field} />
      </div>
    );
  }

  if (field.type === 'string') {
    return (
      <div className="flex items-start">
        <div className="pt-2">
          <Label name={name} label={field.title} infoText={field.description} />
        </div>
        <MarkdownRichTextField richTextFieldSize="sm" name={name} schema={schema} largeLabel />
      </div>
    );
  }

  if (field.type === 'array') {
    if (field.control == 'toggle-group') {
      return (
        <div className="flex">
          <Label name={name} label={field.title} infoText={field.description} />
          <ToggleGroupField name={name} schema={field} />
        </div>
      );
    } else {
      return (
        <div className="flex items-start">
          <div className="pt-2">
            <Label name={name} label={field.title} infoText={field.description} />
          </div>
          <StructuredField name={name} />
        </div>
      );
    }
  }

  return null;
};

export default DocumentConfigForm;
