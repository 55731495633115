import { HelperOptions } from 'handlebars';

/**
 * A Handlebars helper function that checks if a value is included in an array.
 *
 * Usage:
 *   {{#includes array value}}...{{/includes}}
 *
 * @param {unknown} array - The array to search.
 * @param {unknown} value - The value to look for in the array.
 * @param {HelperOptions} options - Handlebars options object.
 * @return {string} Rendered string based on whether the value is found.
 *
 * @throws {Error} If incorrect parameters are provided or if the array is not an array when not null/undefined.
 */
export function includesHelper(
  this: unknown,
  array: unknown,
  value: unknown,
  options: HelperOptions
): string {
  if (arguments.length < 3) {
    throw new Error('Handlebars Helper "includes" needs 2 parameters');
  }

  if (array == null || value == null) {
    return options.inverse(this);
  }

  if (!Array.isArray(array)) {
    throw new Error('First parameter to "includes" helper must be an array');
  }

  if (array.includes(value)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
}
