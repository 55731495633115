import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { GET_JOB_AND_CONTENT } from 'src/graphql/queries';
import { GetJobAndDocuments, GetJobAndDocumentsVariables, Job } from 'types/graphql';
import { EntityExportDialog } from './EntityExportDialog';
import { handleExport } from './lib/handleExport';
import { ExportSubmitParams } from './types';
import { useApolloClient } from '@apollo/client';

type Props = {
  id: string;
};

export const JobExportDialog: FC<Props> = ({ id }) => {
  const client = useApolloClient();
  const { data } = useQuery<GetJobAndDocuments, GetJobAndDocumentsVariables>(GET_JOB_AND_CONTENT, {
    variables: {
      jobId: id,
    },
  });

  const job = data?.job as Job;

  const handleSubmit = async ({ includeDocuments, onComplete }: ExportSubmitParams) => {
    if (!job) return;

    try {
      await handleExport(client, job, 'Job', includeDocuments);
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Job" onSubmit={handleSubmit} />;
};
