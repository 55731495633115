import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormError, Link, SelectField, TextAreaField, TextField } from 'src/components';
import { Button } from '../../components/Button';
import { DatePickerField } from 'src/components/DatePicker';
import { RichTextField } from '../../components/RichTextField';
import { PlusIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { GetCandidateAndDocuments, ACCESS_LEVEL } from 'types/graphql';
import { Form } from 'src/components/Form/Form';
import { candidateFormSchema, CandidateFormValues } from '../../lib/formSchemas';
import { FC, useEffect, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { HideIfReadOnly } from 'src/components/HideIfReadOnly';
import { FadeIn } from 'src/components/FadeIn';
import { Accordion } from '../../components/Accordion';
import { Reorder } from 'framer-motion';
import { LANGUAGE_LEVEL_OPTIONS, getLanguageLevelLabel } from 'src/lib/sharedConstants';
import { DraggableItem } from 'src/components/DraggableItem';
import { useBoolean } from 'src/hooks';
import { Toggle } from 'src/components/Toggle/Toggle';
import { GuitarIcon } from 'src/assets/GuitarIcon';
import { LanguagesIcon } from 'src/assets/LanguagesIcon';
import { ToggleButton } from 'src/components/ToggleButton';
import { classNames } from 'src/lib';
import { hasCvDetails } from '../../lib/hasCvDetails';
import { formatStartEndDateSnippet } from '../../lib/candidateUtil';
import { htmlToText } from 'html-to-text';

type Props = {
  onClickRegenerate: SubmitHandler<CandidateFormValues>;
  candidate: GetCandidateAndDocuments['candidate'];
  error?: ApolloError;
  myPermission?: ACCESS_LEVEL | null;
  isRegenerateCampaignButtonVisible?: boolean;
  onHandleRegenerateCampaign?: () => void;
  regenerateCampaignLoading?: boolean;
  hasCampaign?: boolean;
};

export const RegenerateForm: FC<Props> = ({
  onClickRegenerate,
  candidate,
  error,
  myPermission,
  isRegenerateCampaignButtonVisible,
  onHandleRegenerateCampaign,
  regenerateCampaignLoading,
  hasCampaign,
}) => {
  // This state keeps track of which accordions are currently open, and is used to automatically open a newly created accordion field.
  // The keys are strings representing the field names, and the values are booleans indicating whether the accordion is open.
  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});

  // This state is used to manage the open/close status of all accordions simultaneously.
  const { value: isAccordionOpen, setTrue: openAllAccordions } = useBoolean(false);
  const [showCvDetails, setShowCvDetails] = useState(hasCvDetails(candidate));
  const [showLanguages, setShowLanguages] = useState(Boolean(candidate?.languages?.length));
  const [showInterests, setShowInterests] = useState(Boolean(candidate?.interests?.length));

  const initialIncludeLanguageLevel = !!candidate?.languages?.some(({ level }) => !!level);

  const { value: includeLanguageLevel, setValue: setIncludeLanguageLevel } = useBoolean(
    initialIncludeLanguageLevel
  );

  const formMethods = useForm<CandidateFormValues>({
    resolver: zodResolver(candidateFormSchema),
    defaultValues: {
      name: candidate.name,
      refId: candidate.refId,
      rightToWork: candidate.rightToWork,
      availability: candidate.availability,
      jobTitle: candidate.jobTitle,
      notes: candidate.notes,
      desiredJobTitle: candidate.desiredJobTitle,
      desiredSalary: candidate.desiredSalary,
      currentSalary: candidate.currentSalary,
      profileSummary: candidate.profileSummary,
      location: candidate.location,
      experience:
        candidate.experience?.map((exp) => ({
          id: exp.id,
          startDate: exp.startDate ? new Date(exp.startDate) : null,
          endDate: exp.endDate ? new Date(exp.endDate) : null,
          location: exp.location,
          description: exp.description,
          company: exp.company,
          title: exp.title,
        })) ?? [],
      certifications:
        candidate.certifications?.map((cert) => ({
          id: cert.id,
          title: cert.title,
          description: cert.description,
        })) ?? [],
      education:
        candidate?.education?.map((edu) => ({
          id: edu.id,
          title: edu.title,
          location: edu.location,
          description: edu.description,
          institution: edu.institution,
          startDate: edu.startDate ? new Date(edu.startDate) : null,
          endDate: edu.endDate ? new Date(edu.endDate) : null,
        })) ?? [],
      skills:
        candidate.skills?.map((skill) => ({
          id: skill.id,
          title: skill.title,
          description: skill.description,
        })) ?? [],
      achievements:
        candidate?.achievements?.map((ach) => ({
          id: ach.id,
          description: ach.description,
        })) ?? [],

      languages:
        candidate.languages?.map((lang) => ({
          id: lang.id,
          language: lang.language,
          level: lang.level,
        })) ?? [],
      interests:
        candidate.interests?.map((interest) => ({
          id: interest.id,
          description: interest.description,
        })) ?? [],
    },
  });

  const {
    fields: experienceFields,
    append: appendExperience,
    remove: removeExperience,
    replace: replaceExperiences,
  } = useFieldArray({ control: formMethods.control, name: 'experience', keyName: 'fieldId' });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
    replace: replaceEducation,
  } = useFieldArray({ control: formMethods.control, name: 'education', keyName: 'fieldId' });

  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
    replace: replaceSkills,
  } = useFieldArray({ control: formMethods.control, name: 'skills', keyName: 'fieldId' });

  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievement,
    replace: replaceAchievements,
  } = useFieldArray({ control: formMethods.control, name: 'achievements', keyName: 'fieldId' });

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
    replace: replaceLanguages,
  } = useFieldArray({ control: formMethods.control, name: 'languages', keyName: 'fieldId' });

  const {
    fields: interestFields,
    append: appendInterest,
    remove: removeInterest,
    replace: replaceInterests,
  } = useFieldArray({ control: formMethods.control, name: 'interests', keyName: 'fieldId' });

  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification,
    replace: replaceCertifications,
  } = useFieldArray({ control: formMethods.control, name: 'certifications', keyName: 'fieldId' });

  const handleToggleLanguageLevels = (enabled: boolean) => {
    setIncludeLanguageLevel(enabled);

    const updatedLanguages = languageFields.map((field, index) => ({
      ...formMethods.getValues(`languages.${index}`),
      level: enabled ? 3 : null,
    }));
    formMethods.setValue('languages', updatedLanguages);
  };

  // Reset languages when hidden
  useEffect(() => {
    if (!showLanguages) {
      formMethods.setValue('languages', []);
    }
  }, [showLanguages]);

  // Reset interests when hidden
  useEffect(() => {
    if (!showInterests) {
      formMethods.setValue('interests', []);
    }
  }, [showInterests]);

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Open all accordions when there is any form error and scroll into view the first error field
  useEffect(() => {
    const errors = formMethods.formState.errors;
    if (Object.keys(errors).length > 0) {
      openAllAccordions();

      // Scroll to the first error field
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = formErrorRef.current[firstErrorKey];
      if (firstErrorRef) {
        firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formMethods.formState.errors]);

  const liveEducation = formMethods.watch('education') ?? [];
  const mergedEducationFields = educationFields.map((f, i) => ({
    ...f,
    ...liveEducation?.[i],
  }));

  const liveExperiences = formMethods.watch('experience') ?? [];
  const mergedExperienceFields = experienceFields.map((f, i) => ({
    ...f,
    ...liveExperiences?.[i],
  }));

  const liveSkills = formMethods.watch('skills') ?? [];
  const mergedSkillFields = skillFields.map((f, i) => ({
    ...f,
    ...liveSkills?.[i],
  }));

  const liveAchievements = formMethods.watch('achievements') ?? [];
  const mergedAchievementFields = achievementFields.map((f, i) => ({
    ...f,
    ...liveAchievements?.[i],
  }));

  const liveLanguages = formMethods.watch('languages') ?? [];
  const mergedLanguageFields = languageFields.map((f, i) => ({
    ...f,
    ...liveLanguages?.[i],
  }));

  const liveInterests = formMethods.watch('interests') ?? [];
  const mergedInterestFields = interestFields.map((f, i) => ({
    ...f,
    ...liveInterests?.[i],
  }));

  const liveCertifications = formMethods.watch('certifications') ?? [];
  const mergedCertificationFields = certificationFields.map((f, i) => ({
    ...f,
    ...liveCertifications?.[i],
  }));

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <Form<CandidateFormValues>
        readOnly={myPermission === 'READ'}
        className="flex min-h-0 flex-1 flex-col"
        formMethods={formMethods}
        onSubmit={onClickRegenerate}
      >
        <div className="flex flex-1 flex-col gap-y-3 overflow-auto px-4 md:px-16">
          <div className="pt-4" />
          <FormError error={error} />
          <div className="grid grid-cols-1">
            <TextField
              label="Candidate Name"
              name="name"
              placeholder="Emma Smith"
              schema={candidateFormSchema}
              ref={(el) => (formErrorRef.current['name'] = el)}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <TextField
              label="Job Title"
              placeholder="Sales Consultant"
              name="jobTitle"
              schema={candidateFormSchema}
              ref={(el) => (formErrorRef.current['jobTitle'] = el)}
            />
            <TextField
              label="Desired Job Title"
              name="desiredJobTitle"
              placeholder="Senior Sales Consultant"
              schema={candidateFormSchema}
              ref={(el) => (formErrorRef.current['desiredJobTitle'] = el)}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <TextField
              label="Current Salary"
              name="currentSalary"
              placeholder="£45k + Commission"
              ref={(el) => (formErrorRef.current['currentSalary'] = el)}
            />
            <TextField
              label="Desired Salary"
              name="desiredSalary"
              placeholder="£50k"
              ref={(el) => (formErrorRef.current['desiredSalary'] = el)}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <TextField
              label="Location"
              name="location"
              placeholder="London"
              ref={(el) => (formErrorRef.current['location'] = el)}
            />
            <TextField
              label="Notice Period"
              name="availability"
              placeholder="2 Weeks"
              ref={(el) => (formErrorRef.current['availability'] = el)}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <TextField
              label="Right to work"
              name="rightToWork"
              placeholder="Residential Visa"
              ref={(el) => (formErrorRef.current['rightToWork'] = el)}
            />
            <TextField
              label="Reference ID"
              name="refId"
              placeholder="e.g. Candidate CRM ID"
              schema={candidateFormSchema}
              ref={(el) => (formErrorRef.current['refId'] = el)}
            />
          </div>
          <div
            className={classNames('flex flex-col space-y-2 pt-2', !showCvDetails && 'flex-1')}
            ref={(el) => (formErrorRef.current['notes'] = el)}
          >
            <RichTextField
              label="Interview Notes"
              name="notes"
              placeholder="Career overview, expertise, strengths, career highlights, personal attributions, aspirations and motivations for moving..."
              schema={candidateFormSchema}
              visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
              className={showCvDetails ? 'h-72' : 'flex-grow'}
            />
          </div>

          {!showCvDetails && (
            <div className="mb-4 flex justify-start">
              <Link
                size="medium"
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                LeftIcon={PlusIcon}
                onClick={() => setShowCvDetails(true)}
              >
                <p className="text-base font-medium">Add CV Details</p>
              </Link>
            </div>
          )}

          {/* CV Details Form */}
          {showCvDetails && (
            <>
              <div className="sticky top-0 z-10 flex items-center justify-between bg-pageGray py-3">
                <h2 className="text-xl font-semibold text-text-dark">CV Details</h2>
                <HideIfReadOnly>
                  <Link
                    size="large"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={XMarkIcon}
                    onClick={() => setShowCvDetails(false)}
                  >
                    Remove
                  </Link>
                </HideIfReadOnly>
              </div>
              <div
                className="flex flex-col pt-4"
                ref={(el) => (formErrorRef.current['profileSummary'] = el)}
              >
                <RichTextField
                  label="Personal Profile"
                  name="profileSummary"
                  placeholder="Brief overview of professional background including expertise, strengths and career aspirations."
                  schema={candidateFormSchema}
                  visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="text-lg font-semibold text-text-veryDark">Work Experience</h2>
                <Reorder.Group
                  values={mergedExperienceFields}
                  onReorder={replaceExperiences}
                  axis="y"
                  className="flex flex-col gap-y-4"
                  layoutScroll
                >
                  {mergedExperienceFields.map((field, index) => {
                    const liveField = liveExperiences?.[index];
                    const startDateName = `experience.${index}.startDate`;
                    const endDateName = `experience.${index}.endDate`;
                    const fieldKey = `experience.${index}`;

                    return (
                      <DraggableItem
                        key={field.fieldId}
                        field={field}
                        index={index}
                        removeHandler={removeExperience}
                      >
                        <Accordion
                          headline={`${liveField?.title || 'Untitled'}${
                            liveField?.company ? ` - ${liveField.company}` : ''
                          }`}
                          subtext={formatStartEndDateSnippet(
                            liveField?.startDate,
                            liveField?.endDate
                          )}
                          isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        >
                          <div
                            className="flex flex-col justify-center gap-2"
                            ref={(el) => (formErrorRef.current[fieldKey] = el)}
                          >
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                              <TextField
                                label="Job Title"
                                placeholder="Executive"
                                className="h-[42px]"
                                name={`experience.${index}.title`}
                                required
                              />
                              <TextField
                                label="Company"
                                className="h-[42px]"
                                name={`experience.${index}.company`}
                                required
                              />
                            </div>
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                              <DatePickerField
                                label="Start Date"
                                name={startDateName}
                                maxDate={new Date()}
                              />
                              <DatePickerField
                                label="End Date"
                                name={endDateName}
                                startDateName={startDateName}
                                setPlaceholderPresentIfNull={!!field.startDate}
                              />
                            </div>
                            <TextField
                              label="Location"
                              className="h-[42px]"
                              name={`experience.${index}.location`}
                              placeholder="London"
                            />
                            <RichTextField
                              label="Notes"
                              name={`experience.${index}.description`}
                              placeholder="Overview of main responsibilities, projects/initiatives, achievements.
                              "
                              schema={candidateFormSchema}
                              className="h-72"
                              visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                            />
                          </div>
                        </Accordion>
                      </DraggableItem>
                    );
                  })}
                </Reorder.Group>
                <HideIfReadOnly>
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={() => {
                      const newIndex = experienceFields.length;
                      const newFieldKey = `experience.${newIndex}`;
                      appendExperience({
                        id: null,
                        title: '',
                        company: '',
                        startDate: null,
                        endDate: null,
                        location: '',
                        description: '',
                      });
                      setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                    }}
                  >
                    Add Experience
                  </Link>
                </HideIfReadOnly>
              </div>

              <div className="flex flex-col space-y-2 pt-4">
                <h2 className="text-lg font-semibold text-text-veryDark">Education</h2>
                <Reorder.Group
                  values={mergedEducationFields}
                  onReorder={replaceEducation}
                  axis="y"
                  className="flex flex-col gap-y-4"
                  layoutScroll
                >
                  {mergedEducationFields.map((field, index) => {
                    const liveField = liveEducation?.[index];
                    const startDateName = `education.${index}.startDate`;
                    const endDateName = `education.${index}.endDate`;
                    const fieldKey = `education.${index}`;
                    const titleValue = formMethods.watch(`education.${index}.title`);
                    const institutionValue = formMethods.watch(`education.${index}.institution`);
                    const separator = titleValue && institutionValue ? ' - ' : '';

                    return (
                      <DraggableItem
                        key={field.fieldId}
                        field={field}
                        index={index}
                        removeHandler={removeEducation}
                      >
                        <Accordion
                          headline={
                            titleValue || institutionValue
                              ? `${institutionValue}${separator}${titleValue}`
                              : 'Untitled'
                          }
                          subtext={formatStartEndDateSnippet(
                            liveField?.startDate,
                            liveField?.endDate
                          )}
                          isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        >
                          <div
                            className="flex flex-col justify-center gap-2"
                            ref={(el) => (formErrorRef.current[fieldKey] = el)}
                          >
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                              <TextField
                                label="Title"
                                name={`education.${index}.title`}
                                className="h-[42px]"
                                placeholder="Degree"
                                required
                              />
                              <TextField
                                label="Institution"
                                name={`education.${index}.institution`}
                                className="h-[42px]"
                                placeholder="University"
                              />
                            </div>
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                              <DatePickerField
                                label="Start Date"
                                name={startDateName}
                                maxDate={new Date()}
                              />
                              <DatePickerField
                                label="End Date"
                                name={endDateName}
                                startDateName={startDateName}
                              />
                            </div>
                            <TextField
                              label="Location"
                              name={`education.${index}.location`}
                              className="h-[42px]"
                              placeholder="London"
                            />
                            <RichTextField
                              label="Description"
                              name={`education.${index}.description`}
                              placeholder="Details of academic qualifications, including results, relevant courses or academic achievements."
                              schema={candidateFormSchema}
                              className="h-72"
                              visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                            />
                          </div>
                        </Accordion>
                      </DraggableItem>
                    );
                  })}
                </Reorder.Group>
                <HideIfReadOnly>
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={() => {
                      const newIndex = educationFields.length;
                      const newFieldKey = `education.${newIndex}`;
                      appendEducation({
                        id: null,
                        title: '',
                        institution: '',
                        startDate: null,
                        endDate: null,
                        location: '',
                        description: '',
                      });
                      setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                    }}
                  >
                    Add Education
                  </Link>
                </HideIfReadOnly>
              </div>

              <div className="flex flex-col space-y-2 pt-4">
                <h2 className="text-lg font-semibold text-text-veryDark">Certifications</h2>
                <Reorder.Group
                  values={mergedCertificationFields}
                  onReorder={replaceCertifications}
                  axis="y"
                  className="flex flex-col gap-y-4"
                  layoutScroll
                >
                  {mergedCertificationFields.map((field, index) => {
                    const liveField = formMethods.watch(`certifications.${index}`);
                    const fieldKey = `certifications.${index}`;

                    return (
                      <DraggableItem
                        key={field.fieldId}
                        field={field}
                        index={index}
                        removeHandler={removeCertification}
                      >
                        <Accordion
                          headline={liveField?.title || 'Untitled'}
                          isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        >
                          <div
                            className="grid grid-cols-1 gap-2"
                            ref={(el) => (formErrorRef.current[fieldKey] = el)}
                          >
                            <TextField
                              label="Certification"
                              className="h-[42px]"
                              name={`certifications.${index}.title`}
                              required
                            />
                            <RichTextField
                              label="Description"
                              name={`certifications.${index}.description`}
                              placeholder="Additional qualifications including training, certifications, memberships, awards etc.,"
                              className="h-72"
                            />
                          </div>
                        </Accordion>
                      </DraggableItem>
                    );
                  })}
                </Reorder.Group>
                <HideIfReadOnly>
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={() => {
                      const newIndex = certificationFields.length;
                      const newFieldKey = `certifications.${newIndex}`;
                      appendCertification({
                        id: null,
                        title: '',
                        description: '',
                      });
                      setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                    }}
                  >
                    Add Certification
                  </Link>
                </HideIfReadOnly>
              </div>
              <div className="flex flex-col space-y-2 pt-4">
                <h2 className="text-lg font-semibold text-text-veryDark">Skills</h2>

                <Reorder.Group
                  values={mergedSkillFields}
                  onReorder={replaceSkills}
                  axis="y"
                  className="flex flex-col gap-y-4"
                  layoutScroll
                >
                  {mergedSkillFields.map((field, index) => {
                    const liveField = liveSkills?.[index];
                    const fieldKey = `skills.${index}`;

                    return (
                      <DraggableItem
                        key={field.fieldId}
                        field={field}
                        index={index}
                        removeHandler={removeSkill}
                      >
                        <Accordion
                          headline={liveField?.title || 'Untitled'}
                          isOpen={openAccordions[fieldKey] || isAccordionOpen}
                          onToggle={() =>
                            setOpenAccordions((prev) => ({
                              ...prev,
                              [fieldKey]: !prev[fieldKey],
                            }))
                          }
                        >
                          <div ref={(el) => (formErrorRef.current[fieldKey] = el)}>
                            <TextField
                              label="Skill"
                              name={`skills.${index}.title`}
                              required
                              className="h-[42px]"
                            />
                          </div>
                          <TextAreaField
                            label="Description"
                            name={`skills.${index}.description`}
                            placeholder="Brief explanation of proficiency level, relevant experience, or specific applications of the skill."
                            className="h-[105px] pt-2"
                          />
                        </Accordion>
                      </DraggableItem>
                    );
                  })}
                </Reorder.Group>
                <HideIfReadOnly>
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={() => {
                      const newIndex = skillFields.length;
                      const newFieldKey = `skills.${newIndex}`;
                      appendSkill({
                        id: null,
                        title: '',
                        description: '',
                      });
                      setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                    }}
                  >
                    Add Skill
                  </Link>
                </HideIfReadOnly>
              </div>

              <div className="flex flex-col space-y-2 pt-4">
                <h2 className="text-lg font-semibold text-text-veryDark">Achievements</h2>
                <Reorder.Group
                  values={mergedAchievementFields}
                  onReorder={replaceAchievements}
                  axis="y"
                  className="flex flex-col gap-y-4"
                  layoutScroll
                >
                  {mergedAchievementFields.map((field, index) => {
                    const liveField = liveAchievements?.[index];
                    const fieldKey = `achievements.${index}`;

                    return (
                      <DraggableItem
                        key={field.fieldId}
                        field={field}
                        index={index}
                        removeHandler={removeAchievement}
                      >
                        <Accordion
                          headline={
                            htmlToText(liveField?.description, {
                              baseElements: {
                                selectors: ['p'],
                              },
                            }) || 'Untitled'
                          }
                          isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        >
                          <div
                            className="grid grid-cols-1 gap-3"
                            ref={(el) => (formErrorRef.current[fieldKey] = el)}
                          >
                            <RichTextField
                              label="Description"
                              name={`achievements.${index}.description`}
                              placeholder="Significant accomplishments, awards, or recognition received throughout career."
                              className="h-64"
                              required
                              schema={candidateFormSchema}
                            />
                          </div>
                        </Accordion>
                      </DraggableItem>
                    );
                  })}
                </Reorder.Group>
                <HideIfReadOnly>
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={() => {
                      const newIndex = achievementFields.length;
                      const newFieldKey = `achievements.${newIndex}`;
                      appendAchievement({
                        id: null,
                        description: '',
                      });
                      setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                    }}
                  >
                    Add Achievement
                  </Link>
                </HideIfReadOnly>
              </div>

              {showLanguages && (
                <div className="flex flex-col space-y-2 pt-4">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-text-veryDark">Languages</h2>
                    {liveLanguages?.length > 0 && (
                      <div className="flex items-center justify-center gap-3">
                        <p className="text-sm font-normal text-text-medium">Include level</p>
                        <Toggle
                          enabled={includeLanguageLevel}
                          onChange={handleToggleLanguageLevels}
                        />
                      </div>
                    )}
                  </div>

                  <Reorder.Group
                    values={mergedLanguageFields}
                    onReorder={replaceLanguages}
                    axis="y"
                    className="flex flex-col gap-y-4"
                    layoutScroll
                  >
                    {mergedLanguageFields.map((field, index) => {
                      const liveField = liveLanguages?.[index];
                      const fieldKey = `languages.${index}`;

                      return (
                        <DraggableItem
                          key={field.fieldId}
                          field={field}
                          index={index}
                          removeHandler={removeLanguage}
                        >
                          <Accordion
                            headline={liveField?.language || 'Untitled'}
                            subtext={
                              includeLanguageLevel
                                ? getLanguageLevelLabel(liveField?.level ?? 3)
                                : ''
                            }
                            isOpen={openAccordions[fieldKey] || isAccordionOpen}
                            onToggle={() =>
                              setOpenAccordions((prev) => ({
                                ...prev,
                                [fieldKey]: !prev[fieldKey],
                              }))
                            }
                          >
                            <div
                              className={classNames(
                                'grid grid-cols-1 gap-2',
                                includeLanguageLevel ? 'xl:grid-cols-2' : 'xl:grid-cols-1'
                              )}
                              ref={(el) => (formErrorRef.current[fieldKey] = el)}
                            >
                              <TextField
                                label="Language"
                                name={`languages.${index}.language`}
                                required
                                className="h-[42px]"
                              />
                              {includeLanguageLevel && (
                                <SelectField
                                  label="Level"
                                  name={`languages.${index}.level`}
                                  options={LANGUAGE_LEVEL_OPTIONS}
                                />
                              )}
                            </div>
                          </Accordion>
                        </DraggableItem>
                      );
                    })}
                  </Reorder.Group>
                  <HideIfReadOnly>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = languageFields.length;
                        const newFieldKey = `languages.${newIndex}`;
                        appendLanguage({
                          id: null,
                          language: '',
                          level: includeLanguageLevel ? 3 : null,
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Language
                    </Link>
                  </HideIfReadOnly>
                </div>
              )}

              {showInterests && (
                <div className="flex flex-col space-y-2 pt-4">
                  <h2 className="text-lg font-semibold text-text-veryDark">Interests</h2>
                  <Reorder.Group
                    values={mergedInterestFields}
                    onReorder={replaceInterests}
                    axis="y"
                    className="flex flex-col gap-y-4"
                    layoutScroll
                  >
                    {mergedInterestFields.map((field, index) => {
                      const liveField = liveInterests?.[index];
                      const fieldKey = `interests.${index}`;

                      return (
                        <DraggableItem
                          key={field.fieldId}
                          field={field}
                          index={index}
                          removeHandler={removeInterest}
                        >
                          <Accordion
                            headline={liveField?.description || 'Untitled'}
                            isOpen={openAccordions[fieldKey] || isAccordionOpen}
                          >
                            <div
                              className="flex min-h-32 flex-grow flex-col"
                              ref={(el) => (formErrorRef.current[fieldKey] = el)}
                            >
                              <TextAreaField
                                label="Description"
                                name={`interests.${index}.description`}
                                placeholder="Relevant hobbies, volunteer work, or extracurricular activities that showcase skills or character."
                                className="min-h-24 flex-grow"
                                required
                              />
                            </div>
                          </Accordion>
                        </DraggableItem>
                      );
                    })}
                  </Reorder.Group>
                  <HideIfReadOnly>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = interestFields.length;
                        const newFieldKey = `interests.${newIndex}`;
                        appendInterest({
                          id: null,
                          description: '',
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Interest
                    </Link>
                  </HideIfReadOnly>
                </div>
              )}

              <HideIfReadOnly>
                {showCvDetails && (
                  <div className="flex flex-col gap-2 pt-4">
                    <h2 className="text-xl font-semibold text-text-dark">Add Section</h2>
                    <p className="text-sm font-normal text-text-medium">
                      Select cards below to add more information about the candidate.
                    </p>
                    <div className="mt-4 flex space-x-6">
                      <ToggleButton
                        value={showLanguages}
                        onToggle={setShowLanguages}
                        text="Languages"
                        LeftIcon={LanguagesIcon}
                      />
                      <ToggleButton
                        value={showInterests}
                        onToggle={setShowInterests}
                        text="Interests"
                        LeftIcon={GuitarIcon}
                      />
                    </div>
                  </div>
                )}
              </HideIfReadOnly>
            </>
          )}
        </div>
        <div className="flex flex-col gap-y-3 px-4 py-4 md:px-16">
          <HideIfReadOnly>
            <Button className="flex-grow" text="Save Changes" size="mega" type="submit" />
          </HideIfReadOnly>
          <div className="h-4">
            <FadeIn visible={isRegenerateCampaignButtonVisible}>
              <div className="flex cursor-pointer items-center justify-center gap-2">
                <Link
                  onClick={onHandleRegenerateCampaign}
                  size="large"
                  disabled={regenerateCampaignLoading}
                >
                  <p className="text-sm font-medium text-primary-medium">
                    {`${hasCampaign ? 'Regenerate' : 'Generate'} Campaign`}
                  </p>
                </Link>
                <ArrowPathIcon className="h-5 w-5" />
              </div>
            </FadeIn>
          </div>
        </div>
      </Form>
    </div>
  );
};
