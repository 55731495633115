import { PhotoIcon } from '@heroicons/react/24/outline';
import { FC, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Link } from 'src/components';
import { classNames } from 'src/lib';

type FileInputProps = {
  name: string;
  onChange: (file: File) => void;
  onRemove: () => void;
  label?: string;
  description?: string;
  imageUrl?: string;
  className?: string;
  frameClassName?: string;
};

export const FileInput: FC<FileInputProps> = ({
  name,
  onChange,
  onRemove,
  label,
  description,
  imageUrl,
  className,
  frameClassName,
}) => {
  const { control } = useFormContext();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={`flex flex-col justify-center space-y-1  ${className}`}>
      {label && <h3 className="text-sm font-medium text-text-dark">{label}</h3>}
      {imageUrl ? (
        <div className="space-y-2">
          <img
            src={imageUrl}
            alt="Uploaded"
            className={`h-20 w-fit rounded-xl border bg-white object-contain p-2 ${frameClassName}`}
          />
          <div className="flex space-x-2">
            <Link
              className="text-sm font-medium text-primary-medium"
              onClick={handleFileUploadClick}
              size="medium"
            >
              Update
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    onChange(file);
                  }
                }}
              />
            </Link>
            <Link
              className="text-sm font-medium text-primary-medium"
              onClick={onRemove}
              size="medium"
            >
              Remove
            </Link>
          </div>
        </div>
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <div
              className={classNames(
                'flex cursor-pointer items-center justify-center rounded-xl border bg-white p-2 text-center hover:bg-gray-50',
                frameClassName
              )}
              onClick={handleFileUploadClick}
            >
              <PhotoIcon className="h-5 w-5 text-text-medium" aria-hidden="true" />
              {value && <p className="mt-2 text-xs text-gray-500">{value.name}</p>}
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    onChange(file);
                  }
                }}
              />
            </div>
          )}
        />
      )}
      {description && <p className="text-sm font-normal text-text-medium">{description}</p>}
    </div>
  );
};
