import { HelperOptions } from 'handlebars';

/**
 * A Handlebars helper function for comparing two values for equality.
 *
 * This helper determines if two values are strictly equal. If they are equal,
 * it renders the block within its template. If not, it renders the inverse block
 * (if provided). This can be useful for conditional rendering within Handlebars templates.
 *
 * @param {Handlebars.HelperOptions} this - The current context of the template.
 * @param {any} a - The first value to compare.
 * @param {any} b - The second value to compare.
 * @param {HelperOptions} options - The options object provided by Handlebars, containing functions
 *                                  to render the block (`fn`) or its inverse (`inverse`).
 * @return {string} The rendered string from either the block or its inverse, based on the comparison.
 *
 * @throws {Error} If less than two parameters are passed to the helper, an error is thrown to
 *                 ensure the helper is used correctly.
 */
export function eqHelper(
  this: Handlebars.HelperOptions,
  a: unknown,
  b: unknown,
  options: HelperOptions
): string {
  if (arguments.length < 3) {
    throw new Error('Handlebars Helper "eq" needs 2 parameters');
  }

  if (a === b) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
}
