import { FC, PropsWithChildren } from 'react';
import { classNames, extractTextFromChildren } from 'src/lib';
import { track } from 'src/services';

export type Props = {
  size: 'small' | 'medium' | 'large';
  onClick?: () => void;
  LeftIcon?: React.FC<React.ComponentProps<'svg'>>;
  RightIcon?: React.FC<React.ComponentProps<'svg'>>;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
};

export const Link: FC<PropsWithChildren<Props>> = ({
  children,
  size,
  LeftIcon,
  RightIcon,
  disabled,
  onClick,
  className,
  iconClassName,
  variant,
}) => {
  const handleClick = () => {
    if (disabled) return;
    const buttonName = extractTextFromChildren(children);
    track('Button Click', { buttonName });
    if (onClick) {
      onClick();
    }
  };

  return (
    <span
      onClick={handleClick}
      className={classNames(
        'font-medium hover:cursor-pointer hover:underline',
        getTextClasses(size),
        (LeftIcon || RightIcon) && 'flex items-center space-x-1',
        disabled ? 'text-text-medium' : getTextColor(variant, disabled),
        disabled && 'hover:cursor-not-allowed hover:no-underline'
      )}
    >
      {LeftIcon && (
        <LeftIcon
          strokeWidth={2}
          className={classNames(
            getIconClasses(size),
            getIconColor(variant, disabled),
            iconClassName
          )}
        />
      )}
      <a className={className}>{children}</a>
      {RightIcon && (
        <RightIcon
          strokeWidth={2}
          className={classNames(
            getIconClasses(size),
            getIconColor(variant, disabled),
            iconClassName
          )}
        />
      )}
    </span>
  );
};

function getTextColor(variant: Props['variant'], disabled?: boolean) {
  if (disabled) return 'text-text-medium';
  switch (variant) {
    case 'primary':
      return 'text-text-veryDark';
    case 'secondary':
      return 'text-indigo-400';
  }
}

function getIconColor(variant: Props['variant'], disabled?: boolean) {
  if (disabled) return 'text-text-medium';
  switch (variant) {
    case 'primary':
      return 'text-text-veryDark';
    case 'secondary':
      return 'text-indigo-300';
  }
}

function getTextClasses(size: Props['size']) {
  switch (size) {
    case 'small':
      return 'text-xs';
    case 'medium':
      return 'text-sm';
    case 'large':
      return 'text-base';
  }
}

function getIconClasses(size: Props['size']) {
  switch (size) {
    case 'small':
      return 'h-3 w-3';
    case 'medium':
      return 'h-4 w-4';
    case 'large':
      return 'h-5 w-5';
  }
}
