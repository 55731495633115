import { FC } from 'react';
import { Editor } from '@tiptap/react';

import { ACCESS_LEVEL } from 'types/graphql';

import { EditorButton, AccessControl, SnippetsDropdown } from 'src/components';

import { classNames } from 'src/lib';

import { BoldIcon } from 'src/assets/bold';
import { Italic } from 'src/assets/italic';
import { H1 } from 'src/assets/h1';
import { H2 } from 'src/assets/h2';
import { List } from 'src/assets/list';
import { OList } from 'src/assets/olist';

export type ButtonType = 'bold' | 'italic' | 'heading1' | 'heading2' | 'bulletList' | 'orderedList';

export const RichTextMenuBar: FC<{
  editor: Editor;
  extraButtons?: React.ReactNode;
  editable?: boolean;
  hasBorderTop?: boolean;
  hasSnippetButton?: boolean;
  disabled?: boolean;
  disableMaxWidth?: boolean;
  onHandleSnippetClick?: (snippet: string) => void;
  myPermission?: ACCESS_LEVEL | null;
  className?: string;
  visibleButtons?: ButtonType[];
  richTextFieldSize?: 'sm' | 'md';
}> = ({
  editor,
  extraButtons,
  editable,
  hasBorderTop,
  hasSnippetButton,
  disabled,
  className,
  onHandleSnippetClick,
  myPermission,
  visibleButtons = ['bold', 'italic', 'heading1', 'heading2', 'bulletList', 'orderedList'],
  richTextFieldSize = 'md',
}) => {
  if (!editor) {
    return null;
  }

  const iconSize = richTextFieldSize === 'sm' ? 'h-4 w-4' : 'h-6 w-6';

  return (
    <div className="flex flex-1 justify-center rounded-t-2xl bg-white">
      <div
        className={classNames(
          'flex flex-1 items-center self-stretch',
          hasBorderTop && 'border-t border-gray-200'
        )}
      >
        <div className={classNames('flex flex-grow items-center gap-x-3 py-3', className)}>
          {editable && (
            <>
              {visibleButtons.includes('bold') && (
                <EditorButton
                  onClick={() => {
                    editor.chain().focus().toggleBold().run();
                  }}
                  disabled={!editor.can().chain().focus().toggleBold().run() || disabled}
                  active={editor.isActive('bold')}
                  tooltipText="bold"
                  Icon={BoldIcon}
                  iconSize={iconSize}
                />
              )}
              {visibleButtons.includes('italic') && (
                <EditorButton
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  disabled={!editor.can().chain().focus().toggleItalic().run() || disabled}
                  active={editor.isActive('italic')}
                  tooltipText="italic"
                  Icon={Italic}
                  iconSize={iconSize}
                />
              )}
              {visibleButtons.includes('heading1') && (
                <EditorButton
                  onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                  disabled={
                    !editor.can().chain().focus().toggleHeading({ level: 1 }).run() || disabled
                  }
                  active={editor.isActive('heading', { level: 1 })}
                  Icon={H1}
                  tooltipText="heading"
                  iconSize={iconSize}
                />
              )}
              {visibleButtons.includes('heading2') && (
                <EditorButton
                  onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                  disabled={
                    !editor.can().chain().focus().toggleHeading({ level: 2 }).run() || disabled
                  }
                  active={editor.isActive('heading', { level: 2 })}
                  Icon={H2}
                  tooltipText="subheading"
                  iconSize={iconSize}
                />
              )}
              {visibleButtons.includes('bulletList') && (
                <EditorButton
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  disabled={disabled}
                  active={editor.isActive('bulletList')}
                  Icon={List}
                  tooltipText="bullet list"
                  iconSize={iconSize}
                />
              )}
              {visibleButtons.includes('orderedList') && (
                <EditorButton
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  disabled={disabled}
                  active={editor.isActive('orderedList')}
                  Icon={OList}
                  tooltipText="ordered list"
                  iconSize={iconSize}
                />
              )}

              {hasSnippetButton && (
                <AccessControl myPermission={myPermission} requiredPermission="WRITE">
                  <SnippetsDropdown
                    onClickSnippet={
                      onHandleSnippetClick ||
                      (() => {
                        undefined;
                      })
                    }
                  />
                </AccessControl>
              )}
            </>
          )}
        </div>
        {extraButtons && <div className="flex items-center gap-x-4 py-3 pr-6">{extraButtons}</div>}
      </div>
    </div>
  );
};
