import { DialogLayout } from '../DialogLayout';
import { Button } from '../Button';
import { LinkIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { Link } from '../Link';
import { USER_PERMISSION_LABELS, classNames, copyToClipboard } from 'src/lib';
import { Form } from '../Form/Form';
import { TextField } from '../TextField';
import { DropdownSelect } from '../DropdownSelect';
import { useAuth } from 'src/auth';
import { UserIcon } from '../UserIcon';
import { ACCESS_LEVEL, PermissionFields } from 'types/graphql';
import { FormError } from '../FormError';
import {
  INVITE_USER_DROPDOWN_OPTIONS,
  ORGANISATION_PERMISSION_DROPDOWN_OPTIONS,
  USER_PERMISSION_DROPDOWN_OPTIONS,
} from 'src/lib/sharedConstants/userRoleDropdownOptions';
import { InviteFormValues, inviteFormSchema } from './inviteFormSchema';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { sortUserPermissions } from 'src/lib/sortUserPermissions';

export type ShareDialogProps = {
  onClose: () => void;
  isOwner: boolean;
  title: string;
  description: string;
  // Use fragment to ensure aliased field is available in the type
  permissionsData: PermissionFields[];
  onCopy?: () => void;
  onSetAccessToUser: (email: string, permisison: ACCESS_LEVEL, name: string) => Promise<void>;
  onSetAccessToOrganisation: (permisison: ACCESS_LEVEL | null) => Promise<void>;
  onAddAccess: (values: InviteFormValues) => Promise<void>;
};

export const ShareDialog = ({
  onClose,
  isOwner,
  title,
  description,
  permissionsData,
  onCopy,
  onAddAccess,
  onSetAccessToUser,
  onSetAccessToOrganisation,
}: ShareDialogProps) => {
  const { currentUser } = useAuth();

  const handleCopyLink = async () => {
    if (onCopy) {
      onCopy();
    } else {
      const urlToCopy = `${window.location.origin}${window.location.pathname}`;
      await copyToClipboard(urlToCopy);
    }
  };

  const formMethods = useForm<InviteFormValues>({
    resolver: zodResolver(inviteFormSchema),
    defaultValues: {
      email: '',
      permission: 'READ',
    },
  });

  const handleFormSubmit: SubmitHandler<InviteFormValues> = async (values) => {
    onAddAccess(values);
  };

  const inviteAccessDropdown = formMethods.watch('permission');

  const organisationPermissions = permissionsData.filter(
    (perm) => perm.__typename === 'OrganisationPermission'
  );

  const sortedUserPermissions = sortUserPermissions(permissionsData, currentUser);

  const combinedPermissions = [...organisationPermissions, ...sortedUserPermissions];

  const permissionsList = combinedPermissions.map((permission) => {
    // Handling UserPermission
    if (permission.__typename === 'UserPermission') {
      const isCurrentUser = permission.user.email === currentUser?.email;
      const userInfo = isCurrentUser ? 'You' : permission?.user?.email || permission?.user?.name;
      const userName = permission?.user?.name || permission?.user?.email;

      const permissionLabel =
        USER_PERMISSION_LABELS[permission?.permission as keyof typeof USER_PERMISSION_LABELS] || '';
      return (
        <div key={permission.id} className="mb-2 flex justify-between">
          <div className="flex items-center justify-center gap-4">
            <UserIcon user={permission.user} size="medium" />
            <div className={`text-sm ${isCurrentUser ? 'text-text-verydark' : 'text-text-dark'}`}>
              {userInfo}
            </div>
          </div>
          {isOwner ? (
            !isCurrentUser ? (
              <div className="text-sm text-text-dark">
                <DropdownSelect
                  items={USER_PERMISSION_DROPDOWN_OPTIONS}
                  value={permission?.permission}
                  onChange={(newRole) =>
                    onSetAccessToUser(permission?.user?.email, newRole as ACCESS_LEVEL, userName)
                  }
                />
              </div>
            ) : (
              <div className="text-sm text-text-veryDark">{permissionLabel}</div>
            )
          ) : (
            <div className="text-sm text-text-dark">{permissionLabel}</div>
          )}
        </div>
      );
    }
    // Handling OrganisationPermission
    else if (permission.__typename === 'OrganisationPermission') {
      const organisationName = permission?.organisation?.name;
      const permissionLabel =
        USER_PERMISSION_LABELS[permission?.orgPermission as keyof typeof USER_PERMISSION_LABELS] ||
        '';
      return (
        <div key={permission.id} className="mb-2 flex justify-between">
          <div className="flex items-center gap-4">
            <UserGroupIcon className="h-6 w-6 text-gray-400" />
            <div className="text-sm">{organisationName} members</div>
          </div>
          {isOwner ? (
            <div className="text-sm text-text-dark">
              <DropdownSelect
                items={ORGANISATION_PERMISSION_DROPDOWN_OPTIONS}
                value={permission?.orgPermission ?? null}
                onChange={(newRole) => onSetAccessToOrganisation(newRole)}
              />
            </div>
          ) : (
            <div className="text-sm text-text-dark">{permissionLabel}</div>
          )}
        </div>
      );
    }
  });

  return (
    <DialogLayout
      title={isOwner ? title : ''}
      onClose={onClose}
      className="w-full max-w-full overflow-visible md:max-w-md"
    >
      <Form
        onSubmit={(data) => {
          handleFormSubmit(data);
          formMethods.reset();
        }}
        formMethods={formMethods}
      >
        {isOwner && (
          <>
            <p className="mb-4 pt-4 text-sm text-text-medium">{description}</p>
            <div className="space-y-1">
              <label className="text-sm text-text-dark">Invite</label>
              <hr />
            </div>
            <div className="relative flex flex-col gap-4 pt-4 md:flex-row md:items-center md:justify-between">
              <div className="relative flex min-h-[36px] min-w-[365px]">
                <TextField name="email" schema={inviteFormSchema} />
                <div
                  className={classNames(
                    'absolute right-0 top-0 flex h-full items-center pr-2 pt-1 text-sm text-text-dark',
                    formMethods.formState.errors.email?.message && 'pb-6'
                  )}
                >
                  <DropdownSelect<(typeof INVITE_USER_DROPDOWN_OPTIONS)[0]['value']>
                    items={INVITE_USER_DROPDOWN_OPTIONS}
                    value={inviteAccessDropdown}
                    onChange={(newValue) => formMethods.setValue('permission', newValue)}
                  />
                </div>
              </div>
              <FormError />
              <Button
                text="Invite"
                size="small"
                type="submit"
                className="mb-1.5 self-end md:w-auto"
              />
            </div>
          </>
        )}

        <div className="mb-6 pt-4">
          <div className="mb-2 space-y-1 text-sm text-text-dark">
            <p>Users With Access</p>
            <hr />
          </div>
          {permissionsList.length > 0 ? (
            <div className="pt-2">{permissionsList}</div>
          ) : (
            <div className="flex items-center gap-4">
              <UserIcon user={currentUser} />
              <p className="text-sm text-text-dark">You</p>
            </div>
          )}
        </div>

        <div className="flex  items-center justify-between gap-2 ">
          <p className="text-sm text-text-medium">Only members with access can open this link.</p>
          <Link onClick={handleCopyLink} size="large">
            <div className="flex cursor-pointer items-center justify-center gap-2">
              <p className="text-sm font-medium text-primary-medium">Copy Link</p>
              <LinkIcon className="h-5 w-5" />
            </div>
          </Link>
        </div>
      </Form>
    </DialogLayout>
  );
};
