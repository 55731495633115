import {
  ArrowPathIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  ArrowDownTrayIcon,
  PaintBrushIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@redwoodjs/web';
import { FC, useEffect, useRef } from 'react';
import {
  ACCESS_LEVEL,
  ChangeDocumentStyleMutation,
  ChangeDocumentStyleMutationVariables,
  DocumentFieldsWithHistory,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  RegenerateDocument,
  RegenerateDocumentVariables,
} from 'types/graphql';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { useDialog, useEditDocument, useExportDocument } from 'src/hooks';
import { IconButton } from 'src/components/IconButton';
import { RichTextEditor } from 'src/components/RichTextEditor';
import type { RichTextEditorRef } from 'src/components/RichTextEditor';
import { Spinner } from 'src/components/Spinner';
import { canDeleteDocument } from 'src/lib/document';
import { navigate, routes, useLocation } from '@redwoodjs/router';
import {
  GET_JOB_AND_DOCUMENTS,
  GET_CANDIDATE_AND_DOCUMENTS_QUERY,
  GET_COMPANY_AND_DOCUMENTS_QUERY,
} from 'src/graphql/queries';

import { toast } from '@redwoodjs/web/dist/toast';
import {
  CHANGE_DOCUMENT_STYLE_MUTATION,
  REGENERATE_DOCUMENT_MUTATION,
} from 'src/graphql/mutations';
import { ChangeStyleDialog } from './ChangeStyleDialog';
import { DocumentHistoryDialog } from 'src/components/DocumentHistoryDialog';
import { FadeIn } from 'src/components/FadeIn';
import { AccessControl } from 'src/components/AccessControl';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { copyToClipboard } from 'src/lib';
import { EditorEvents } from '@tiptap/react';
import { DocumentTopBar } from './DocumentTopBar';
import { ShareDocumentDialog } from 'src/components/ShareDialogs';

type Props = {
  document: DocumentFieldsWithHistory;
};

export const RichTextDocumentEditor: FC<Props> = ({ document: documentData }) => {
  const { show, close } = useDialog();

  const { pathname } = useLocation();

  const exportDocument = useExportDocument();

  const { data, previousData, loading, error } = useQuery<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >(GET_DOCUMENT_QUERY, {
    variables: { id: documentData.id },
    // Return base document while history is fetched in background
    returnPartialData: true,
  });

  const richTextEditorRef = useRef<RichTextEditorRef>(null);

  const {
    updateDocument,
    loading: editDocumentLoading,
    isPending,
  } = useEditDocument(documentData.id);

  useEffect(() => {
    // Ensure there's document data and the editor ref is initialized
    if (data?.document?.markup && richTextEditorRef.current) {
      const editorContent = richTextEditorRef.current.getContent();
      const documentMarkup = data.document.markup;

      // Update the editor content if the current content differs from the latest document data, and there are no pending updates.
      if (editorContent !== documentMarkup && !(editDocumentLoading || isPending)) {
        richTextEditorRef.current.setContent(documentMarkup);
      }
    }
  }, [data, editDocumentLoading, isPending]);

  const myPermission = data?.document?.myPermission;
  const documentPermissions = data?.document?.permissions;

  const [regenerate, { loading: isRegenerating }] = useMutation<
    RegenerateDocument,
    RegenerateDocumentVariables
  >(REGENERATE_DOCUMENT_MUTATION, {
    variables: { input: { id: documentData.id } },
    onCompleted: (result) => {
      richTextEditorRef.current?.setContent(result.regenerateDocument.markup);
      toast.success('Document regenerated.');
    },
  });

  const [changeDocumentStyle, { loading: isChangingStyle }] = useMutation<
    ChangeDocumentStyleMutation,
    ChangeDocumentStyleMutationVariables
  >(CHANGE_DOCUMENT_STYLE_MUTATION, {
    refetchQueries: [
      GET_JOB_AND_DOCUMENTS,
      GET_COMPANY_AND_DOCUMENTS_QUERY,
      GET_CANDIDATE_AND_DOCUMENTS_QUERY,
      {
        query: GET_DOCUMENT_QUERY,
        variables: {
          id: documentData.id,
        },
      },
    ],
    onCompleted: (data) => {
      const updatedMarkup = data.changeDocumentStyle.markup;
      richTextEditorRef.current?.setContent(updatedMarkup);
      toast.success('Document updated with desired style!');
    },
  });

  const document = (data ?? previousData)?.document ?? documentData;

  const handleCopyDocumentMarkup = () => {
    copyToClipboard(document?.markup ?? '');
  };

  const handleCopyDocumentLink = async () => {
    const urlToCopy = `${window.location.origin}/document/${document?.id}`;
    await copyToClipboard(urlToCopy);
  };

  const handleSnippetClick = (htmlContent: string) => {
    if (richTextEditorRef.current) {
      const editor = richTextEditorRef.current.getEditor();
      editor?.chain().focus().insertContent(htmlContent).run();
    }
  };

  /* Handles changes in the rich text editor. It updates the document with the
   new content and sets the hasUserEdited flag to true. This flag is used to
   distinguish between manual user edits and programmatic changes to the editor's
   content, ensuring that the updateDocument mutation is only called in response
   to user actions. */
  const handleEditorChange = (event: EditorEvents['update']) => {
    const value = event.editor.getHTML();
    if (value !== document.markup) {
      updateDocument(event);
    }
  };

  const onRestoreDocument = (markup: string) => {
    richTextEditorRef.current?.setContent(markup);
  };

  if (loading && !document) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (!document) {
    return null;
  }

  const canDelete = canDeleteDocument(document.__typename);
  const showRegenerateButton = document.__typename !== 'General';

  const onChangeStyle = (document: DocumentFieldsWithHistory) => {
    document.config?.configSchema
      ? show(
          <ChangeStyleDialog
            document={document}
            template={document.config}
            onChangeStyle={(config, saveDefault, templateId) => {
              if (!(document?.config && document?.id)) {
                console.error('Tried to change a document style without a config or docId');
                return;
              }

              changeDocumentStyle({
                variables: {
                  input: {
                    config: JSON.stringify(config),
                    templateId: templateId ?? document.config.id,
                    id: document.id,
                    default: saveDefault ?? undefined,
                  },
                },
              });
              close();
            }}
            onClose={close}
          />
        )
      : undefined;
  };

  const dropdownButtons = [
    {
      text: 'Change Style',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      onClick: onChangeStyle,
      Icon: PaintBrushIcon,
    },
    {
      text: 'Edit with Chat',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      onClick: () =>
        navigate(
          routes.chat({
            documentId: document.id,
            redirectUrl: pathname,
          })
        ),
      Icon: ChatBubbleLeftIcon,
    },
    {
      text: 'Share Document',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      Icon: UserPlusIcon,
      onClick: () => {
        show(
          <ShareDocumentDialog
            document={document}
            onClose={close}
            onCopy={handleCopyDocumentLink}
          />
        );
      },
    },
    {
      text: 'Export',
      Icon: ArrowDownTrayIcon,
      requiredPermission: 'READ' as ACCESS_LEVEL,
      onClick: () =>
        exportDocument(document.id, document.title || document.__typename || 'Document'),
    },
  ];

  return (
    <div className="relative flex h-full min-h-0 flex-1 flex-col">
      <DocumentTopBar
        permissionsData={documentPermissions ?? []}
        myPermission={document.myPermission}
        dropdownButtons={dropdownButtons}
        document={document}
        onCopyLinkToDocument={handleCopyDocumentLink}
      />

      <RichTextEditor
        editable={hasRequiredAccess(myPermission, 'WRITE')}
        ref={richTextEditorRef}
        content={document.markup}
        onChange={handleEditorChange}
        onHandleSnippetClick={handleSnippetClick}
        myPermission={myPermission}
        extraButtons={
          <>
            <AccessControl myPermission={myPermission} requiredPermission="WRITE">
              <FadeIn visible>
                <IconButton
                  tooltipText="History"
                  size="medium"
                  Icon={ClockIcon}
                  onClick={() =>
                    show(
                      <DocumentHistoryDialog
                        onRestore={onRestoreDocument}
                        onClose={close}
                        documentId={document.id}
                      />
                    )
                  }
                />
              </FadeIn>
            </AccessControl>
            {showRegenerateButton && (
              <AccessControl myPermission={myPermission} requiredPermission="OWNER">
                <FadeIn visible={showRegenerateButton}>
                  <IconButton
                    tooltipText="Regenerate"
                    size="medium"
                    Icon={ArrowPathIcon}
                    onClick={regenerate}
                  />
                </FadeIn>
              </AccessControl>
            )}
            <IconButton
              tooltipText="Copy to Clipboard"
              size="medium"
              Icon={DocumentDuplicateIcon}
              onClick={handleCopyDocumentMarkup}
            />
          </>
        }
      />

      {(isRegenerating || isChangingStyle) && (
        <div className="absolute inset-0 rounded-[36px] bg-white opacity-80">
          <Spinner />
        </div>
      )}
    </div>
  );
};
