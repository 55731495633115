import { FC } from 'react';
import { Button } from '../../../components/Button';
import { useMutation } from '@redwoodjs/web';
import { ChangePasswordMutation, ChangePasswordMutationVariables } from 'types/graphql';
import { KeyIcon } from '@heroicons/react/24/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useDialog, usePageClasses } from '../../../hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from '@redwoodjs/web/dist/toast';
import { passwordRegex } from '../../../lib';
import { CHANGE_PASSWORD_MUTATION } from '../../../graphql/mutations/changePasswordMutation';
import { Form } from 'src/components/Form';
import { TextField } from 'src/components';

import { SettingsItem } from '../SettingsComponents';
import { useAuth } from 'src/auth';
import { DialogLayout, FormError } from 'src/components';

const AccountPage: FC = () => {
  usePageClasses('bg-white');
  const { logOut, currentUser } = useAuth();
  const { show } = useDialog();

  const onChangePassword = () => {
    show(<ChangePasswordDialog />);
  };

  return (
    <div className="flex w-full max-w-[740px] flex-col gap-y-4 p-6">
      <h3 className="text-xl font-bold text-text-dark">Account</h3>
      <div className="flex flex-col gap-y-[34px]">
        <SettingsItem label="Email" value={currentUser?.email ?? ''}>
          <Button
            variant="outline"
            size="medium"
            text="Change Password"
            onClick={onChangePassword}
          />
        </SettingsItem>
        {/* TODO: uncomment this later, when linkedin feature is completed */}
        {/* <SettingsItem
          label="LinkedIn"
          value="Connect LinkedIn Account"
          LeftIcon={LinkedinIcon}
        >
          <Button
            variant="outline"
            size="medium"
            text="Disconnect"
            onClick={() => {
              console.log('Disconnect');
            }}
          />
        </SettingsItem> */}
        <div className="flex justify-end pt-5">
          <Button variant="outline" size="medium" text="Log Out" onClick={logOut} />
        </div>
      </div>
    </div>
  );
};

const changePasswordFormSchema = z.object({
  currentPassword: z.string().min(1, { message: 'Required' }),
  newPassword: z.string().min(1, { message: 'Required' }).regex(passwordRegex, {
    message:
      'Must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, and one number',
  }),
  confirmPassword: z.string().min(1, { message: 'Required' }),
});

type ChangePasswordSchema = z.infer<typeof changePasswordFormSchema>;

const ChangePasswordDialog: FC = () => {
  const { close } = useDialog();
  const formMethods = useForm<ChangePasswordSchema>({
    resolver: zodResolver(changePasswordFormSchema),
  });
  const [changePassword, { loading, error }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION, {
    onCompleted: () => {
      toast.success('Password changed');
      close();
    },
  });

  const onResetPassword: SubmitHandler<ChangePasswordSchema> = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      formMethods.setError('confirmPassword', {
        message: 'Passwords do not match',
      });
      return;
    }

    changePassword({
      variables: {
        input: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      },
    });
  };

  return (
    <DialogLayout
      Icon={KeyIcon}
      title="Change Password"
      onClose={close}
      className="min-h-[513px] w-full max-w-full rounded-[32px] md:max-w-[457px]"
      titleClassName="text-text-veryDark text-2xl font-normal "
    >
      <Form<ChangePasswordSchema>
        formMethods={formMethods}
        onSubmit={onResetPassword}
        className="flex flex-col gap-y-4 pt-2"
      >
        <p className="pt-4 text-sm font-normal text-text-medium">
          Must be at least 8 characters long and include a number or special character.
        </p>
        <div className="flex flex-col justify-center space-y-7 pt-2">
          <TextField
            schema={changePasswordFormSchema}
            type="password"
            name="currentPassword"
            label="Current Password"
            disabled={loading}
          />
          <TextField
            schema={changePasswordFormSchema}
            type="password"
            name="newPassword"
            label="New Password"
            disabled={loading}
          />
          <TextField
            schema={changePasswordFormSchema}
            type="password"
            name="confirmPassword"
            label="Confirm New Password"
            disabled={loading}
          />
          <FormError error={error} />
          <Button type="submit" size="large" text="Submit" loading={loading} />
        </div>
      </Form>
    </DialogLayout>
  );
};

export default AccountPage;
