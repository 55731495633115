import { useState, useRef, useCallback, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import useResizeObserver from 'use-resize-observer';
import { classNames } from 'src/lib';
import { Link } from 'src/components/Link';
import { PencilIcon } from '@heroicons/react/24/outline';

// Set the worker source for PDF.js to handle PDF parsing in a separate thread.
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const CANVAS_PADDING = 16;
const EDIT_BUTTON_HEIGHT = 24;
let renderTask: any | null = null;

export const PdfViewer: React.FC<{
  fileUrl: string;
  className?: string;
  loading?: boolean;
  edit?: {
    onClick: () => void;
    text: string;
  };
}> = ({ fileUrl, className, edit, loading }) => {
  const [pdf, setPdf] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { ref: containerRef, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  /**
   * Function to load the PDF document from the given file URL.
   * - Uses PDF.js to load and parse the PDF.
   * - Sets the loaded PDF document to the `pdf` state.
   */
  const loadPdf = useCallback(async () => {
    try {
      const loadingTask = pdfjsLib.getDocument(fileUrl);
      const loadedPdf = await loadingTask.promise;
      setPdf(loadedPdf); // Store the loaded PDF document in state.
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  }, [fileUrl]);

  /**
   * Function to render the current page of the loaded PDF document.
   * - Fetches the page based on the `pageNumber` state.
   * - Renders the page into the canvas element.
   */
  const renderPage = useCallback(async () => {
    if (!(pdf && canvasRef.current && width && height)) return;

    try {
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5, rotation: page.rotate ?? 0 });

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (canvas && context) {
        // Set the canvas dimensions to match the page size.
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        if (renderTask) {
          renderTask.cancel();
        }

        renderTask = page.render(renderContext); // Render the page into the canvas.
        await renderTask.promise;
      }
    } catch (error) {
      console.error('Error rendering page:', error);
    }
  }, [pdf, pageNumber, width, height, canvasRef]);

  const nextPage = () => {
    if (pdf && pageNumber < pdf.numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pdf && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  // Load the PDF document when the component mounts or when `fileUrl` changes.
  useEffect(() => {
    loadPdf();
  }, [loadPdf]);

  // Re-render the page whenever the `pageNumber` or `pdf` changes.
  useEffect(() => {
    renderPage();
  }, [renderPage, width, height, pdf]);

  // Ensure the `pageNumber` does not exceed the total number of pages in the PDF.
  useEffect(() => {
    if (pageNumber > (pdf?.numPages || 1)) {
      setPageNumber(pdf?.numPages || 1);
    }
  }, [pageNumber, pdf]);

  const editButtonAdjustment = edit ? EDIT_BUTTON_HEIGHT : 0;
  const pdfHeight = height - CANVAS_PADDING - editButtonAdjustment;

  return (
    <div className={`flex w-full flex-1 flex-col items-center overflow-hidden`}>
      {/* The PDF rendering area */}

      <div ref={containerRef} className="my-2 flex flex-1 flex-col items-center">
        {edit && (
          <div className="flex self-start pb-2">
            <Link size="large" variant="secondary" onClick={edit.onClick} LeftIcon={PencilIcon}>
              {edit.text}
            </Link>
          </div>
        )}

        <div
          className={classNames(`border border-gray-100 shadow-document`, loading && 'opacity-30')}
        >
          <canvas style={{ height: `${pdfHeight}px` }} ref={canvasRef} />
        </div>
      </div>
      <div className="flex items-center justify-center py-2">
        <span
          className={`select-none text-sm font-medium ${
            pageNumber === 1
              ? 'cursor-not-allowed text-text-light'
              : 'cursor-pointer text-text-medium hover:text-gray-400'
          }`}
          onClick={prevPage}
        >
          {'<'}
        </span>
        <span className="mx-4 font-medium text-text-medium">
          {pageNumber} / {pdf?.numPages}
        </span>
        <span
          className={`select-none text-sm font-medium ${
            pageNumber === pdf?.numPages
              ? 'cursor-not-allowed text-text-light'
              : 'cursor-pointer text-text-medium hover:text-gray-400'
          }`}
          onClick={nextPage}
        >
          {'>'}
        </span>
      </div>
    </div>
  );
};
