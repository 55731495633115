import { FC } from 'react';

import { Tooltip } from 'src/components';

import { classNames } from 'src/lib';

type EditorButtonProps = {
  Icon: FC<React.ComponentProps<'svg'>>;
  onClick: () => void;
  tooltipText: string;
  active?: boolean;
  disabled?: boolean;
  iconSize?: string;
};

export const EditorButton: FC<EditorButtonProps> = ({
  Icon,
  onClick,
  tooltipText,
  active,
  disabled,
  iconSize = 'h-6 w-6',
}) => {
  return (
    <div>
      <Tooltip innerBody={tooltipText}>
        <button
          type="button"
          disabled={disabled}
          className={classNames(
            'rounded-lg p-1 text-text-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-medium focus:ring-offset-2 focus:ring-offset-gray-100',
            active && 'bg-gray-100 text-text-veryDark',
            disabled && 'cursor-not-allowed bg-gray-100 hover:bg-gray-50'
          )}
          onClick={onClick}
        >
          <Icon className={classNames(iconSize, active && 'stroke-[3]')} />
        </button>
      </Tooltip>
    </div>
  );
};
