import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { z } from 'zod';
import { Reorder } from 'framer-motion';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { IconButton, Tooltip } from 'src/components';
import GrabIcon from 'src/assets/GrabIcon';
import { classNames } from 'src/lib';

export type StructuredProps = {
  structuredItems: { key: string; visible: boolean }[];
  label?: string;
  onChange: (value: { key: string; visible: boolean }[]) => void;
};

const structuredItemSchema = z.object({
  key: z.string(),
  visible: z
    .union([z.boolean(), z.literal('true'), z.literal('false')])
    .transform((value) => (typeof value === 'string' ? value === 'true' : value)),
});

const structuredItemsSchema = z.array(structuredItemSchema);

export const Structured: FC<StructuredProps> = ({ structuredItems, label, onChange }) => {
  const toggleVisibility = (key: string) => {
    const newValue = structuredItems.map((item) =>
      item.key === key ? { ...item, visible: !item.visible } : item
    );
    onChange(newValue);
  };

  const handleReorder = (newOrder: { key: string; visible: boolean }[]) => {
    onChange(newOrder);
  };

  return (
    <div className="flex flex-col gap-2">
      {label && <div className="text-lg font-semibold text-text-dark">{label}</div>}

      <Reorder.Group
        axis="y"
        values={structuredItems}
        onReorder={handleReorder}
        className="flex flex-col gap-2 rounded-lg bg-white"
      >
        {structuredItems?.map((item) => (
          <Reorder.Item
            key={item.key}
            value={item}
            className="transition-al group relative flex h-10 cursor-grab items-center justify-between rounded-xl border border-text-light px-3 py-2"
            whileDrag={{
              scale: 1.02,
              boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
              background: 'white',
              rotate: 2,
            }}
          >
            <div className="absolute -left-6 flex cursor-grab items-center text-gray-400 transition-colors group-hover:text-text-dark">
              <Tooltip innerBody="Drag to reorder" minWidth="110px">
                <GrabIcon className="h-5 w-5" strokeWidth={2} />
              </Tooltip>
            </div>
            <div
              className={classNames(
                'flex-grow pr-6 text-xs font-medium',
                item.visible ? 'text-text-medium' : 'text-text-light'
              )}
            >
              {item.key}
            </div>
            <div className="flex-shrink-0">
              <IconButton
                size="small"
                className={item.visible ? 'text-text-medium' : 'text-text-light'}
                Icon={item.visible ? EyeIcon : EyeSlashIcon}
                tooltipText={item.visible ? 'Hide' : 'Show'}
                onClick={() => toggleVisibility(item.key)}
              />
            </div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};

export const StructuredField = ({ name, label }: { name: string; label?: string }) => (
  <Controller
    name={name}
    render={({ field }) => {
      const transformedValue = structuredItemsSchema.parse(field.value ?? []);

      return (
        <Structured
          structuredItems={transformedValue}
          label={label}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
        />
      );
    }}
  />
);
