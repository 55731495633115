import { SubmitHandler, useForm } from 'react-hook-form';
import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { ACCESS_LEVEL, GetJobAndDocuments } from '../../../types/graphql';
import { FormError, Link, SelectField, TextAreaField, TextField } from 'src/components';
import { CompanyAutocompleteField } from '../../components/Autocomplete';
import { Button } from '../../components/Button';
import { contractOptions, jobFormSchema, JobFormValues } from '../../lib/formSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from 'src/components/Form/Form';
import { HideIfReadOnly } from 'src/components/HideIfReadOnly';
import { FadeIn } from 'src/components/FadeIn';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

type Props = {
  onClickRegenerate: SubmitHandler<JobFormValues>;
  job: GetJobAndDocuments['job'];
  error?: ApolloError;
  myPermission?: ACCESS_LEVEL | null;
  isRegenerateCampaignButtonVisible?: boolean;
  onHandleRegenerateCampaign?: () => void;
  regenerateCampaignLoading?: boolean;
  hasCampaign?: boolean;
};

export const RegenerateForm: FC<Props> = ({
  onClickRegenerate,
  job,
  error,
  myPermission,
  isRegenerateCampaignButtonVisible,
  onHandleRegenerateCampaign,
  regenerateCampaignLoading,
  hasCampaign,
}) => {
  if (!job) {
    return null;
  }

  const formMethods = useForm<JobFormValues>({
    defaultValues: {
      contract: job.contractType as (typeof contractOptions)[number],
      benefits: job.company.benefits,
      company: { id: job.company.id, name: job.company.name },
      culture: job.company.culture,
      location: job.company.location,
      overview: job.company.overview,
      responsibilities: job.roleAndResponsibilities,
      salary: job.salary,
      title: job.title,
      mustHaves: job.mustHaves,
    },
    resolver: zodResolver(jobFormSchema),
  });

  const selectOptions = contractOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <Form
        readOnly={myPermission === 'READ'}
        className="flex min-h-0 flex-1 flex-col"
        formMethods={formMethods}
        onSubmit={onClickRegenerate}
      >
        <div className="flex flex-1 flex-col gap-y-3 overflow-auto px-4 md:px-16">
          <div className="pt-4" />
          <FormError error={error} />
          <CompanyAutocompleteField
            label="Company Name"
            name="company"
            schema={jobFormSchema}
            disabled
          />
          <TextField label="Location" name="location" placeholder="e.g North London | Hybrid" />
          <TextAreaField
            label="Company Overview"
            name="overview"
            autoResize
            schema={jobFormSchema}
          />
          <TextAreaField label="Company Culture" name="culture" autoResize schema={jobFormSchema} />
          <TextAreaField label="Benefits" name="benefits" autoResize schema={jobFormSchema} />
          <TextField label="Job Title" name="title" schema={jobFormSchema} />
          <TextField label="Remuneration" name="salary" placeholder="e.g £30k-35k + Commission" />
          <SelectField
            label="Contract Type"
            name="contract"
            placeholder="Permanent"
            options={selectOptions}
          />
          <TextAreaField
            label="Role and Responsibilities"
            name="responsibilities"
            autoResize
            schema={jobFormSchema}
          />
          <TextAreaField label="Must Haves" name="mustHaves" autoResize schema={jobFormSchema} />
        </div>

        <div className="flex flex-col gap-y-3 px-4 py-4 md:px-16">
          <HideIfReadOnly>
            <Button className="flex-grow" text="Save Changes" size="mega" type="submit" />
          </HideIfReadOnly>
          <div className="h-4">
            <FadeIn visible={isRegenerateCampaignButtonVisible}>
              <div className="flex cursor-pointer items-center justify-center gap-2">
                <Link
                  onClick={onHandleRegenerateCampaign}
                  size="large"
                  disabled={regenerateCampaignLoading}
                >
                  <p className="text-sm font-medium text-primary-medium">
                    {`${hasCampaign ? 'Regenerate' : 'Generate'} Campaign`}
                  </p>
                </Link>
                <ArrowPathIcon className="h-5 w-5" />
              </div>
            </FadeIn>
          </div>
        </div>
      </Form>
    </div>
  );
};
