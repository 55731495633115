import { FC, PropsWithChildren } from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { InformationCircleIcon as OutlineIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon as FilledIcon } from '@heroicons/react/24/solid';

type InfoProps = PropsWithChildren<{
  iconType?: 'outline' | 'filled';
  size?: 'small' | 'regular';
  color?: 'gray' | 'dark';
}>;

export const Info: FC<InfoProps> = ({
  children,
  iconType = 'outline',
  size = 'regular',
  color = 'dark',
}) => {
  const Icon = iconType === 'filled' ? FilledIcon : OutlineIcon;
  const iconSizeClass = size === 'small' ? 'h-4 w-4' : 'h-5 w-5';
  const iconColorClass = color === 'gray' ? 'text-text-medium' : 'text-text-dark';

  return (
    <span className="inline cursor-pointer hover:opacity-85">
      <Tooltip position="right" innerBody={children}>
        <Icon className={`${iconSizeClass} ${iconColorClass}`} />
      </Tooltip>
    </span>
  );
};
