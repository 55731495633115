import { SubmitHandler, useForm } from 'react-hook-form';
import { FC } from 'react';
import { ACCESS_LEVEL, GetCompanyAndDocuments } from '../../../types/graphql';
import { FormError, Link, TextAreaField, TextField } from 'src/components';
import { Button } from '../../components/Button';
import { ApolloError } from '@apollo/client';
import { companyFormSchema, CompanyFormValues } from '../../lib/formSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from 'src/components/Form/Form';
import { HideIfReadOnly } from 'src/components/HideIfReadOnly';
import { FadeIn } from 'src/components/FadeIn';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

type Props = {
  onClickRegenerate: SubmitHandler<CompanyFormValues>;
  company: GetCompanyAndDocuments['company'];
  error?: ApolloError;
  myPermission?: ACCESS_LEVEL | null;
  isRegenerateCampaignButtonVisible?: boolean;
  onHandleRegenerateCampaign?: () => void;
  regenerateCampaignLoading?: boolean;
  hasCampaign?: boolean;
};

export const RegenerateForm: FC<Props> = ({
  onClickRegenerate,
  company,
  error,
  myPermission,
  isRegenerateCampaignButtonVisible,
  onHandleRegenerateCampaign,
  regenerateCampaignLoading,
  hasCampaign,
}) => {
  const formMethods = useForm<CompanyFormValues>({
    resolver: zodResolver(companyFormSchema),
    defaultValues: {
      benefits: company?.benefits,
      culture: company?.culture,
      location: company?.location,
      name: company?.name,
      overview: company?.overview,
      notes: company?.notes,
      website: company?.website,
    },
  });

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <Form<CompanyFormValues>
        readOnly={myPermission === 'READ'}
        className="flex min-h-0 flex-1 flex-col"
        formMethods={formMethods}
        onSubmit={onClickRegenerate}
      >
        <div className="flex flex-1 flex-col gap-y-3 overflow-auto px-4 md:px-16">
          <div className="pt-3" />
          <FormError error={error} />
          <div className="flex flex-col gap-3  xl:flex-row">
            <TextField label="Name" name="name" schema={companyFormSchema} />
            <TextField label="Website" name="website" />
          </div>
          <TextField label="Location" name="location" placeholder="e.g North London | Hybrid" />
          <TextAreaField
            label="Overview"
            name="overview"
            placeholder="e.g. global leading design and communications agency, 2,000 employees across 29 studios…"
            autoResize
            schema={companyFormSchema}
          />
          <TextAreaField
            label="Culture"
            name="culture"
            placeholder="e.g. growth-minded and ambitious, engaging and supportive environment..."
            autoResize
            schema={companyFormSchema}
          />
          <TextAreaField
            label="Benefits"
            name="benefits"
            placeholder="e.g. up to 2 days remote working, health insurance…"
            autoResize
            schema={companyFormSchema}
          />
          <TextAreaField className="min-h-[100px]" label="Notes" name="notes" />
        </div>

        <div className="flex flex-col gap-y-3 px-4 py-4 md:px-16">
          <HideIfReadOnly>
            <Button className="flex-grow" text="Save Changes" size="mega" type="submit" />
          </HideIfReadOnly>
          <div className="h-4">
            <FadeIn visible={isRegenerateCampaignButtonVisible}>
              <div className="flex cursor-pointer items-center justify-center gap-2">
                <Link
                  onClick={onHandleRegenerateCampaign}
                  size="large"
                  disabled={regenerateCampaignLoading}
                >
                  <p className="text-sm font-medium text-primary-medium">
                    {`${hasCampaign ? 'Regenerate' : 'Generate'} Campaign`}
                  </p>
                </Link>
                <ArrowPathIcon className="h-5 w-5" />
              </div>
            </FadeIn>
          </div>
        </div>
      </Form>
    </div>
  );
};
