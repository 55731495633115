import { useApolloClient } from '@apollo/client';
import { toast } from '@redwoodjs/web/dist/toast';
import { saveAs } from 'file-saver';
import { GENERATE_BRANDED_DOCUMENT_QUERY } from 'src/graphql/queries';
import { GenerateBrandedDocumentQuery, GenerateBrandedDocumentQueryVariables } from 'types/graphql';

export function useExportDocument() {
  const client = useApolloClient();

  const exportDocument = async (documentId: string, title: string) => {
    try {
      toast.loading('Exporting document...');
      const pdf = await client.query<
        GenerateBrandedDocumentQuery,
        GenerateBrandedDocumentQueryVariables
      >({
        query: GENERATE_BRANDED_DOCUMENT_QUERY,
        variables: {
          input: {
            documentId,
          },
        },
      });

      if (pdf.error) {
        throw new Error('Failed to generate branded document');
      }
      const base64Content = pdf.data.generateBrandedDocument.pdfContent;

      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      await saveAs(blob, `${title}.pdf`);
    } catch (e) {
      console.error('Export failed:', e);
      toast.error('Export failed');
    } finally {
      toast.dismiss();
    }
  };

  return exportDocument;
}
