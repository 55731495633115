import { BoltIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { PageTitle } from '../PageTitle';
import { TextAreaField } from '../TextArea';
import { TextField } from '../TextField';
import { BaseForm } from './BaseForm';
import { BaseFormProps } from './types';
import { useTemplateInputsStore } from '../../services/store';
import { TestimonialRequest } from '../../../types/graphql';
import { TestimonialRequestFormValues, testimonialRequestFormSchema } from '../../lib/formSchemas';
import { EnumField } from '../DocumentConfigForm/EnumField';
import { CompanyAutocompleteField } from '../Autocomplete';

/**
 * In this form, company autocomplete is used just for the company name.
 * The input is stored as a raw string, not a company object.
 * We transform the company object into a string in the onSubmit handler, and then store it in the cache.
 */
export const TestimonialRequestForm: FC<BaseFormProps> = ({
  onSubmit,
  operation,
  document,
  documentLoading,
  mutationError,
  mutationLoading,
}) => {
  const cachedInput = useTemplateInputsStore((state) => state.testimonialRequest);
  const formMethods = useForm<TestimonialRequestFormValues>({
    resolver: zodResolver(testimonialRequestFormSchema),
  });

  if (document && document?.__typename !== 'TestimonialRequest') {
    throw new Error('Document is not a TestimonialRequest');
  }

  const handleSubmit: SubmitHandler<TestimonialRequestFormValues> = ({ companyName, ...data }) => {
    useTemplateInputsStore.setState({
      testimonialRequest: {
        ...data,
        companyName: companyName.name,
      },
    });
    onSubmit({
      inputData: {
        testimonialRequest: {
          ...data,
          companyName: companyName.name,
        },
      },
    });
  };

  useEffect(() => {
    if (document?.__typename === 'TestimonialRequest') {
      const documentData = document as TestimonialRequest;
      const { companyName, ...rest } = documentData.input;
      formMethods.reset({
        ...rest,
        companyName: { name: companyName, id: undefined },
      });
    } else if (cachedInput) {
      const { companyName, ...rest } = cachedInput;
      formMethods.reset({
        ...rest,
        companyName: { name: companyName, id: undefined },
      });
    }
  }, [document, formMethods]);

  const recipient = formMethods.watch('recipient');

  const servicePlaceholderText =
    recipient === 'candidate'
      ? 'E.g. Placed in an Architect role'
      : 'E.g. Placed an Architectural Technician';

  const recipientNameLabel = recipient === 'candidate' ? 'Candidate Name' : 'Recipient Name';

  const companyLabel = recipient === 'candidate' ? 'Candidate Company Name' : 'Client Company Name';

  return (
    <BaseForm<TestimonialRequestFormValues>
      formProps={{ onSubmit: handleSubmit, formMethods }}
      operation={operation}
      documentLoading={documentLoading}
      mutationError={mutationError}
      mutationLoading={mutationLoading}
      document={document}
    >
      <PageTitle Icon={BoltIcon} text="Testimonial Request" />
      <EnumField
        schema={{
          default: 'client',
          enum: ['client', 'candidate'],
          title: 'Recipient',
          type: 'string',
          description: 'Who is the testimonial for?',
          enumDetails: [
            { label: 'Client', value: 'client' },
            { label: 'Candidate', value: 'candidate' },
          ],
          order: 0,
        }}
        name="recipient"
      />
      <CompanyAutocompleteField
        name="companyName"
        label={companyLabel}
        schema={testimonialRequestFormSchema}
      />
      <TextField
        name="recipientName"
        label={recipientNameLabel}
        schema={testimonialRequestFormSchema}
      />
      <TextField name="duration" label="Duration of Partnership" placeholder="6 Months" />
      <TextAreaField
        name="servicesProvided"
        label="Specific Services Provided"
        className="min-h-[200px]"
        placeholder={servicePlaceholderText}
        schema={testimonialRequestFormSchema}
      />
    </BaseForm>
  );
};
