import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller } from 'react-hook-form';
import { PlusIcon } from '@heroicons/react/20/solid';
import { textInputClasses } from '../TextField/TextField';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

export type ColorPickerFieldProps = {
  name: string;
  colorRecommendations?: string[];
};

export const ColorPickerField: React.FC<ColorPickerFieldProps> = ({
  name,
  colorRecommendations,
}) => {
  return (
    <Popover className="h-9 w-9">
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            {value ? (
              <PopoverButton
                className="h-9 w-9 rounded-xl border"
                style={{ backgroundColor: value }}
              ></PopoverButton>
            ) : (
              <PopoverButton className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-xl bg-gradient-conic p-1">
                <div className="relative flex h-full w-full items-center justify-center rounded-lg bg-black">
                  <PlusIcon className="z-2 absolute h-5 w-5 text-white" />
                </div>
              </PopoverButton>
            )}

            <PopoverPanel anchor="bottom start" className="z-20 rounded-xl bg-white shadow-md">
              <div className="w-72 rounded-lg bg-white p-4 shadow-lg">
                <div className="w-full">
                  <HexColorPicker color={value} onChange={onChange} style={{ width: '100%' }} />
                </div>
                <div className="mt-2 flex items-center gap-4">
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className={`${textInputClasses} w-full max-w-32 pl-3`}
                    placeholder="#FFFFFF"
                  />
                  <div
                    className="h-8 w-8 cursor-pointer rounded-full border"
                    style={{ backgroundColor: value }}
                  />
                </div>
                <div className="mt-3 grid grid-cols-5 justify-items-center gap-4">
                  {colorRecommendations?.map((recommendedColor) => (
                    <div
                      key={recommendedColor}
                      onClick={() => onChange(recommendedColor)}
                      className={`h-8 w-8 cursor-pointer rounded-xl ${
                        recommendedColor === value ? 'border-2 border-primary-medium' : ''
                      }`}
                      style={{ backgroundColor: recommendedColor }}
                    />
                  ))}
                </div>
              </div>
            </PopoverPanel>
          </>
        )}
      />
    </Popover>
  );
};
