import { FC, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { classNames } from '../../lib';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  UserIcon as CandidateIcon,
  CogIcon,
} from '@heroicons/react/24/outline';
import * as Logo from '../../assets/alogo.svg';
import { navigate, routes, useMatch } from '@redwoodjs/router';
import { useDialog, usePageClasses } from '../../hooks';
import { PromptsDialog } from '../PromptsDialog';
import { CreateDialog } from '../CreateDialog';

const SIDEBAR_COLLAPSED_WIDTH = 64;
const SIDEBAR_EXPANDED_WIDTH = 200;

export const Sidebar: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const { show } = useDialog();
  const { pageClasses } = usePageClasses();

  const hideSidebar = pageClasses.includes('hide-sidebar');
  const showSidebar = pageClasses.includes('show-sidebar');

  return (
    <div
      className={classNames(
        'static  left-0 top-0 z-30 h-full  pr-[64px]',
        hideSidebar && 'hidden',
        showSidebar && 'static'
      )}
    >
      <motion.div
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
        className={classNames(
          'absolute z-30 flex h-full flex-col items-center border-r border-text-light',
          pageClasses
        )}
        animate={{
          width: expanded ? `${SIDEBAR_EXPANDED_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`,
        }}
      >
        <div className="flex flex-col gap-y-4 pb-3 pt-6">
          <div
            className="flex cursor-pointer justify-center self-stretch rounded p-1 hover:bg-gray-100"
            onClick={() => navigate(routes.Home())}
          >
            <div className="h-8 w-8">
              {/* @ts-expect-error Logo doesn't convert to React svg well */}
              <Logo />
            </div>
          </div>
          <CreateButton expanded={expanded} />
        </div>
        <div className="flex flex-col gap-y-1 border-b border-t border-text-light py-3">
          <SidebarButton text="Jobs" Icon={BriefcaseIcon} expanded={expanded} to={routes.jobs()} />
          <SidebarButton
            text="Candidates"
            Icon={CandidateIcon}
            to={routes.candidates()}
            expanded={expanded}
          />
          <SidebarButton
            text="Companies"
            Icon={BuildingOfficeIcon}
            to={routes.companies()}
            expanded={expanded}
          />
          <SidebarButton
            text="Chat"
            Icon={ChatBubbleLeftRightIcon}
            to={routes.chat()}
            expanded={expanded}
          />
          <SidebarButton
            text="Docs"
            Icon={DocumentTextIcon}
            to={routes.documents()}
            expanded={expanded}
          />
        </div>
        <div className="flex flex-grow flex-col justify-end border-b border-text-light py-3">
          <SidebarButton
            text="Prompts"
            Icon={ChatBubbleLeftEllipsisIcon}
            onClick={() => show(<PromptsDialog />)}
            expanded={expanded}
          />
        </div>
        <div className="py-3">
          <SidebarButton
            text="Help"
            Icon={QuestionMarkCircleIcon}
            expanded={expanded}
            to={routes.help()}
          />
          <SidebarButton
            text="Settings"
            Icon={CogIcon}
            expanded={expanded}
            to={routes.settingsAccount()}
          />
        </div>
      </motion.div>
    </div>
  );
};

type SidebarButtonProps = {
  text: string;
  expanded?: boolean;
  Icon?: FC<React.ComponentProps<'svg'>> | FC<{ className?: string }>;
} & ({ to: string; onClick?: undefined } | { onClick: () => void; to?: undefined });

const SidebarButton: FC<SidebarButtonProps> = ({ text, to, expanded, Icon, onClick }) => {
  const { match } = useMatch(to ?? '');

  return (
    <motion.div
      className={classNames(
        'flex cursor-pointer items-center rounded-lg',
        !match && 'hover:bg-gray-100'
      )}
      // Allocate space for extra padding
      animate={{
        width: expanded ? `${SIDEBAR_EXPANDED_WIDTH - 16}px` : `${SIDEBAR_COLLAPSED_WIDTH - 16}px`,
      }}
      onClick={to ? () => navigate(to) : onClick}
    >
      <div className="p-3">
        {Icon && (
          <Icon
            className={classNames('h-6 w-6 text-text-medium', match && 'text-text-veryDark')}
            strokeWidth={2}
          />
        )}
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={classNames(match ? 'font-medium text-text-veryDark' : 'text-text-dark')}
          >
            {text}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const CreateButton: FC<{ expanded?: boolean }> = ({ expanded }) => {
  const { show, close } = useDialog();
  return (
    <motion.div
      className={classNames(
        'flex cursor-pointer items-center justify-center rounded-lg bg-generate-medium hover:bg-generate-light'
      )}
      animate={{
        width: expanded ? `${SIDEBAR_EXPANDED_WIDTH - 16}px` : `${SIDEBAR_COLLAPSED_WIDTH - 24}px`,
      }}
      onClick={() => show(<CreateDialog onClose={close} />)}
    >
      <div className="p-2">
        <PlusIcon className={classNames('h-6 w-6 text-white')} strokeWidth={2} />
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={classNames('text-white')}
          >
            Create
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
