import { FC, useState } from 'react';
import { useDialog } from 'src/hooks';
import { DialogLayout } from '../DialogLayout';
import { classNames } from 'src/lib';
import { EntityName, ExportSubmitParams } from './types';
import ActionButtons from './ActionButtons';
import { toast } from '@redwoodjs/web/dist/toast';
import { DropdownSelect } from '../DropdownSelect';

type Props = {
  entityName: EntityName;
  className?: string;
  onSubmit: ({ includeDocuments, onComplete }: ExportSubmitParams) => void;
};

export const EntityExportDialog: FC<Props> = ({ className, entityName, onSubmit }) => {
  const [includeDocuments, setIncludeDocuments] = useState<boolean>(false);

  const { close } = useDialog();

  const exportHandler = async () => {
    close();
    toast.loading('Exporting...', { duration: 500 });

    onSubmit({
      includeDocuments,
      onComplete: () => {
        toast.dismiss();
      },
    });
  };

  return (
    <DialogLayout
      className={classNames('overflow-visible', className)}
      title={`Export ${entityName}`}
      onClose={close}
    >
      <p className="py-2 text-base text-text-dark">The job will be exported as a .csv file.</p>
      <div className="flex flex-col gap-y-4 px-1 pb-6">
        <div className="flex justify-between">
          <p className="text-base text-text-medium">Include Documents?</p>
          <DropdownSelect<boolean>
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={(value) => setIncludeDocuments(value)}
            value={includeDocuments}
          />
        </div>
      </div>
      <ActionButtons onCancel={close} onExport={exportHandler} />
    </DialogLayout>
  );
};
