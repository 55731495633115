import { FC, InputHTMLAttributes, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EditorContent } from '@tiptap/react';
import { ZodObject, ZodSchema } from 'zod';

import { Label, Spinner, RichTextMenuBar, FieldError, ReadOnlyRichTextField } from 'src/components';

import { classNames } from 'src/lib';
import { useEditor, useReadOnlyForm } from 'src/hooks';
import { findMaxLengthOfFieldSchema, isFieldRequired } from 'src/lib/zod';
import { ButtonType } from '../RichTextMenuBar/RichTextMenuBar';
import { TEXT_AREA_CLASSES } from '../TextArea/util';

export type RichTextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  largeLabel?: boolean;
  placeholder?: string;
  loading?: boolean;
  value?: string;
  onChange?: (e: string) => void;
  schema?: ZodSchema | null;
  required?: boolean;
  visibleButtons?: ButtonType[];
  className?: string;
  name: string;
  richTextFieldSize?: 'sm' | 'md';
};

const editorContentClasses = classNames(
  TEXT_AREA_CLASSES,
  'flex-grow min-h-32 overflow-y-auto rounded-t-none w-full cursor-text'
);

export const RichTextInput: FC<RichTextInputProps> = ({
  name,
  label,
  largeLabel,
  loading,
  onChange,
  value,
  disabled,
  placeholder,
  schema,
  required,
  visibleButtons = ['bold', 'italic', 'bulletList', 'orderedList'],
  className,
  richTextFieldSize = 'md',
}) => {
  const { readOnly } = useReadOnlyForm();

  const fieldSchema = (schema as ZodObject<any, any>)?.shape?.[name];

  // Subtracting htmlMarkupBuffer from the schema's max length to account for HTML tags in the text.
  // This ensures the character limit displayed to the user reflects only visible text, not markup.
  const maxLengthResult = findMaxLengthOfFieldSchema(fieldSchema);
  const maxLengthOfFieldSchema =
    maxLengthResult !== undefined ? Math.floor(maxLengthResult * 0.9) : undefined;

  const isDisabled = disabled || loading;

  const editor = useEditor({
    content: value,
    editable: !readOnly && !isDisabled,
    placeholder: placeholder ?? '',
    maxLength: maxLengthOfFieldSchema,
    onUpdate: onChange,
    richTextFieldSize,
  });

  // Add useEffect to update editor content when value changes
  useEffect(() => {
    if (editor && value !== undefined) {
      if (editor.getHTML() !== value) {
        editor.commands.setContent(value, false, { preserveWhitespace: true });
      }
    }
  }, [value, editor]);

  const currentCharCount = editor?.storage.characterCount?.characters();
  const isCharCountLimitReached =
    maxLengthOfFieldSchema && currentCharCount >= maxLengthOfFieldSchema;
  const isRequired = (schema && isFieldRequired(schema, name)) || required;

  const { watch } = useFormContext();
  const readOnlyValue = watch(name);

  if (readOnly) {
    return <ReadOnlyRichTextField name={name} label={label} value={readOnlyValue} />;
  }

  return (
    <div className={classNames('flex w-full flex-col gap-1', className)}>
      <Label
        name={name}
        label={label || ' '}
        readOnly={readOnly}
        required={isRequired}
        largeLabel={largeLabel}
      />
      <div
        className={classNames(
          'flex flex-grow flex-col justify-center overflow-hidden rounded-2xl ',
          isDisabled ? 'cursor-not-allowed bg-gray-100' : 'bg-white'
        )}
      >
        {editor && (
          <RichTextMenuBar
            editor={editor}
            editable={true}
            disabled={isDisabled}
            className={classNames(
              'rounded-t-2xl border border-b-0 border-gray-300 p-3',
              isDisabled && 'bg-gray-100'
            )}
            visibleButtons={visibleButtons}
            richTextFieldSize={richTextFieldSize}
          />
        )}
        <div
          className={classNames(
            'relative flex h-full flex-col overflow-auto',
            editorContentClasses,
            richTextFieldSize === 'sm' ? 'text-sm' : 'text-base'
          )}
          onClick={() => editor?.view.focus()}
        >
          <EditorContent editor={editor} placeholder={placeholder} />
          {loading && (
            <div className="absolute right-2 top-2 pr-2 pt-2">
              <Spinner className={richTextFieldSize === 'md' ? 'h-4 w-4' : 'h-6 w-6'} />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <FieldError name={name} />
        </div>
        {maxLengthOfFieldSchema && (
          <div
            className={classNames(
              'self-end text-sm text-text-light',
              Boolean(isCharCountLimitReached) && 'text-text-medium/90'
            )}
          >
            {currentCharCount}/{maxLengthOfFieldSchema}
          </div>
        )}
      </div>
    </div>
  );
};

export const RichTextField: FC<{
  name: string;
  label?: string;
  largeLabel?: boolean;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  schema?: ZodSchema | null;
  visibleButtons?: ButtonType[];
  className?: string;
  required?: boolean;
  richTextFieldSize?: 'sm' | 'md';
}> = ({
  name,
  label,
  largeLabel,
  loading,
  disabled,
  placeholder,
  schema,
  required,
  visibleButtons,
  className,
  richTextFieldSize = 'md',
}) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => (
        <RichTextInput
          value={value}
          onChange={onChange}
          name={name}
          label={label}
          largeLabel={largeLabel}
          loading={loading}
          disabled={disabled}
          placeholder={placeholder}
          schema={schema}
          visibleButtons={visibleButtons}
          className={className}
          required={required}
          richTextFieldSize={richTextFieldSize}
        />
      )}
    />
  );
};
