import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { motion, AnimatePresence } from 'framer-motion';
import { useBoolean } from 'src/hooks';

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

export type TooltipProps = {
  children: React.ReactNode;
  innerBody: React.ReactNode;
  position?: TooltipPosition;
  disabled?: boolean;
  className?: string;
  minWidth?: string;
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  innerBody,
  position = 'top',
  disabled = false,
  className,
  minWidth,
}) => {
  const {
    value: isTooltipVisible,
    setTrue: showTooltip,
    setFalse: hideTooltip,
  } = useBoolean(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div>
      <div ref={setReferenceElement} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </div>
      <AnimatePresence>
        {isTooltipVisible && (
          <motion.div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              minWidth,
              zIndex: 100,
            }}
            {...attributes.popper}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, type: 'tween', delay: 0.1 }}
            className={`overflow-visible rounded-l bg-text-veryDark px-2 py-1 text-xs text-text-light shadow-sm ${className}`}
          >
            {innerBody}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
